import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../Components/Loading";
import Mensagem from "../../Components/Mensagem";
import { Alert, Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { MyContext } from "../../Context/MyContext";
import styles from "./CancelarPedidoBmaAcesso.module.css";

const CancelarPedidoBmaAcesso = () => {
    const { id } = useParams();

    const navigate = useNavigate();  

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');    
    const idDistribuidorLogado = window.localStorage.getItem('distribuidorId');   

    const {mensagemGlobal, setMensagemGlobal, tipoMensagemGlobal, setTipoMensagemGlobal} = React.useContext(MyContext);

    const [mensagem, setMensagem] = React.useState('');
    const [tipoMensagem, setTipoMensagem] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [idDistribuidor, setIdDistribuidor] = React.useState('');
    const [razaoSocial, setRazaoSocial] = React.useState('');
    const [inscricao, setInscricao] = React.useState('');
    const [agendaWeb, setAgendaWeb] = React.useState('');
    const [usuariosAgenda, setUsuariosAgenda] = React.useState('');
    const [equipamentoAdicional, setEquipamentoAdicional] = React.useState(0);
    const [serverAdicional, setServerAdicional] = React.useState(0);
    const [tipoPedido, setTipoPedido] = React.useState('');
    const [tipoVertical, setTipoVertical] = React.useState('');
    const [tipoBanco, setTipoBanco] = React.useState('');
    const [versao, setVersao] = React.useState('');
    const [release, setRelease] = React.useState('');
    const [integracaoFuncionarios, setIntegracaoFuncionarios] = React.useState('');
    const [integracaoPacientes, setIntegracaoPacientes] = React.useState('');
    const [moduloLGPD, setModuloLGPD] = React.useState('');
    const [wpe, setWpe] = React.useState('');
    const [contrato, setContrato] = React.useState('');
    const [valorPedido, setValorPedido] = React.useState('');
    const [valorMensal, setValorMensal] = React.useState('');
    const [dataPedido, setDataPedido] = React.useState('');
    const [MotivoCancelamento, setMotivoCancelamento] = React.useState('');
    const [statusPedido, setStatusPedido] = React.useState('');

    React.useEffect(() => {
        (async () => {
            try {   
                setLoading(true);
                const response = await api.get(`/BmaAcesso/${id}`);    
        
                setIdDistribuidor(response.data.distribuidorId);
                setStatusPedido(response.data.statusPedido);

                if (response.data.tipoPedido === 0)  {
                     setTipoPedido("Cópia Nova");
                }
                else setTipoPedido("Atualização");

                switch (response.data.tipoVertical) {
                    case 1 : setTipoVertical("Empresa");
                        break;
                    case 2 : setTipoVertical("Refeitório");
                        break;
                    case 3 : setTipoVertical("Academia");
                        break;
                    case 4 : setTipoVertical("Condomínio");
                        break;
                    case 5 : setTipoVertical("Hospital");
                        break;
                    case 6 : setTipoVertical("Escola");
                        break;
                }

                switch (response.data.tipoBancoDados) {
                    case 1 : setTipoBanco("MySql");
                        break;
                    case 2 : setTipoBanco("Sql-Server");
                        break;
                    case 3 : setTipoBanco("Oracle");
                        break;
                }

                setUsuariosAgenda(response.data.usuariosAgenda);               
                setValorPedido(response.data.valorBruto);
                setValorMensal(response.data.valorMensal);
                setVersao(response.data.versaoSistema);
                setRelease(response.data.versaoRelease);

                const dataString = response.data.dataPedido.substring(8,10) + "/" +
                response.data.dataPedido.substring(5,7)  + "/" +   
                response.data.dataPedido.substring(0,4);
                setDataPedido(dataString);   

                setEquipamentoAdicional(response.data.equipamentoAdicional);
                setServerAdicional(response.data.serverAdicional);

                if  (response.data.contrato === 1)
                    setContrato("Sim")
                else
                    setContrato("Não");    

                if  (response.data.agendaWeb === 1)
                    setAgendaWeb("Sim")
                else
                    setAgendaWeb("Não");    

                if  (response.data.integracaoPonto === 1)
                    setWpe("Sim")
                else
                    setWpe("Não");    

                if  (response.data.moduloLGPD === 1)
                    setModuloLGPD("Sim")
                else
                    setModuloLGPD("Não");    

               if  (response.data.integracaoFuncionarios === 1)
                    setIntegracaoFuncionarios("Sim")
                else
                    setIntegracaoFuncionarios("Não");    

                if  (response.data.integracaoPacientes === 1)
                    setIntegracaoPacientes("Sim")
                else
                    setIntegracaoPacientes("Não");    
                    
                buscarCliente(response.data.clienteId);
                setLoading(false);

                const distribuidorLogado = Number(idDistribuidorLogado);
                const distribuidorPedido = Number(response.data.distribuidorId);

                if  ((distribuidorLogado !== distribuidorPedido) && (nivelUsuario === "3" || nivelUsuario === "4")) {
                    setMensagemGlobal("Ops, você não tem permissão para acessar este Pedido !");
                    setTipoMensagemGlobal("danger");
                    navigate("/pedidosacesso");
                }

            } catch (error) {
                alert(error);
            }            
        }
        )();

    }, [id]);                

    function buscarCliente(id) {
        (async () => {
            try {   
                const response = await api.get(`/Cliente/${id}`);    
                setRazaoSocial(response.data.razaoSocial);
                setInscricao(response.data.numeroInscricao);
                
            } catch (error) {
            }            
        }
        )();
    }

    async function handleSubmit(ev) {
        ev.preventDefault();

        if  (MotivoCancelamento.length < 10) {
            setMensagem("Motivo Cancelamento precisa ter no minimo 10 caracteres !");
            setTipoMensagem("danger");
            return;
        }

        const statusPedido = 2;
        const retorno = await api.put(`/BmaAcesso/atualizar-status-pedido?id=${id}&status=${statusPedido}&motivo=${MotivoCancelamento}`);   
        setMensagemGlobal(retorno.data);
        setTipoMensagemGlobal("success");               
        navigate("/pedidosacesso");
    }


    if  (loading) return <Loading />

    return (
        <section className={styles.form}>

            {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center', color: 'tomato'}}>Cancelar Pedido BmaAcesso</Alert.Heading>        
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}>   
                <Col md='8'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Cliente">
                        <Form.Control value={razaoSocial} disabled />
                    </FloatingLabel>                    
                </Col>

                <Col md='4'>
                     <FloatingLabel controlid="floatingInputGrid6" label="CNPJ / CPF">
                         <Form.Control value={inscricao} disabled />
                    </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}> 
                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Vertical">
                        <Form.Control value={tipoVertical} disabled readOnly/>
                    </FloatingLabel>                    
                </Col>

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid6" label="Versão">
                         <Form.Control value={versao} disabled readOnly />
                    </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid7" label="Release">
                         <Form.Control value={release} disabled readOnly />
                      </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid7" label="Banco de Dados">
                         <Form.Control value={tipoBanco} disabled readOnly />
                      </FloatingLabel>      
                </Col>               
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Tipo de Pedido">
                        <Form.Control value={tipoPedido} disabled readOnly />
                    </FloatingLabel>                    
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Equipamentos Adicionais">
                        <Form.Control value={equipamentoAdicional} disabled readOnly />
                    </FloatingLabel>      
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Server Adicional">
                        <Form.Control value={serverAdicional} disabled /> 
                    </FloatingLabel>      
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Agenda Web">
                        <Form.Control value={agendaWeb} disabled readOnly />
                    </FloatingLabel>                    
                </Col>

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Módulo LPGD">
                        <Form.Control value={moduloLGPD} disabled readOnly />
                    </FloatingLabel>                    
                </Col>

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Integra Funcionários">
                        <Form.Control value={integracaoFuncionarios} disabled readOnly />
                    </FloatingLabel>                    
                </Col>

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Integra Pacientes">
                        <Form.Control value={integracaoPacientes} disabled readOnly />
                    </FloatingLabel>                    
                </Col>

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Integra BmaPonto">
                        <Form.Control value={wpe} disabled readOnly />
                    </FloatingLabel>                    
                </Col>

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Contrato">
                        <Form.Control value={contrato} disabled readOnly />
                    </FloatingLabel>                    
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid13" label="Data Pedido">
                        <Form.Control value={dataPedido} disabled  readOnly />
                    </FloatingLabel>                    
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid16" label="Usuarios Agenda">
                        <Form.Control value={usuariosAgenda} disabled readOnly />
                    </FloatingLabel>                    
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid19" label="Valor Pedido">
                        <Form.Control value={valorPedido} disabled readOnly />
                    </FloatingLabel>                    
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid22" label="Valor Mensal">
                        <Form.Control value={valorMensal} disabled  readOnly/>
                    </FloatingLabel>                    
                </Col>
            </Row>

            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Motivo Cancelamento</Alert.Heading>        
            <br /> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                     <FloatingLabel controlid="floatingInputGrid4" label="Mínimo (10 caracteres)">
                        <Form.Control value={MotivoCancelamento} minLength={10} maxLength={250}
                                    onChange={({ target }) => setMotivoCancelamento(target.value)}/>
                      </FloatingLabel>      
                </Col>                
            </Row>

            </Alert>

            <div className={styles.areaBtn}>    
               <Button onClick={handleSubmit} variant="outline-danger" className={styles.btn} 
                       disabled={nivelUsuario !== "1" || statusPedido > 1}>Confirmar Cancelamento</Button>
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
)}

export default CancelarPedidoBmaAcesso;