import React from "react";
import api from "../../Api/apiAxios";
import { Alert, Button, Col, OverlayTrigger, Form, Row, InputGroup, Table, Tooltip } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { CardText, Search } from "react-bootstrap-icons";
import Loading from "../../Components/Loading";
import ModalCliente from "../../Components/ModalCliente";

import styles from "./DistribuidorClientes.module.css";

const DistribuidorClientes = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [distribuidor, setDistribuidor] = React.useState('');
    const [pesquisa, setPesquisa] = React.useState('');
    const [regCliente, setRegCliente] = React.useState();
    const [clientes, setClientes] = React.useState([]);

    React.useEffect(() => {  
        (async () => {
            try {   
                setLoading(true);                  

                const response = await api.get(`/Distribuidor/${id}`);    
                setDistribuidor(response.data);
                
                const resp = await api.get(`/Cliente/${id}/A`);
                resp.data.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));                

                setClientes(resp.data);            
                setLoading(false);       

            } catch (error) {
                alert(error);
            }            
        }
        )();

    }, [id]);                

    function acionarModalClick(regCliente) {
            setModalOpen(true);      
            setRegCliente(regCliente);  
    }
    
    async function pesquisarClick() {
        setLoading(true);                  
        const pesquisaLowerCase = pesquisa.toLowerCase();           
        if  (pesquisa.length > 0) {
            const resp = await api.get(`/Cliente/${id}/A`);

            if  (isNaN(pesquisa)) {
                const registrosFiltrados = resp.data.filter((cliente) => 
                      cliente.razaoSocial.toLowerCase().includes(pesquisaLowerCase));               
                setClientes(registrosFiltrados);            
            } else {
                const cnpjComMascara = pesquisa.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");      
                const registrosFiltrados = resp.data.filter((cliente) => 
                      cliente.numeroInscricao.includes(cnpjComMascara));               
                setClientes(registrosFiltrados);                            
            }

        }
        else {
            const resp = await api.get(`/Cliente/${id}/A`);
            resp.data.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));  
            setClientes(resp.data);            
        }
        setLoading(false);                  
        setPesquisa('');
    }


    if  (loading) return <Loading />

    if  (modalOpen) return <ModalCliente setModalOpen={setModalOpen} cliente={regCliente} />

    return (
        <section className={styles.form}>
        <Alert style={{background: 'ghostwhite', color: 'dimGray'}}>
            <Row>
                <Col md="12">
                    <Alert.Heading style={{textAlign: 'center', fontSize: 'large'}}>Lista de Clientes - {distribuidor.razaoSocial}</Alert.Heading>        
                </Col>
            </Row>

            <br />

            <Row>
                <Col md='4'>
                    <InputGroup className="mb-3" style={{marginTop: '6px'}}>
                        <Form.Control placeholder="Pesquisa Parcial: Razão Social / CNPJ (somente numeros)" value={pesquisa}
                                    style={{fontSize: '0.8rem'}}
                                    onChange={({ target }) => setPesquisa(target.value)}/>

                            <Button onClick={pesquisarClick} variant="outline-primary">
                                <Search size={15} />
                            </Button>
                    </InputGroup>
                </Col>
                
                <Col md='1'></Col>

                <Col md='3'>
                     <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
                </Col>
            </Row>
         </Alert>

          <Table striped hover responsive size="sm" >
                <thead className={styles.thead}>
                    <tr className={styles.trHead}>
                            <th className={styles.th}>Razão Social</th>
                            <th className={styles.th}>Cnpj/Cpf</th>                          
                            <th className={styles.th}>Contato</th>                        
                            <th className={styles.th}>E-Mail</th>
                            <th className={styles.th}>Fone</th>
                        </tr>
                </thead>
                <tbody className={styles.tbody}>
                {clientes.map(cliente => 
                    <tr key={cliente.id} className={styles.trBody}>                                     
                        <td className={styles.td}>{cliente.razaoSocial}</td>          
                        <td className={styles.td}>{cliente.numeroInscricao}</td>
                        <td className={styles.td}>{cliente.nomeContato}</td>
                        <td className={styles.td}>{cliente.emailContato}</td>  
                        <td className={styles.td}>{cliente.foneContato}</td>
                        <td>
                            <OverlayTrigger overlay={
                                <Tooltip id="btn-editar">Detalhar</Tooltip> }> 
                                <Button onClick={() => acionarModalClick(cliente)}
                                        variant="outline-success">
                                    <CardText size={15}/>
                                </Button>   
                            </OverlayTrigger>                                                         
                        </td>                        
                    </tr>
                )}
            </tbody>

            </Table>

        </section>
    )

}

export default DistribuidorClientes;