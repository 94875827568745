import React from "react";
import api from "../../Api/apiAxios";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import { HeaderPedidos} from "../../Components/HeaderPedidos";
import ModalPedidos from "../../Components/ModalPedido";
import { Paginacao } from "../../Components/Paginacao";
import styles from "./BmaPontoIndex.module.css";

import StatusPedido from "../../Components/StatusPedido";
import TipoInstalacao from "../../Components/TipoInstalacao";
import PedidosButtons from "../../Components/PedidosButtons";
import { TableItemName } from "../../Components/TableItemName";

const BmaPontoIndex = () => {

    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');   

    const { mensagemGlobal, setMensagemGlobal, tipoMensagemGlobal,
            setListaGlobalClientes, setListaGlobalContratos,  setDistribuidorIdGlobal, itensPorPagina } = React.useContext(MyContext);

    const [showModal, setShowModal] = React.useState(false);
    const [botaoLiberar, setBotaoLiberar] = React.useState(false);
    const [pedidoSalvo, setPedidoSalvo] = React.useState('');
    const [bodyModal1, setBodyModal1] = React.useState('');
    const [bodyModal2, setBodyModal2] = React.useState('');
    const [bodyModal3, setBodyModal3] = React.useState('');

    const [pesquisa, setPesquisa] = React.useState('');
    const [tipoPesquisa, setTipoPesquisa] = React.useState('*');
    const [loading, setLoading] = React.useState(false);
    const [botaoAdicionar, setBotaoAdicionar] = React.useState('');
    const [registroInicial, setRegistroInicial] = React.useState(1);
    const [registroFinal, setRegistroFinal] = React.useState(20);
    const [quantidadeRegistros, setQuantidadeRegistros] = React.useState(0);

    const [pedidos, setPedidos] = React.useState([]);

    React.useEffect(() => {               
        setListaGlobalClientes(null);
        setListaGlobalContratos(null);
        setDistribuidorIdGlobal('');

        if  (nivelUsuario === "2" || nivelUsuario === "4")    
        {
            setBotaoAdicionar("hide");
        }
        else {
            setBotaoAdicionar(' Novo Pedido');
        }
        pesquisaInicial();            

    }, []);    


    async  function pesquisaInicial() { 
        setLoading(true);  
        
        try {
            const response = await api.get(`/BmaPonto/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}&regInicial=${registroInicial}&itensPorPagina=${999999}`);   
            var qtdeRegistros = response.data[0].numeroOrdemCompra;
            setQuantidadeRegistros(qtdeRegistros);             

            const response2 = await api.get(`/BmaPonto/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}&regInicial=${registroInicial}&itensPorPagina=${itensPorPagina}`);   
            setPedidos(response2.data);
        } catch (e) {
            alert(e);
        } finally {
            setLoading(false);
        }   
    }

    async function pesquisarClick() {
        setLoading(true);                  
        setShowModal(false);
        setBotaoLiberar(false);
        setMensagemGlobal(null);

        if  (pesquisa.length === 0) {           
            if  (tipoPesquisa === "liberados" || tipoPesquisa === "pendentes" || tipoPesquisa === "cancelados") {
                const pesq = 'status';
                const response = await 
                     api.get(`/BmaPonto/parametros-consulta?id=${idDistribuidor}&nivel=${nivelUsuario}&tipo=${tipoPesquisa}&args=${pesq}`);   
                setPedidos(response.data);            

            }
            else {
                const response = await 
                      api.get(`/BmaPonto/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}&regInicial=${registroInicial}&itensPorPagina=${itensPorPagina}`);   
                setPedidos(response.data);            
  
/*                 const pesq = '*';
                const response = await 
                     api.get(`/BmaPonto/parametros-consulta?id=${idDistribuidor}&nivel=${nivelUsuario}&tipo=${tipoPesquisa}&args=${pesq}`);   
                setPedidos(response.data);              
 */                
            }
        }
        else {
            const response = await 
                api.get(`/BmaPonto/parametros-consulta?id=${idDistribuidor}&nivel=${nivelUsuario}&tipo=${tipoPesquisa}&args=${pesquisa}`);   
            setPedidos(response.data);            
        }
        setPesquisa('');
        setLoading(false);       

    }

    if  (loading) return <Loading />

    if  (showModal) return <ModalPedidos 
                                showModal={showModal} 
                                setShowModal={setShowModal}
                                botaoLiberar={botaoLiberar} 
                                setBotaoLiberar={setBotaoLiberar}
                                titleModal="Confirmação de Liberação Pedido !"
                                bodyModal1={bodyModal1} 
                                bodyModal2={bodyModal2} 
                                bodyModal3={bodyModal3}
                                atualizarUrl={`/BmaPonto/atualizar-status-pedido`} 
                                pedidoSalvo={pedidoSalvo}
                                pesquisarClick={pesquisarClick}
                                setPedidoSalvo={setPedidoSalvo}
                            />
                   
    return (
        <section>
          {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

           <HeaderPedidos 
                titulo={"Pedidos BmaPonto"} 
                textoBotao={botaoAdicionar} 
                pesquisa={pesquisa} setPesquisa={setPesquisa}
                setTipoPesquisa={setTipoPesquisa}
                isPonto={true}
                pesquisarClick={pesquisarClick}
            />

            <Table striped hover responsive size='sm'>
                <thead className={styles.thead}>
                    <tr className={styles.trHead}>
                            <th className={styles.th}>Distribuidor</th>
                            <th className={styles.th}>Cliente</th>                          
                            <th className={styles.th}>Versão</th>
                            <th className={styles.th}>NS</th>                        
                            <th className={styles.th}>TP</th>                        
                            <th className={styles.th}>Plano</th>
                            <th className={styles.th}>Faixa</th>
                            <th className={styles.th}>Data Pedido</th>
                            <th className={styles.th}>Data Lib.</th>
                            <th className={styles.th}>Status</th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                {pedidos.map(pedido => 
                    <tr key={pedido.id} className={styles.trBody}>                                     
                        <TableItemName title={pedido.distribuidor.nomeFantasia} />
                        <TableItemName title={pedido.cliente.razaoSocial} />

                        <td className={styles.td}>{pedido.versaoSistema}.{pedido.versaoRelease}</td>
                        <td className={styles.td}>{pedido.numeroSerie}</td>  
                        
                        {pedido.tipoPedido === 0 ? 
                            <td className={styles.td}>N</td>  
                          : <td className={styles.td}>A</td>  
                        }

                        <TipoInstalacao tipoInstalacao={pedido.tipoInstalacao} isPonto={true} />

                        <td className={styles.td}>{pedido.faixaFuncionarios}</td>
                        <td className={styles.td}>
                            {pedido.dataPedido.substring(8,10)}/   
                            {pedido.dataPedido.substring(5,7)}/   
                            {pedido.dataPedido.substring(0,4)}   
                            
                        </td>
                        {pedido.dataLiberacao &&
                            <td className={styles.td}>
                                 {pedido.dataLiberacao.substring(8,10)}/ 
                                 {pedido.dataLiberacao.substring(5,7)}/   
                                 {pedido.dataLiberacao.substring(0,4)}    
                            </td>
                        }
                        {!pedido.dataLiberacao && pedido.statusPedido !== 2 &&
                            <td className={styles.td}>Em Análise</td>
                        }
                        {!pedido.dataLiberacao && pedido.statusPedido === 2 &&
                            <td className={styles.td}>* * * * *</td>
                        }

                        <StatusPedido status={pedido.statusPedido} />  
                        <PedidosButtons 
                            nivelUsuario={nivelUsuario} 
                            pedido={pedido} 
                            setBotaoLiberar={setBotaoLiberar}
                            setPedidoSalvo={setPedidoSalvo}
                            setBodyModal1={setBodyModal1}
                            setBodyModal2={setBodyModal2}
                            setBodyModal3={setBodyModal3}
                            setShowModal={setShowModal} 
                            cancelarUrl={`/cancelarpedidobmaponto/${pedido.id}`}
                            editarUrl={`/editarpedidobmaponto/${pedido.id}`}
                            viewUrl={`/viewpedidobmaponto/${pedido.id}`}
                        />
                    </tr>
                )}

                </tbody>

                <tfoot>
                    <Paginacao 
                        registroInicial={registroInicial}     
                        registroFinal={registroFinal}
                        urlPesquisa={`/BmaPonto/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}`}
                        quantidadeRegistros={quantidadeRegistros}
                        itensPorPagina={itensPorPagina}   
                        setRegistrosData={setPedidos}
                        setRegistroFinal={setRegistroFinal}
                        setRegistroInicial={setRegistroInicial}                            
                    />
                </tfoot>

            </Table>

        </section>
    )

}

export default BmaPontoIndex;