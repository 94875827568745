import React from "react";
import api from "../../Api/apiAxios";
import { Button, Table } from "react-bootstrap";
import { EnvelopeAt, Pencil } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import HeaderCadastros from "../../Components/HeaderCadastros";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import Loading from "../../Components/Loading";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styles from "./UsuariosIndex.module.css";

const UsuariosIndex = () => {

    const navigate = useNavigate();
    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  

    const { mensagemGlobal, setMensagemGlobal, tipoMensagemGlobal, setTipoMensagemGlobal } = React.useContext(MyContext);

    const [pesquisa, setPesquisa] = React.useState('');
    const [usuarios, setUsuarios] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
            async  function pesquisar() { 
                setLoading(true);                 
                const { status, data } = await api.get('/Usuario');
                if  (status === 200) {
                    setUsuarios(data);    
                }            
                setLoading(false);     
            }

        pesquisar();            
    }, []);            

    function pesquisarClick(event) { 
        event.preventDefault();     
        (async () => {
            try {
                setLoading(true);
                const pesquisaLowerCase = pesquisa.toLowerCase();                       

                const response = await api.get('/Usuario');                             
                if  (pesquisa !== null) {
                    const usuariosFiltrados = response.data.filter((usuario) => 
                        usuario.nome.toLowerCase().includes(pesquisaLowerCase));               
                    setUsuarios(usuariosFiltrados);            
                } else {
                    setUsuarios(response.data);
                }    
                setLoading(false);
                setPesquisa('');
                

            } catch (error) {
                alert(error);
            }
        }
        )();
    }

    function adicionarClick(event) {
        event.preventDefault();
        navigate("/novousuario");
    }

    function editarClick(usuario) {              
        navigate(`/editarusuario/${usuario.id}`);
    }

    async function emailUsuario(usuario) {
        setLoading(true);

        const response = await api.get(`/Usuario/recuperacao-credenciais?id=${usuario.id}`);                             
        setMensagemGlobal(response.data);
        setTipoMensagemGlobal("warning");
        
        setLoading(false);
    }


    if (loading) return <Loading />

    return (
       <section>
       {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

        <HeaderCadastros titulo={"Usuários"} textoBotao={" Adicionar Usuario"} 
                         pesquisa={pesquisa} setPesquisa={setPesquisa}
                         pesquisarClick={pesquisarClick} adicionarClick={adicionarClick} />

        <Table striped hover responsive>
            <thead className={styles.thead}>
                   <tr className={styles.trHead}>
                        <th className={styles.th}>Nome</th>
                        <th className={styles.th}>E-Mail</th>                          
                        <th className={styles.th}>Distribuidor</th>                        
                        <th className={styles.th}>Celular</th>
                    </tr>
            </thead>
            <tbody className={styles.tbody}>
                {usuarios.map(usuario => 
                    <tr key={usuario.id} className={styles.trBody}>                                     
                        <td className={styles.td}>{usuario.nome}</td>          
                        <td className={styles.td}>{usuario.email}</td>  
                        <td className={styles.td}>{usuario.distribuidor.nomeFantasia}</td>  
                        <td className={styles.td}>{usuario.celular}</td>
                        <td>
                            <OverlayTrigger overlay={
                                <Tooltip id="btn-editar">Editar</Tooltip> }>
                                <Button onClick={() => editarClick(usuario)}
                                        disabled={nivelUsuario !== "1"}                                      
                                        variant="outline-warning">
                                    <Pencil size={15}/>
                                </Button>
                            </OverlayTrigger>                            
                        </td>                        
                        <td style={{display: 'flex'}}>
                            <OverlayTrigger overlay={
                                <Tooltip id="btn-email">Enviar Credenciais</Tooltip> }>
                                <Button onClick={() => emailUsuario(usuario)}
                                        disabled={nivelUsuario !== "1"}                                      
                                        variant="outline-secondary">
                                    <EnvelopeAt size={15}/>
                                </Button>
                            </OverlayTrigger>                            
                        </td>                        

                    </tr>
                )}
            </tbody>

        </Table>

       </section>
    )

}

export default UsuariosIndex;