import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from './TableItemName.module.css';

export const TableItemName = ({title}) => {
    let newTitle = ''
    var showTooltip = false;
    var maxLength = 25

    if (title.length > maxLength) {
        newTitle = title.substring(0,maxLength) + '...';
        showTooltip = true;
    } else {
        newTitle = title;
    }

    return (      
        <>                  
            { showTooltip ? 
                <OverlayTrigger placement="right" overlay={<Tooltip className={styles.tooltip}>{title}</Tooltip>}>                
                    <td className={styles.td}>{newTitle}</td>
                </OverlayTrigger> 
            : <td className={styles.td}>{newTitle}</td> 
            }
        </>
    )
}
