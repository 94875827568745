import React, { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom'

import MenuDefault from "../Menu/MenuDefault/MenuDefault";
import MenuRevenda from "../Menu/MenuRevenda/MenuRevenda";

export default function Menu() {
    const navigate = useNavigate();
    const location = useLocation();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');

    useEffect(() => {
        const token = window.localStorage.getItem('token');
        if (token === null) {
            navigate('/login');
            return;
        }
    }, [navigate])

    if (location.pathname !== '/login') {
        return (
            <>
                {Number(nivelUsuario) < 3 ? <MenuDefault /> : <MenuRevenda />}
            </>
        )
    }
}