import React from "react";
import { useNavigate } from "react-router-dom";
import { Button} from "react-bootstrap";
import { CardText, FolderSymlink, Lock, Unlock, Calendar3, Key } from "react-bootstrap-icons";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { MyContext } from "../Context/MyContext";
import api from "../Api/apiAxios";

import styles from "./Buttons.module.css"

export default function PersonalizacoesButtons({
    nivelUsuario, 
    personalizacao, 
    pesquisarClick,
    editarUrl,
    viewUrl,
    liberarPersonalizacaoUrl,
    liberarChaveUrl = "",
    bloquearUrl, 
    isAcesso = false}) {
    const navigate = useNavigate();

    const { setMensagemGlobal, setTipoMensagemGlobal } = React.useContext(MyContext);

    function editarDataValidadeClick() {
        navigate(`${editarUrl}`);
    }

    function viewPersonalizacao() {
        navigate(`${viewUrl}`);
    }

    async function liberarPersonalizacaoClick() {
        try {
            const retorno = await api.put(`${liberarPersonalizacaoUrl}`);
            setMensagemGlobal(retorno.data);
            setTipoMensagemGlobal("success");               

            pesquisarClick(); 
        } catch (e) {
            alert(e);
        }      
    }        

    async function bloquearPersonalizacaoClick() {
        try {
            const retorno = await api.put(`${bloquearUrl}`);
            setMensagemGlobal(retorno.data);
            setTipoMensagemGlobal("success");               

            pesquisarClick();    
        } catch (e) {
            alert(e);
        }
    }

    async function liberarChaveRegistroClick() {
        try {
            const retorno = await api.put(`${liberarChaveUrl}`);
            setMensagemGlobal(retorno.data);
            setTipoMensagemGlobal("success");               

            pesquisarClick(); 
        } catch (e) {
            alert(e);
        }
                   
    }

    return (
        <td>
            <div className={styles.buttons_container}>
                <OverlayTrigger overlay={<Tooltip id="btn-editar">Data Validade</Tooltip> }> 
                    <Button onClick={() => editarDataValidadeClick(personalizacao)}
                        variant="outline-warning" 
                        disabled={nivelUsuario !== "1"}
                    >
                        <Calendar3 size={18} />
                    </Button>   
                </OverlayTrigger>  

                <span />   

                <OverlayTrigger   overlay={<Tooltip id="btn-view-pedido">Visualizar Licença</Tooltip> }>                              
                    <Button onClick={() => viewPersonalizacao(personalizacao)}                                       
                        variant="outline-success"
                    >
                        <CardText size={18}/>
                    </Button>                                       
                </OverlayTrigger> 

                <span />       

                <OverlayTrigger   overlay={<Tooltip id="btn-view-liberar-personalizacao">Liberar para Registro</Tooltip> }>                              
                    <Button onClick={() => liberarPersonalizacaoClick(personalizacao)} 
                        disabled={nivelUsuario !== "1" || personalizacao.dataRegistro == null || personalizacao.baixadaPorNovaVersao === 1}                                      
                        variant="outline-primary"
                    >
                        <FolderSymlink size={18}/>
                    </Button>                                       
                </OverlayTrigger>                                          

                    <span />                     

                <OverlayTrigger   overlay={ <Tooltip id="btn-view-cancelar-pedido">Bloquear/Desbloquear</Tooltip> }>                              
                    <Button onClick={() => bloquearPersonalizacaoClick(personalizacao)} 
                        disabled={nivelUsuario !== "1" && nivelUsuario !== "3"}                                      
                        variant="outline-danger"
                    >
                        {personalizacao.bloqueado === 1 ?     
                            <Lock size={18}/>                 
                            : <Unlock size={18} />  
                        }                           
                    </Button>                                       
                </OverlayTrigger>                                          

                    <span />                    
                { isAcesso && <OverlayTrigger   overlay={<Tooltip id="btn-view-liberar-chave-acesso">Liberar Chave de Registro</Tooltip> }>                              
                    <Button onClick={() => liberarChaveRegistroClick(personalizacao)} 
                        disabled={nivelUsuario !== "1" || personalizacao.chaveRegistro.length === 0}                                      
                        variant="outline-secondary"
                    >                                                
                        <Key size={18}/>
                    </Button>                                       
                </OverlayTrigger> }       
            </div>
        </td>
    )
}