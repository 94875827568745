import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import { Button, Form, Row, Col, FloatingLabel, Alert } from "react-bootstrap";
import { CurrencyDollar, DashLg, PlusLg } from "react-bootstrap-icons";
import styles from "./NovoPedidoBmaPonto.module.css";
import ModalNS from "../../Components/ModalNS";

const NovoPedidoBmaPonto = () => {

    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');   

    const { mensagemGlobal, setMensagemGlobal, setTipoMensagemGlobal, tipoMensagemGlobal } = React.useContext(MyContext);

    const [loading, setLoading] = React.useState(false);
    const [modalNS, setModalNS] = React.useState(false);
    const [nsSelecionado, setNsSelecionado] = React.useState(false);
    const [qtdeLicencas, setQtdeLicencas] = React.useState(0);

    const [listaNS, setListaNS] = React.useState([]);
    const [distribuidores, setDistribuidores] = React.useState([]);
    const [clientes, setClientes] = React.useState([]);
    const [versao, setVersao] = React.useState('');
    const [versaoEditada, setVersaoEditada] = React.useState('');

    const [distribuidorSalvo, setDistribuidorSalvo] = React.useState('');
    const [clienteSalvo, setClienteSalvo] = React.useState('');
    const [distribuidorSelecionado, setDistribuidorSelecionado] = React.useState('');
    const [clienteSelecionado, setClienteSelecionado] = React.useState('');
    const [tipoPedido, setTipoPedido] = React.useState('');
    const [apelido, setApelido] = React.useState('Utilizado na personalização');
    const [numeroSerie, setNumeroSerie] = React.useState('000000');
    const [faixaFuncionarios, setFaixaFuncionarios] = React.useState(10);
    const [tipoPlano, setTipoPlano] = React.useState('');
    const [usuarioAdicional, setUsuarioAdicional] = React.useState(0);
    const [tipoBanco, setTipoBanco] = React.useState('');
    const [portaria671, setPortaria671] = React.useState(false);
    const [integradorRep, setIntegradorRep] = React.useState(false);
    const [moduloEpi, setModuloEpi] = React.useState(false);
    const [wacesso, setWacesso] = React.useState(false);
    const [wgep, setWgep] = React.useState(false);
    const [contrato, setContrato] = React.useState(true);
    const [observacao, setObservacao] = React.useState('');
    const [valorPedido, setValorPedido] = React.useState('0.00');
    const [valorMensal, setValorMensal] = React.useState('0.00');
    const [inscricaoCliente, setInscricaoCliente] = React.useState('');

    React.useEffect(() => {
        async  function pesquisarDistribuidores() { 
               setLoading(true);                  
               if  (nivelUsuario !== "1") {
                    const resp = await api.get(`/Distribuidor/${idDistribuidor}`);  
                    const novoArray = []; 
                    novoArray.unshift(resp.data);
                    setDistribuidores(novoArray);            
               } else {
                      const response = await api.get('/Distribuidor');
                      const registrosFiltrados = response.data.filter((distribuidor) => 
                            distribuidor.statusEmpresa === 1);          
                      registrosFiltrados.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));
                      setDistribuidores(registrosFiltrados);            
               }    
               setLoading(false);       
        }

        async function pesquisarVersaoAtual() {
            const tipoSistema = 1;
            const response = await api.get(`/Versao/retornar-versao-atual?tipoSistema=${tipoSistema}`);   
            setVersao(response.data);            
          //  console.log(response.data);
            const numeroVersaoEditada = response.data.numeroVersao.toString();
            const iniVer = numeroVersaoEditada.substring(2,0);
            const fimVer = numeroVersaoEditada.substring(2);
            setVersaoEditada(iniVer + "." + fimVer);            
        }

        pesquisarDistribuidores();            
        pesquisarVersaoAtual();

    }, []);    

     React.useEffect(() => {
        if  (distribuidorSelecionado) {
            loadClientes(distribuidorSelecionado);
        }
    }, [distribuidorSelecionado])


    async function loadClientes(id) {
            const response = await api.get(`/Cliente/${id}/A`);
            const registrosFiltrados = response.data.filter((cliente) => 
                  cliente.statusEmpresa === 1);          
            registrosFiltrados.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));
            setClientes(registrosFiltrados);            
            setQtdeLicencas(0);
    };    

    function getValueDistribuidor(ev) {
        const idSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setDistribuidorSelecionado(idSelected);     
        setDistribuidorSalvo(idSelected);       
        setApelido('Utilizado na personalização');
    };  

    function getValueCliente(ev) {
        const idClienteSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setClienteSelecionado(idClienteSelected); 
        setClienteSalvo(idClienteSelected);
        setQtdeLicencas(0);
        if  (idClienteSelected) {
            buscarCliente(idClienteSelected);      
        }
    };  

    function adicionar() {
        if  (faixaFuncionarios > 75) {
             setFaixaFuncionarios((faixaFuncionarios => faixaFuncionarios + 50));
        }
        else if (faixaFuncionarios >= 25) {
             setFaixaFuncionarios((faixaFuncionarios => faixaFuncionarios + 25));       
        }
        else setFaixaFuncionarios(faixaFuncionarios => faixaFuncionarios + 15);   
    }

    function subtrair() {        
        if  (faixaFuncionarios > 100) {
            setFaixaFuncionarios((faixaFuncionarios => faixaFuncionarios - 50));        
        }
        else if (faixaFuncionarios >= 50) {
             setFaixaFuncionarios((faixaFuncionarios => faixaFuncionarios - 25));
        }
        else if (faixaFuncionarios === 25) {
            setFaixaFuncionarios(10);   
        }
    }

    function buscarCliente(id) {
        (async () => {
            try {   
                setModalNS(false);
                setNsSelecionado(false);
                setListaNS(null);
                setQtdeLicencas(0);
                const response = await api.get(`/Cliente/${id}`);    
                setApelido(response.data.nomeFantasia);   
                setInscricaoCliente(response.data.numeroInscricao);           
                if  (tipoPedido === "1") {
                    const retorno = await 
                          api.get(`/LicencaPonto/retornar-ns-cliente?id=${response.data.id}`);   
                    const qtdeRegistros = retorno.data.length;   
                    setQtdeLicencas(qtdeRegistros);                 

                    if  (qtdeRegistros === 1)  {
                        setNumeroSerie(retorno.data[0].numeroSerie);
                        setFaixaFuncionarios(retorno.data[0].faixaFuncionarios);
                        setTipoBanco(retorno.data[0].tipoBancoDados);
                        setTipoPlano(retorno.data[0].tipoInstalacao);

                        var totalUsuarios = retorno.data[0].quantidadeUsuarios;
                        if  (totalUsuarios > 0) {
                             setUsuarioAdicional(totalUsuarios - 1);
                        }
                        else setUsuarioAdicional(0);

                        if  (retorno.data[0].temContrato === 1)
                            setContrato(true)
                        else
                            setContrato(false);    
    
                        if  (retorno.data[0].portaria671 === 1)
                            setPortaria671(true)
                        else
                            setPortaria671(false);    
        
                        if  (retorno.data[0].integradorRep === 1)
                            setIntegradorRep(true)
                        else
                            setIntegradorRep(false);    
        
                        if  (retorno.data[0].moduloEpi === 1)
                            setModuloEpi(true)
                        else
                            setModuloEpi(false);    
        
                        if  (retorno.data[0].integracaoAcesso === 1)
                            setWacesso(true)
                        else
                            setWacesso(false);    
        
                        if  (retorno.data[0].integracaoFolha === 1)
                            setWgep(true)
                        else
                            setWgep(false);    
    
                    }
                    else if  (qtdeRegistros > 1) {
                        setListaNS(retorno.data);
                        setModalNS(true);
                    }
                }               

            } catch (error) {
            }            
        }
        )();
    }

    function modalNumeroSerie() {
       setNsSelecionado(true);
    }

    async function atualizarPedido() {
        setDistribuidorSalvo(distribuidorSalvo);
        setClienteSelecionado(clienteSalvo);
        setNsSelecionado(false);

        const retorno = await 
              api.get(`/LicencaPonto/retornar-licenca?numeroSerie=${numeroSerie}&inscricao=${inscricaoCliente}`);   

        setFaixaFuncionarios(retorno.data.faixaFuncionarios);
        setTipoBanco(retorno.data.tipoBancoDados);
        setTipoPlano(retorno.data.tipoInstalacao);

        var totalUsuarios = retorno.data.quantidadeUsuarios;
        if  (totalUsuarios > 0) {
             setUsuarioAdicional(totalUsuarios - 1);
        }
        else setUsuarioAdicional(0);

        if  (retorno.data.temContrato === 1)
            setContrato(true)
        else
            setContrato(false);    

        if  (retorno.data.portaria671 === 1)
            setPortaria671(true)
        else
            setPortaria671(false);    

        if  (retorno.data.integradorRep === 1)
            setIntegradorRep(true)
        else
            setIntegradorRep(false);    

        if  (retorno.data.moduloEpi === 1)
            setModuloEpi(true)
        else
            setModuloEpi(false);    

        if  (retorno.data.integracaoAcesso === 1)
            setWacesso(true)
        else
            setWacesso(false);    

        if  (retorno.data.integracaoFolha === 1)
            setWgep(true)
        else
            setWgep(false);    

    }

    async function verificarTotais(ev) {
        ev.preventDefault();

        const dataAtual = new Date();       
        let regPedido = {
            Id: 0,
            FaixaFuncionarios: Number(faixaFuncionarios),            
            Portaria671: portaria671 ? 1 : 0,       
            IntegradorRep: integradorRep ? 1 : 0,       
            Supervisor: tipoPlano === "2" ? 1 : 0,
            PortalColaborador: tipoPlano === "2" ? 1 : 0,
            AplicativoRegistrador: tipoPlano === "2" ? 1 : 0,
            ColetorVirtual: 0,
            QuantidadeColetorVirtual: 0,
            UsuariosAdicionais: Number(usuarioAdicional),
            ModuloEpi: moduloEpi ? 1 : 0,
            DistribuidorId: Number(distribuidorSelecionado),    
            TipoPedido: Number(tipoPedido),
            ClienteId: Number(clienteSelecionado),    
            NumeroSerie: Number(numeroSerie),
            StatusPedido: 0,
            TipoBancoDados: Number(tipoBanco),
            TipoInstalacao: Number(tipoPlano),
            VersaoSistema: versaoEditada,
            VersaoRelease: versao.release,
            DataPedido: dataAtual,
            Observacao: observacao,
            MotivoCancelamento: '',
            NumeroOrdemCompra: '',
            ValorBruto: 0,
            ValorDesconto: 0,
            ValorLiquido: 0,
            ValorMensal: 0,
            IntegracaoAcesso: wacesso ? 1 : 0,
            IntegracaoFolha: wgep ? 1 : 0,
            IntegracaoPonto: 0,
            Contrato: contrato ? 1 : 0,
            EnviarEmailPedidoLiberado: 0
        }                   

        const ret = await api.post("CalculoPonto/", 
                    JSON.stringify(regPedido), 
              {
                  headers: { 'Content-Type': 'application/json'}
              });

        setValorPedido(ret.data.valorBruto);
        setValorMensal(ret.data.valorMensal);

    }

    async function handleSubmit(ev) {
        ev.preventDefault();
        setMensagemGlobal(null);

        if  (tipoPedido !== "0" && tipoPedido !== "1") {
            setMensagemGlobal("Faltou Selecionar o Tipo do Pedido");
            setTipoMensagemGlobal("danger");
            return;
        }
        if  (tipoPlano.length === 0) {
            setMensagemGlobal("Faltou Selecionar o Tipo do Plano");
            setTipoMensagemGlobal("danger");
            return;
        }
        
        if  (tipoBanco.length === 0) {
            setMensagemGlobal("Faltou Selecionar o Banco de Dados");
            setTipoMensagemGlobal("danger");
            return;
        }

        if  (distribuidorSelecionado === '' || distribuidorSelecionado === null || clienteSelecionado === '' || clienteSelecionado === null) {
            setMensagemGlobal("Faltou Selecionar Distribuidor e/ou Cliente");
            setTipoMensagemGlobal("danger");
            return;
        }

        const dataAtual = new Date();     
        let regPedido = {
            Id: 0,
            FaixaFuncionarios: Number(faixaFuncionarios),            
            Portaria671: portaria671 ? 1 : 0,       
            IntegradorRep: integradorRep ? 1 : 0,       
            Supervisor: tipoPlano === "2" ? 1 : 0,
            PortalColaborador: tipoPlano === "2" ? 1 : 0,
            AplicativoRegistrador: tipoPlano === "2" ? 1 : 0,
            ColetorVirtual: 0,
            QuantidadeColetorVirtual: 0,
            UsuariosAdicionais: Number(usuarioAdicional),
            ModuloEpi: moduloEpi ? 1 : 0,
            DistribuidorId: Number(distribuidorSelecionado),    
            TipoPedido: Number(tipoPedido),
            ClienteId: Number(clienteSelecionado),    
            NumeroSerie: Number(numeroSerie),
            StatusPedido: 0,
            TipoBancoDados: Number(tipoBanco),
            TipoInstalacao: Number(tipoPlano),
            VersaoSistema: versaoEditada,
            VersaoRelease: versao.release,
            DataPedido: dataAtual,
            Observacao: observacao,
            MotivoCancelamento: '',
            NumeroOrdemCompra: '',
            ValorBruto: Number(valorPedido),
            ValorDesconto: 0,
            ValorLiquido: 0,
            ValorMensal: Number(valorMensal),
            IntegracaoAcesso: wacesso ? 1 : 0,
            IntegracaoFolha: wgep ? 1 : 0,
            IntegracaoPonto: 0,
            Contrato: contrato ? 1 : 0,
            QuantidadeParcelas: 1,
            EnviarEmailPedidoLiberado: 0
        }              
      
        const ret = await api.post("BmaPonto/", 
                    JSON.stringify(regPedido), 
              {
                  headers: { 'Content-Type': 'application/json'}
              });

        setMensagemGlobal(ret.data);
        setTipoMensagemGlobal("success");               
        navigate("/pedidosponto");

    }


    if  (loading) return <Loading />

    if  (nsSelecionado && modalNS) return <ModalNS modalNS={modalNS} setModalNS={setModalNS} numeroSerie={numeroSerie} 
                                  setNumeroSerie={setNumeroSerie} titleModal={apelido} 
                                  listaNS={listaNS}  />
    if  (nsSelecionado) {
        atualizarPedido();
    }


    return (
        <section className={styles.form}>
            {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center'}}>Inserir Pedido BmaPonto Vr. {versaoEditada}</Alert.Heading>        
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}>
    
                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Selecione o Tipo de Pedido">
                        <Form.Select value={tipoPedido} onChange={({target}) => setTipoPedido(target.value)}>
                            <option value="" disabled></option>
                            <option value="0">Cópia Nova</option>
                            <option value="1">Atualização</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='8'>
                    <FloatingLabel controlid="floatingInputGrid" label="Selecione o Distribuidor">
                        <Form.Select onChange={getValueDistribuidor} value={distribuidorSelecionado} className=" form-select-control">
                            <option></option>                               
                            {distribuidores.map((distribuidor) => (
                                <option key={distribuidor.id} 
                                        id={distribuidor.id}
                                        value={distribuidor.id}
                                        selected={distribuidor.id === distribuidorSelecionado}>{distribuidor.razaoSocial}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>    
                </Col>

            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
    
                <Col md='7'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Selecione o Cliente">
                        <Form.Select onChange={getValueCliente} value={clienteSelecionado}  className=" form-select-control" >
                            <option></option>
                            {clientes.map((cliente) => (
                                <option key={cliente.id} 
                                        id={cliente.id}
                                        value={cliente.id}
                                        selected={cliente.id === clienteSelecionado}>{cliente.razaoSocial} - {cliente.numeroInscricao}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>                  
                </Col>

                <Col md='5'>
                     <FloatingLabel controlid="floatingInputGrid4" label="Nome Fantasia">
                        <Form.Control placeholder="Apelido" value={apelido} disabled
                                    onChange={({ target }) => setApelido(target.value)}/>
                      </FloatingLabel>      
                </Col>                

            </Row>


            <Row className="g-2" style={{marginBottom: '1rem'}}>

                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Número de Série">
                        <Form.Control placeholder="NS" value={numeroSerie} disabled
                                    onChange={({ target }) => setNumeroSerie(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='2'>
                    <Button onClick={modalNumeroSerie} variant="outline-secondary" className={styles.btn3}
                            disabled={tipoPedido === "0" || qtdeLicencas < 2}>
                        Selecionar NS
                    </Button>
                </Col>                

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Faixa Funcionários">
                        <Form.Control placeholder="Faixa" value={faixaFuncionarios} 
                                    onChange={({ target }) => setFaixaFuncionarios(target.value)}/>
                    </FloatingLabel>      
                </Col>

                <Col md='2'>
                    <Button onClick={adicionar} variant="outline-secondary">
                           <PlusLg size={20}/>
                    </Button>   
                    <span> </span>                    
                    <Button onClick={subtrair} variant="outline-secondary">
                           <DashLg size={20}/>
                    </Button>   
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Banco De Dados">
                        <Form.Select value={tipoBanco} onChange={({target}) => setTipoBanco(target.value)}>
                            <option value="" disabled></option>
                            <option value="1">MySql</option>
                            <option value="2">SqlServer</option>
                            <option value="3">Oracle</option>
                            {/* <option value="4">SQLite</option> */}
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>                

            </Row>


            <Row className="g-2" style={{marginBottom: '1rem'}}>

                <Col md='8'>
                    <FloatingLabel controlid="floatingInputGrid7" label="Selecione o Tipo de Plano">
                        <Form.Select value={tipoPlano} onChange={({target}) => setTipoPlano(target.value)}>
                            <option value="" disabled></option>
                            <option value="0">Licenciado</option>
                            <option value="1">SaaS (Rep-C)</option>
                            <option value="2">SaaS (Rep-P)</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='4'>
                     <FloatingLabel controlid="floatingInputGrid8" label="Usuários Adicionais">
                        <Form.Control placeholder="NS" value={usuarioAdicional} 
                                    onChange={({ target }) => setUsuarioAdicional(target.value)}/>
                      </FloatingLabel>      
                </Col>                

            </Row>

            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Integrações & Módulos Adicionais</Alert.Heading>        
            <br /> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='2'>
                    <Form.Check 
                        type="switch"
                        id="portaria671"
                        label="Portaria 671"                      
                        onChange={() => setPortaria671(!portaria671)}
                        checked={portaria671}
                    />
                </Col>

                <Col md='2'>
                    <Form.Check 
                        type="switch"
                        id="integradorRep"
                        label="Integrador REP"                      
                        onChange={() => setIntegradorRep(!integradorRep)}
                        checked={integradorRep}
                    />
                </Col>

                <Col md='2'>
                    <Form.Check 
                        type="switch"
                        id="epi"
                        label="Módulo EPI"                      
                        onChange={() => setModuloEpi(!moduloEpi)}
                        checked={moduloEpi}
                    />
                </Col>

                <Col md='2'>
                    <Form.Check 
                        type="switch"
                        id="integraWacesso"
                        label="BmaAcesso"                      
                        onChange={() => setWacesso(!wacesso)}
                        checked={wacesso}
                    />
                </Col>

                <Col md='2'>
                    <Form.Check 
                        type="switch"
                        id="integraWge"
                        label="BmaFolha"                      
                        onChange={() => setWgep(!wgep)}
                        checked={wgep}
                    />
                </Col>

                <Col md='2'>
                    <Form.Check 
                        type="switch"
                        id="contrato"
                        label="Contrato"                      
                        onChange={() => setContrato(!contrato)}
                        checked={contrato}
                    />
                </Col>

            </Row>                    

            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Outras Informações</Alert.Heading>        
            <br /> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>

                <Col md='12'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Observações">
                        <Form.Control placeholder="Obs" value={observacao} maxLength={250} 
                                      onChange={({ target }) => setObservacao(target.value)}/>
                    </FloatingLabel>      
                </Col>

            </Row>                    

            <Row className="g-2" style={{marginBottom: '1rem'}}>

                <Col md='3'>

                    <Button onClick={verificarTotais} variant="outline-success" className={styles.btn2}>
                        <CurrencyDollar size={20} />Verificar Totais</Button>               
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Total Pedido (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control placeholder="Total" value={valorPedido} disabled
                                    onChange={({ target }) => setValorPedido(target.value)}/>
                    </FloatingLabel>      
                </Col>

                <Col md='1'>
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Valor Mensal (R$)" style={{fontWeight: 'bold'}}>                       
                        <Form.Control placeholder="Total" value={valorMensal} disabled
                                    onChange={({ target }) => setValorMensal(target.value)}/>
                    </FloatingLabel>      
                </Col>

            </Row>                    


            </Alert>

            <div className={styles.areaBtn}>                
               <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                       disabled={nivelUsuario === "2" || nivelUsuario ==="4"}>Confirmar</Button>               
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )

}

export default NovoPedidoBmaPonto;