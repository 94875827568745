import { HashRouter } from "react-router-dom";
import { MyStorage } from "./Context/MyContext";
import Menu from "./Forms/Menu";
import { Router } from "./Components/Router";

function App() {
  return (
    <HashRouter>
      <MyStorage>
        <Menu />
        <Router />
      </MyStorage>   
    </HashRouter>    
  );
}

export default App;
