import React from "react";
import GraficosIniciais from "../Graficos/GraficosIniciais";

const Home = () => {
    return (
        <>  
            <GraficosIniciais />         
        </>
    )     
}


export default Home;