import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import { Button, Form, Row, Col, FloatingLabel, Alert, Accordion } from "react-bootstrap";
import { CurrencyDollar } from "react-bootstrap-icons";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { format, isValid, parseISO, addDays } from "date-fns";
import styles from "./EditarPedidoBmaAcesso.module.css";

const EditarPedidoBmaAcesso = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidorLogado = window.localStorage.getItem('distribuidorId');   

    const { setMensagemGlobal, setTipoMensagemGlobal } = React.useContext(MyContext);
    
    const [loading, setLoading] = React.useState(false);
    const [mensagem, setMensagem] = React.useState('');
    const [tipoMensagem, setTipoMensagem] = React.useState(false);

    const [clientes, setClientes] = React.useState([]);
    const [idDistribuidor, setIdDistribuidor] = React.useState('');
    const [clienteSelecionado, setClienteSelecionado] = React.useState('');

    const [tipoVertical, setTipoVertical] = React.useState('');
    const [tipoPedido, setTipoPedido] = React.useState('');
    const [apelido, setApelido] = React.useState('Utilizado na personalização');
    const [numeroSerie, setNumeroSerie] = React.useState('000000');
    const [equipamentoAdicional, setEquipamentoAdicional] = React.useState(0);
    const [valorEquipamentoAdicional, setValorEquipamentoAdicional] = React.useState(0);
    const [serverAdicional, setServerAdicional] = React.useState(0);
    const [valorServerAdicional, setValorServerAdicional] = React.useState(0);
    const [valorLicenca, setValorLicenca] = React.useState(0);
    const [agendaWeb, setAgendaWeb] = React.useState(false);
    const [usuariosAgenda, setUsuariosAgenda] = React.useState(0);
    const [tipoBanco, setTipoBanco] = React.useState('');
    const [versao, setVersao] = React.useState('');
    const [release, setRelease] = React.useState('');
    const [integracaoFuncionarios, setIntegracaoFuncionarios] = React.useState(false);
    const [integracaoPacientes, setIntegracaoPacientes] = React.useState(false);
    const [integracaoAlunos, setIntegracaoAlunos] = React.useState(false);
    const [moduloLGPD, setModuloLGPD] = React.useState(false);
    const [valorModuloLGPD, setValorModuloLGPD] = React.useState(0);
    const [wpe, setWpe] = React.useState(false);
    const [portalTerceiros, setPortalTerceiros] = React.useState(false);
    const [locacao, setLocacao] = React.useState(false);
    const [contrato, setContrato] = React.useState(true);
    const [observacao, setObservacao] = React.useState('');
    const [valorPedido, setValorPedido] = React.useState('0.00');
    const [valorMensal, setValorMensal] = React.useState('0.00');
    const [dataPedido, setDataPedido] = React.useState('');
    const [quantidadeParcelas, setQuantidadeParcelas] = React.useState('');
    const [statusPedido, setStatusPedido] = React.useState('');
    const [dataValidade, setDataValidade] = React.useState('');

    const [tipoVerticalLicenca, setTipoVerticalLicenca] = React.useState('');
    const [equipamentoAdicionalLicenca, setEquipamentoAdicionalLicenca] = React.useState(0);
    const [serverAdicionalLicenca, setServerAdicionalLicenca] = React.useState(0);
    const [locacaoLicenca, setLocacaoLicenca] = React.useState(false);
    const [contratoLicenca, setContratoLicenca] = React.useState(false);
    const [usuariosAgendaLicenca, setUsuariosAgendaLicenca] = React.useState(0);
    const [integracaoFuncionariosLicenca, setIntegracaoFuncionariosLicenca] = React.useState(false);
    const [integracaoPacientesLicenca, setIntegracaoPacientesLicenca] = React.useState(false);
    const [integracaoAlunosLicenca, setIntegracaoAlunosLicenca] = React.useState(false);
    const [moduloLGPDLicenca, setModuloLGPDLicenca] = React.useState(false);
    const [wpeLicenca, setWpeLicenca] = React.useState(false);
    const [portalTerceirosLicenca, setPortalTerceirosLicenca] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            try {   
                setLoading(true);
                const response = await api.get(`/BmaAcesso/${id}`);    

             //   O campo StatusPedido e retornado aqui como segurança, para que nenhum usuario acesse diretamente
             //   a URL da edição, modificando o id do Pedido.. Assim testamos se o pedido em questão esta realmente
             //   em Aberto... 

                setIdDistribuidor(response.data.distribuidorId);
                setStatusPedido(response.data.statusPedido);
                setTipoVertical(response.data.tipoVertical);
                setTipoPedido(response.data.tipoPedido);
                setNumeroSerie(response.data.numeroSerie);
                setTipoBanco(response.data.tipoBancoDados);
                setUsuariosAgenda(response.data.usuariosAgenda);
                setEquipamentoAdicional(response.data.equipamentoAdicional);
                setValorEquipamentoAdicional(response.data.valorEquipamentoAdicional);
                setServerAdicional(response.data.serverAdicional);
                setValorServerAdicional(response.data.valorServerAdicional);
                setObservacao(response.data.observacao);
                setValorPedido(response.data.valorBruto);
                setValorMensal(response.data.valorMensal);
                setValorLicenca(response.data.valorLicenca);
                setValorModuloLGPD(response.data.valorModuloLGPD);
                setVersao(response.data.versaoSistema);
                setRelease(response.data.versaoRelease);
                setDataPedido(response.data.dataPedido); 
                
                if (response.data.dataValidade !== '' && response.data.dataValidade !== null) {
                    setDataValidade(format(response.data.dataValidade, 'yyyy-MM-dd'));
                }

                if  (response.data.quantidadeParcelas) {
                    setQuantidadeParcelas(response.data.quantidadeParcelas)
                }

                if  (response.data.contrato === 1)
                    setContrato(true)
                else
                    setContrato(false);    

                if  (response.data.agendaWeb === 1)
                    setAgendaWeb(true)
                else
                    setAgendaWeb(false);    

                if  (response.data.integracaoPonto === 1)
                    setWpe(true)
                else
                    setWpe(false);    

                if  (response.data.moduloLGPD === 1)
                    setModuloLGPD(true)
                else
                    setModuloLGPD(false);    

               if  (response.data.integracaoFuncionarios === 1)
                    setIntegracaoFuncionarios(true)
                else
                    setIntegracaoFuncionarios(false);    

                if  (response.data.integracaoPacientes === 1)
                    setIntegracaoPacientes(true)
                else
                    setIntegracaoPacientes(false);    

                if  (response.data.integracaoAlunos === 1)
                    setIntegracaoAlunos(true)
                else
                    setIntegracaoAlunos(false);    

                if  (response.data.locacao === 1)
                    setLocacao(true)
                else
                    setLocacao(false);    

                if  (response.data.portalTerceiros === 1)
                    setPortalTerceiros(true)
                else
                    setPortalTerceiros(false);    
                    
                loadClientes(response.data.distribuidorId);
                setClienteSelecionado(response.data.clienteId);
                buscarCliente(response.data.clienteId);

                const tipoBusca = "NS";
                const retorno = await api.get(`/LicencaAcesso/${tipoBusca}/${response.data.numeroSerie}`);
                setTipoVerticalLicenca(retorno.data.tipoVertical);
                setUsuariosAgendaLicenca(retorno.data.usuariosAgenda);
                setEquipamentoAdicionalLicenca(retorno.data.equipamentoAdicional);
                setServerAdicionalLicenca(retorno.data.serverAdicional);
                if  (retorno.data.locacao === 1)
                    setLocacaoLicenca("Sim")
                else
                    setLocacaoLicenca("Não");    
                if  (retorno.data.contrato === 1)
                    setContratoLicenca("Sim")
                else
                    setContratoLicenca("Não");    
                if  (retorno.data.integracaoPonto === 1)
                    setWpeLicenca("Sim")
                else
                    setWpeLicenca("Não");    
                if  (retorno.data.moduloLGPD === 1)
                    setModuloLGPDLicenca("Sim")
                else
                    setModuloLGPDLicenca("Não");    
                if  (retorno.data.integracaoFuncionarios === 1)
                    setIntegracaoFuncionariosLicenca("Sim")
                else
                    setIntegracaoFuncionariosLicenca("Não");    
                if  (retorno.data.integracaoPacientes === 1)
                    setIntegracaoPacientesLicenca("Sim")
                else
                    setIntegracaoPacientesLicenca("Não");    
                if  (retorno.data.integracaoAlunos === 1)
                    setIntegracaoAlunosLicenca("Sim")
                else
                    setIntegracaoAlunosLicenca("Não");    
                if  (retorno.data.portalTerceiros === 1)
                    setPortalTerceirosLicenca("Sim")
                else
                    setPortalTerceirosLicenca("Não");    

                setLoading(false);

                const distribuidorLogado = Number(idDistribuidorLogado);
                const distribuidorPedido = Number(response.data.distribuidorId);

                if  ((distribuidorLogado !== distribuidorPedido) && (nivelUsuario === "3" || nivelUsuario === "4")) {
                    setMensagemGlobal("Ops, você não tem permissão para acessar este Pedido !");
                    setTipoMensagemGlobal("danger");
                    navigate("/pedidosacesso");
                }

            } catch (error) {
                alert(error);
            }            
        }
        )();

    }, [id]);                

    React.useEffect(() => {
        if  (tipoVertical)   {
            pesquisarVersaoAtual(tipoVertical);
        }
    }, [tipoVertical])

    async function pesquisarVersaoAtual(tipoVertical) {
        const tipoSistema = 2;
        const response = await api.get(`/Versao/retornar-versao-atual?tipoSistema=${tipoSistema}&tipoVertical=${tipoVertical}`);   
        const numeroVersaoEditada = response.data.numeroVersao.toString(); 
        const iniVer = numeroVersaoEditada.substring(1,0);
        const fimVer = numeroVersaoEditada.substring(1);

        setVersao(iniVer + "." + fimVer);
        setRelease(response.data.release.toString());            
    }

    async function loadClientes(idDistribuidor) {
        const response = await api.get(`/Cliente/${idDistribuidor}/A`);
        response.data.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));
        setClientes(response.data);            
    };   

    function getValueCliente(ev) {
        const idClienteSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setClienteSelecionado(idClienteSelected); 
        if  (idClienteSelected) {
            buscarCliente(idClienteSelected);      
        }
    };  

    function getValueVertical(value) {
        setTipoVertical(value);     

        if  (Number(value) === 2 || Number(value) === 3 || Number(value) === 5 || Number(value) === 6) {
            setAgendaWeb(false)
            setUsuariosAgenda(0);
        }
    }

    function buscarCliente(id) {
        (async () => {
            try {   
                const response = await api.get(`/Cliente/${id}`);    
                setApelido(response.data.nomeFantasia);
                
            } catch (error) {
            }            
        }
        )();
    }

    async function verificarTotais() {

        const dataAtual = new Date();       
        let regPedido = {
            Id: 0,
            TipoVertical: Number(tipoVertical),
            EquipamentoAdicional: Number(equipamentoAdicional),            
            ValorEquipamentoAdicional: Number(valorEquipamentoAdicional),
            ServerAdicional: Number(serverAdicional),            
            ValorServerAdicional: Number(valorServerAdicional),
            AgendaWeb: agendaWeb ? 1 : 0,
            UsuariosAgenda: Number(usuariosAgenda),
            IntegracaoFuncionarios: integracaoFuncionarios ? 1 : 0,
            IntegracaoPacientes: integracaoPacientes ? 1 : 0,
            ModuloLGPD: moduloLGPD ? 1 : 0,
            ValorModuloLGPD: Number(valorModuloLGPD),
            DistribuidorId: Number(idDistribuidor),    
            TipoPedido: Number(tipoPedido),
            ClienteId: Number(clienteSelecionado),    
            NumeroSerie: Number(numeroSerie),
            StatusPedido: 0,
            TipoBancoDados: Number(tipoBanco),
            TipoInstalacao: 0,
            VersaoSistema: versao,
            VersaoRelease: release,
            DataPedido: dataAtual,
            Observacao: observacao,
            MotivoCancelamento: '',
            NumeroOrdemCompra: '',
            ValorBruto: 0,
            ValorDesconto: 0,
            ValorLiquido: 0,
            ValorMensal: 0,
            IntegracaoAcesso: 0,
            IntegracaoFolha: 0,
            IntegracaoPonto: wpe ? 1 : 0,
            Contrato: contrato ? 1 : 0,
            IntegracaoAlunos: integracaoAlunos ? 1 : 0,
            Locacao: locacao ? 1 : 0,
            PortalTerceiros: portalTerceiros ? 1 : 0,
            DataValidade: null,
        }      
        
        if (dataValidade !== '' && locacao) {
            regPedido.DataValidade = dataValidade;
        }

        const ret = await api.post("CalculoAcesso/", 
                    JSON.stringify(regPedido), 
              {
                  headers: { 'Content-Type': 'application/json'}
              });

        setValorLicenca(ret.data.valorLicenca);
        setValorEquipamentoAdicional(ret.data.valorEquipamentoAdicional);
        setValorServerAdicional(ret.data.valorServerAdicional);
        setValorModuloLGPD(ret.data.valorModuloLGPD);
        setValorPedido(ret.data.valorBruto);
        setValorMensal(ret.data.valorMensal);

    }

    async function handleSubmit(ev) {
        ev.preventDefault();

        if  (tipoPedido.length === 0) {
            setMensagem("Faltou Selecionar o Tipo do Pedido");
            setTipoMensagem("danger");
            return;
        }

        if  (tipoBanco.length === 0) {
            setMensagem("Faltou Selecionar o Banco de Dados");
            setTipoMensagem("danger");
            return;
        }

        if  (clienteSelecionado === '' || clienteSelecionado === null) {
            setMensagem("Faltou Selecionar o Cliente");
            setTipoMensagem("danger");
            return;
        }

        if  (tipoVertical.length === 0) {
            setMensagem("Faltou Selecionar o Tipo de Vertical");
            setTipoMensagem("danger");
            return;
        }

        let ns = Number(numeroSerie);
        if  (ns === 0 && Number(tipoPedido) === 1) {
            setMensagem("Faltou Informar o NS !");
            setTipoMensagem("danger");
            return;
        }

        let regPedido = {
            Id: Number(id),
            TipoVertical: Number(tipoVertical),
            EquipamentoAdicional: Number(equipamentoAdicional),            
            ValorEquipamentoAdicional: Number(valorEquipamentoAdicional),
            ServerAdicional: Number(serverAdicional),            
            ValorServerAdicional: Number(valorServerAdicional),
            AgendaWeb: agendaWeb ? 1 : 0,
            UsuariosAgenda: agendaWeb ? Number(usuariosAgenda) : 0,
            IntegracaoFuncionarios: integracaoFuncionarios ? 1 : 0,
            IntegracaoPacientes: integracaoPacientes ? 1 : 0,
            ModuloLGPD: moduloLGPD ? 1 : 0,
            ValorModuloLGPD: Number(valorModuloLGPD),
            DistribuidorId: Number(idDistribuidor),    
            TipoPedido: Number(tipoPedido),
            ClienteId: Number(clienteSelecionado),    
            NumeroSerie: Number(numeroSerie),
            StatusPedido: 0,
            TipoBancoDados: Number(tipoBanco),
            TipoInstalacao: 0,
            VersaoSistema: versao,
            VersaoRelease: release,
            DataPedido: dataPedido,
            Observacao: observacao,
            MotivoCancelamento: '',
            NumeroOrdemCompra: '',
            ValorBruto: Number(valorPedido),
            ValorDesconto: 0,
            ValorLiquido: Number(valorPedido),
            ValorMensal: Number(valorMensal),
            IntegracaoPonto: wpe ? 1 : 0,
            IntegracaoFolha: 0,
            IntegracaoAcesso: 0,
            Contrato: contrato ? 1 : 0,
            ValorLicenca: Number(valorLicenca),
            QuantidadeParcelas: quantidadeParcelas ? Number(quantidadeParcelas) : 1,
            IntegracaoAlunos: integracaoAlunos ? 1 : 0,
            Locacao: locacao ? 1 : 0,
            PortalTerceiros: portalTerceiros ? 1 : 0,
            DataValidade: null,
        }      
        
        if (dataValidade !== '' && locacao) {
            regPedido.DataValidade = dataValidade;
        }

        const ret = await api.put("BmaAcesso/", 
                    JSON.stringify(regPedido), 
              {
                  headers: { 'Content-Type': 'application/json'}
              });
        setMensagemGlobal(ret.data);
        setTipoMensagemGlobal("success");
       
        navigate("/pedidosacesso");

    }

    const handleDataValidadeChange = (target) => {
        let parsedDate = parseISO(target.value)

        if (isValid(parsedDate)) {
            setDataValidade(format(addDays(target.value, 1), 'yyyy-MM-dd'));
        } else {
            setDataValidade('');
        }
    }


    if  (loading) return <Loading />

    return (
        <section className={styles.form}>
            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center'}}>Editar Pedido BmaAcesso</Alert.Heading>        
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}>
    
                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Tipo de Pedido">
                        <Form.Select value={tipoPedido} onChange={({target}) => setTipoPedido(target.value)}>
                            <option value="" disabled></option>
                            <option value="0">Cópia Nova</option>
                            <option value="1">Atualização</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Número de Série">                               
                            <Form.Control placeholder="NS" value={numeroSerie} 
                                    disabled={nivelUsuario !== "1"}
                                    onChange={({ target }) => setNumeroSerie(target.value)}/>                       
                    </FloatingLabel>      
                </Col>                

                <Col md='5'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Banco De Dados">
                        <Form.Select value={tipoBanco} onChange={({target}) => setTipoBanco(target.value)}>
                            <option value="" disabled></option>
                            <option value="1">MySql</option>
                            <option value="2">SqlServer</option>
                            <option value="3">Oracle</option>
                            {/* <option value="4">SQLite</option> */}
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>                
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
    
                <Col md='7'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Selecione o Cliente">
                        <Form.Select onChange={getValueCliente}  className=" form-select-control" >
                            <option></option>
                            {clientes.map((cliente) => (
                                <option key={cliente.id} 
                                        id={cliente.id}
                                        value={cliente.id}
                                        selected={cliente.id === clienteSelecionado}>{cliente.razaoSocial}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>                  
                </Col>

                <Col md='5'>
                     <FloatingLabel controlid="floatingInputGrid4" label="Nome Fantasia">
                        <Form.Control placeholder="Apelido" value={apelido} disabled
                                    onChange={({ target }) => setApelido(target.value)}/>
                      </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='6'>
                    <FloatingLabel controlid="floatingInputGrid7" label="Selecione a Vertical">
                        <Form.Select value={tipoVertical} onChange={({ target }) => getValueVertical(target.value)}>
                            <option value="" disabled></option>
                            <option value="1">Empresa</option>
                            <option value="2">Refeitório</option>
                            <option value="3">Academia</option>
                            <option value="4">Condomínio</option>
                            <option value="5">Hospital</option>
                            <option value="6">Escola</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid6" label="Versão">
                            <Form.Control placeholder="Ver" value={versao} 
                                    disabled={nivelUsuario !== "1"}
                                    onChange={({ target }) => setVersao(target.value)}/>                        
                    </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid7" label="Release">
                            <Form.Control placeholder="Release" value={release} 
                                    disabled={nivelUsuario !== "1"}
                                    onChange={({ target }) => setRelease(target.value)}/>             
                      </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='6'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Equipamentos Adicionais">
                        <Form.Control placeholder="Nr.Equip." value={equipamentoAdicional} 
                                    onChange={({ target }) => setEquipamentoAdicional(target.value)}/>
                    </FloatingLabel>      
                </Col>

                <Col md='6'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Server Adicional">
                        <Form.Control placeholder="Nr.Equip." value={serverAdicional} 
                                    onChange={({ target }) => setServerAdicional(target.value)}/>
                    </FloatingLabel>      
                </Col>
            </Row>

            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Integrações & Módulos Adicionais</Alert.Heading>        
            <br /> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="locacao"
                        label="Modalidade Locação"                      
                        onChange={() => setLocacao(!locacao)}
                        checked={locacao}                       
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integracaoAlunos"
                        label="Alunos"                      
                        onChange={() => setIntegracaoAlunos(!integracaoAlunos)}
                        checked={integracaoAlunos}                       
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integracaoFuncionarios"
                        label="Funcionários"                      
                        onChange={() => setIntegracaoFuncionarios(!integracaoFuncionarios)}
                        checked={integracaoFuncionarios}                       
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integracaoPacientes"
                        label="Pacientes"                      
                        onChange={() => setIntegracaoPacientes(!integracaoPacientes)}
                        checked={integracaoPacientes}
                    />
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
    
                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integraWpe"
                        label="BmaPonto"                      
                        onChange={() => setWpe(!wpe)}
                        checked={wpe}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="lgpd"
                        label="Módulo LGPD"                      
                        onChange={() => setModuloLGPD(!moduloLGPD)}
                        checked={moduloLGPD}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="terceiros"
                        label="Portal Terceiros"                      
                        onChange={() => setPortalTerceiros(!portalTerceiros)}
                        checked={portalTerceiros}                       
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="contrato"
                        label="Contrato"                      
                        onChange={() => setContrato(!contrato)}
                        checked={contrato}
                    />
                </Col>
            </Row>                    

            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Outras Informações</Alert.Heading>        
            <br /> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='2' style={{paddingTop: '12px'}}>
                    <Form.Check 
                        type="switch"
                        id="agendaWeb"
                        label="Agenda Web"                      
                        onChange={() => setAgendaWeb(!agendaWeb)}
                        checked={agendaWeb}
                        disabled={tipoVertical === 2 || tipoVertical === 3 || tipoVertical === 6}
                    />
                </Col>

                <Col md='5'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Usuários Agenda">
                        <Form.Control placeholder="Usuarios Agenda Web" value={usuariosAgenda} 
                                    onChange={({ target }) => setUsuariosAgenda(target.value)}
                                    disabled={tipoVertical === "2" || tipoVertical === "3" || tipoVertical === "6"}                                              
                                    />
                    </FloatingLabel>      
                </Col>

                <Col md='5'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Data de Validade">
                        <Form.Control 
                            value={dataValidade}
                            type="date" 
                            onChange={({ target }) => handleDataValidadeChange(target)} 
                            disabled={!locacao}
                        />
                    </FloatingLabel>      
                </Col>
            </Row>  
            <Row style={{marginBottom: '1rem'}}>
                <Col md='2'>
                    <Alert.Heading style={{textAlign: 'left', fontSize: 'small', color: 'darkSlateBlue', fontWeight: 'bold'}}>
                        (*)Agenda Web disponível nas Verticais Empresa, Condomínio e Hospital.
                    </Alert.Heading>                    
                </Col>
                <Col md='10' >
                    <FloatingLabel controlid="floatingInputGrid11" label="Observações">
                        <Form.Control placeholder="Obs" as="textarea" style={{height: '100px'}} value={observacao} 
                            onChange={({ target }) => setObservacao(target.value)} 
                        />
                    </FloatingLabel>      
                </Col>
            </Row>                   

            {nivelUsuario === "1" &&
                <Row className="g-2" style={{marginBottom: '1rem'}}>    
                    <Col md='2' />                
                    <Col md='10'>
                        <FloatingLabel controlid="floatingInputGrid3" label="Quantidade de Parcelas">
                            <Form.Select value={quantidadeParcelas} onChange={({target}) => setQuantidadeParcelas(target.value)}>
                                <option value="" disabled></option>
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                            </Form.Select>
                        </FloatingLabel>                    
                    </Col>
                </Row>
            }       

            <br />                
            <Row className="g-2" style={{marginBottom: '1rem'}}>

                <Col md='2'>
                    <Button onClick={verificarTotais} variant="outline-success" className={styles.btn2}>
                        <CurrencyDollar size={20} />Verificar Totais</Button>               
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Valor da Licença (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control placeholder="Total" value={valorLicenca} 
                                      onChange={({ target }) => setValorLicenca(target.value)}
                                      disabled={nivelUsuario !== "1"}
                        />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                      <FloatingLabel controlid="floatingInputGrid8" label="Valor Equip.Adicional (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control placeholder="NS" value={valorEquipamentoAdicional} 
                                      onChange={({ target }) => setValorEquipamentoAdicional(target.value)}
                                      disabled={nivelUsuario !== "1"}
                        />
                      </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid10" label="Valor Server Adicional (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control placeholder="NS" value={valorServerAdicional} 
                                      onChange={({ target }) => setValorServerAdicional(target.value)}
                                      disabled={nivelUsuario !== "1"}
                        />
                      </FloatingLabel>      
                </Col>                
            </Row>                

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='2' />

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid14" label="Valor Módulo LGPD (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control value={valorModuloLGPD} 
                                    onChange={({ target }) => setValorModuloLGPD(target.value)}
                                    disabled={nivelUsuario !== "1"}
                                    />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Total Pedido (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control placeholder="Total" value={valorPedido} 
                                      onChange={({ target }) => setValorPedido(target.value)}
                                      disabled={nivelUsuario !== "1"}
                        />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid18" label="Valor Mensal (R$)" style={{fontWeight: 'bold'}}>                       
                        <Form.Control placeholder="Total" value={valorMensal} 
                                    onChange={({ target }) => setValorMensal(target.value)}
                                    disabled={nivelUsuario !== "1"}
                        />
                    </FloatingLabel>      
                </Col>
            </Row>                    

           </Alert>

           <Alert style={{background: 'whitesmoke', color: 'black'}}>

               <Accordion flush>
                   <Accordion.Item eventKey="0" background='blue' fontWeight='bolder'>
                        <Accordion.Header style={{textAlign: 'center'}}>INFORMAÇÕES DA ULTIMA LICENÇA...</Accordion.Header>
                        <AccordionBody>

                        <Row className="g-2" style={{marginBottom: '1rem'}}>

                            <Col md='4'>
                                <FloatingLabel controlid="floatingInputGrid7" label="Vertical">
                                    <Form.Select value={tipoVerticalLicenca} onChange={({ target }) => setTipoVertical(target.value)} disabled>
                                        <option value="" disabled></option>
                                        <option value="1">Empresa</option>
                                        <option value="2">Refeitório</option>
                                        <option value="3">Academia</option>
                                        <option value="4">Condomínio</option>
                                        <option value="5">Hospital</option>
                                        <option value="6">Escola</option>
                                    </Form.Select>
                                </FloatingLabel>                    
                            </Col>                

                            <Col md='2'>
                                <FloatingLabel controlid="floatingInputGrid48" label="Locação" style={{fontWeight: 'bold'}}>                       
                                    <Form.Control placeholder="locacao" value={locacaoLicenca} disabled />
                                </FloatingLabel>      
                            </Col>

                            <Col md='2'>
                                <FloatingLabel controlid="floatingInputGrid49" label="Contrato" style={{fontWeight: 'bold'}}>                       
                                    <Form.Control placeholder="Contrato" value={contratoLicenca} disabled />
                                </FloatingLabel>      
                            </Col>

                            <Col md='2'>
                                <FloatingLabel controlid="floatingInputGrid9" label="Equip.Adicional" style={{fontWeight: 'bold'}}>
                                   <Form.Control value={equipamentoAdicionalLicenca} 
                                            onChange={({target}) => setEquipamentoAdicionalLicenca(target.value)} disabled>
                                   </Form.Control>
                               </FloatingLabel>                    
                            </Col>

                            <Col md='2'>
                                <FloatingLabel controlid="floatingInputGrid9" label="Server Adicional" style={{fontWeight: 'bold'}}>
                                    <Form.Control value={serverAdicionalLicenca} 
                                                onChange={({target}) => setServerAdicionalLicenca(target.value)} disabled>
                                    </Form.Control>
                                </FloatingLabel>                    
                            </Col>
                        </Row>                    

                        <Row className="g-2" style={{marginBottom: '1rem'}}>
                            <Col md='2'>
                                <FloatingLabel controlid="floatingInputGrid12" label="Usuários Agenda">
                                    <Form.Control placeholder="Usuarios Agenda Web" value={usuariosAgendaLicenca} 
                                                onChange={({ target }) => setUsuariosAgendaLicenca(target.value)}
                                                disabled />
                                </FloatingLabel>      
                            </Col>

                            <Col md='2'>
                                <FloatingLabel controlid="floatingInputGrid66" label="Integ.Alunos" style={{fontWeight: 'bold'}}>                       
                                    <Form.Control placeholder="intAlunos" value={integracaoAlunosLicenca} disabled />
                                </FloatingLabel>      
                            </Col>

                            <Col md='2'>
                                <FloatingLabel controlid="floatingInputGrid67" label="Integ.Pacientes" style={{fontWeight: 'bold'}}>                       
                                    <Form.Control placeholder="intPacientes" value={integracaoPacientesLicenca} disabled />
                                </FloatingLabel>      
                            </Col>

                            <Col md='2'>
                                <FloatingLabel controlid="floatingInputGrid68" label="Integ.Funcionários" style={{fontWeight: 'bold'}}>                       
                                    <Form.Control placeholder="intFunc" value={integracaoFuncionariosLicenca} disabled />
                                </FloatingLabel>      
                            </Col>

                            <Col md='2'>
                                <FloatingLabel controlid="floatingInputGrid69" label="Módulo LGPD" style={{fontWeight: 'bold'}}>                       
                                    <Form.Control placeholder="moduloLGPD" value={moduloLGPDLicenca} disabled />
                                </FloatingLabel>      
                            </Col>

                            <Col md='2'>
                                <FloatingLabel controlid="floatingInputGrid70" label="Portal Terceiros" style={{fontWeight: 'bold'}}>                       
                                    <Form.Control placeholder="portalTerceiros" value={portalTerceirosLicenca} disabled />
                                </FloatingLabel>      
                            </Col>

                        </Row>                    

                        </AccordionBody>
                    </Accordion.Item>
                </Accordion>

           </Alert>

           {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem} /> }                                 

           <div className={styles.areaBtn}>    
            <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                    disabled={nivelUsuario === "2" || nivelUsuario === "4" || statusPedido > 0}>Atualizar</Button>
            <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
           </div>    

        </section>
    )

}

export default EditarPedidoBmaAcesso;
