import React from "react";
import api from "../../Api/apiAxios";
import { Button, Table } from "react-bootstrap";
import { CardText, Pencil, StopCircle } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import HeaderContratos from "../../Components/HeaderContratos";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'
import { Paginacao, pesquisarPrevious } from "../../Components/Paginacao";
import styles from "./ContratosIndex.module.css";
import { TableItemName } from "../../Components/TableItemName";

const ContratosIndex = () => {

    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');   

    const { mensagemGlobal, setMensagemGlobal, tipoMensagemGlobal, setListaGlobalClientes, 
            listaGlobalContratos, setListaGlobalContratos, setDistribuidorIdGlobal, itensPorPagina } = React.useContext(MyContext);

    const [pesquisa, setPesquisa] = React.useState('');
    const [contratos, setContratos] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [tipoPesquisa, setTipoPesquisa] = React.useState('*');
    const [botaoAdicionar, setBotaoAdicionar] = React.useState('');
    const [totalContratos, setTotalContratos] = React.useState(0);

    const [registroInicial, setRegistroInicial] = React.useState(1);
    const [registroFinal, setRegistroFinal] = React.useState(20);
    const [quantidadeRegistros, setQuantidadeRegistros] = React.useState(0);

    const [totalContratosManutencao, setTotalContratosManutencao] = React.useState(0);
    const [totalContratosLocacao, setTotalContratosLocacao] = React.useState(0);
    const [totalContratosSaas, setTotalContratosSaas] = React.useState(0);

    React.useEffect(() => {     
        setListaGlobalClientes(null);
        setDistribuidorIdGlobal('');
        if  (nivelUsuario === "2" || nivelUsuario === "4")    
        {
            setBotaoAdicionar("hide");
        }
        else {
            setBotaoAdicionar(' Novo Contrato');
        }

        async  function pesquisar() { 
            setLoading(true);                  

            try {
                if  (listaGlobalContratos === null) {
                    const response = await 
                          api.get(`/Contrato/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}&regInicial=${registroInicial}&itensPorPagina=${999999}`);   
                    var qtdeRegistros = response.data[0].valorFixo;
                    setQuantidadeRegistros(qtdeRegistros);             
      
                    const response2 = await 
                          api.get(`/Contrato/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}&regInicial=${registroInicial}&itensPorPagina=${itensPorPagina}`);   
                    setContratos(response2.data);            
      
                }
                else {
                    setContratos(listaGlobalContratos);                
                }
            } catch (e) {
                alert(e);
            } finally {
                setLoading(false); 
            }      
        }

        pesquisar();            
    }, []);            

    function pesquisarClick(event) { 
        event.preventDefault();     
        (async () => {
            try {
                setLoading(true);             

                if  (tipoPesquisa === "reiniciar")
                {
                    setRegistroInicial(0);
                    pesquisarPrevious();
                    setLoading(false);
                    setListaGlobalContratos(null);
                    return;
                }

                if  (pesquisa.length === 0) {                  

                    if  (tipoPesquisa === "ativos" || tipoPesquisa === "cancelados" || tipoPesquisa === "totais") {
                        const pesq = 'status';
                        const response = await 
                              api.get(`/Contrato/parametros-consulta?nivel=${nivelUsuario}&tipo=${tipoPesquisa}&args=${pesq}`);   
                        setContratos(response.data);                                   
                        totalizar(response.data);
                        setListaGlobalContratos(null);                    
                    }
                    else {
                        const response = await 
                              api.get(`/Contrato/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}&regInicial=${registroInicial}&itensPorPagina=${itensPorPagina}`);   
                        setContratos(response.data);            
                        setListaGlobalContratos(null);
                    }
                }
                else {
                    const response = await 
                          api.get(`/Contrato/parametros-consulta?nivel=${nivelUsuario}&tipo=${tipoPesquisa}&args=${pesquisa}`);   
                    setContratos(response.data);   
                    setListaGlobalContratos(response.data);                             
                }
                setPesquisa('');
                setLoading(false);              

            } catch (error) {
                alert(error);
            }
        }
        )();
    }

    function totalizar(data) {
        var sum = 0;
        var sum1 = 0;
        var sum2 = 0;
        var sum3 = 0;

        for(var i =0;i<data.length;i++){ 
            if  (data[i].statusContrato === 1) {
                sum+=data[i].valor; 

                if  (data[i].tipoContrato === 0) {
                    sum1+=data[i].valor;
                }
                if  (data[i].tipoContrato === 1) {
                    sum2+=data[i].valor;
                }
                if  (data[i].tipoContrato === 2) {
                    sum3+=data[i].valor;
                }

            }
        }         
        setTotalContratos(sum.toFixed(2));
        setTotalContratosManutencao(sum1.toFixed(2));
        setTotalContratosLocacao(sum2.toFixed(2));
        setTotalContratosSaas(sum3.toFixed(2));
    }

    function adicionarClick(event) {
        event.preventDefault();
        navigate("/novocontrato");
    }

    function editarClick(contrato) {              
        navigate(`/editarcontrato/${contrato.id}`);
    }

    function viewContrato(contrato) {
        navigate(`/viewcontrato/${contrato.id}`);
    }

    function cancelarContrato(contrato) {
        navigate(`/cancelarcontrato/${contrato.id}`);
    }


    if (loading) return <Loading />

    return (
       <section>
         {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

             <HeaderContratos titulo={"Contratos"} textoBotao={botaoAdicionar} 
                pesquisa={pesquisa} setPesquisa={setPesquisa}
                tipoPesquisa={tipoPesquisa} setTipoPesquisa={setTipoPesquisa}
                pesquisarClick={pesquisarClick} adicionarClick={adicionarClick}
            />

            <Table striped hover responsive>
                <thead className={styles.thead}>
                    <tr className={styles.trHead}>
                            <th className={styles.th}>Distribuidor</th>
                            <th className={styles.th}>Cliente</th>                          
                            <th className={styles.th}>Sistema</th>                        
                            <th className={styles.th}>Nr.Serie</th>
                            <th className={styles.th}>Início</th>
                            <th className={styles.th}>Final</th>
                            <th className={styles.th}>Valor (R$)</th>
                            <th className={styles.th}>Tipo</th>
                            <th className={styles.th}>Status</th>
                    </tr>
                </thead>
                {tipoPesquisa !== "totais" &&
                    <tbody className={styles.tbody}>
                    {contratos.map(contrato => 
                        <tr key={contrato.id} className={styles.trBody}>                                     
                            <TableItemName title={contrato.distribuidor.nomeFantasia} />
                            <TableItemName title={contrato.cliente.razaoSocial} />
                            
                            <td className={styles.td}>{contrato.sistema}</td>
                            <td className={styles.td}>{contrato.numeroSerie}</td>  
                            <td className={styles.td}> 
                                {contrato.dataAtivacao.substring(8,10)}/   
                                {contrato.dataAtivacao.substring(5,7)}/   
                                {contrato.dataAtivacao.substring(0,4)}                               
                                
                            </td>
                            {contrato.dataCancelamento ?
                                <td className={styles.td}>
                                    {contrato.dataCancelamento.substring(8,10)}/ 
                                    {contrato.dataCancelamento.substring(5,7)}/   
                                    {contrato.dataCancelamento.substring(0,4)}    
                                </td>
                            : <td> * * * * * * </td>  
                            }
                            <td>{contrato.valor.toFixed(2)}</td>  

                            {contrato.tipoContrato === 0 && <td className={styles.td}>Manut.</td>}
                            {contrato.tipoContrato === 1 && <td className={styles.td} style={{color: "darkcyan"}}>Locação</td>}
                            {contrato.tipoContrato === 2 && <td className={styles.td} style={{color: "blueviolet"}} >Saas</td>}

                            {contrato.statusContrato === 1 ?
                                <td className={styles.td} style={{backgroundColor: "palegreen"}}>Ativo</td>
                            : <td className={styles.td} style={{backgroundColor: "tomato"}}>Cancelado</td>
                            }

                            <td style={{display: 'flex'}}>
                                <OverlayTrigger overlay={
                                    <Tooltip id="btn-editar">Editar</Tooltip> }> 
                                    <Button onClick={() => editarClick(contrato)}
                                            disabled={nivelUsuario !== "1" || contrato.statusContrato === 0}                                      
                                            variant="outline-warning">
                                            <Pencil size={18}/>
                                    </Button>   
                                </OverlayTrigger>                                                         

                                <span> </span>                     
                                <OverlayTrigger   overlay={
                                <Tooltip id="btn-view-pedido">Visualizar Contrato</Tooltip> }>                              
                                    <Button onClick={() => viewContrato(contrato)}                                       
                                            variant="outline-success">
                                            <CardText size={18}/>
                                    </Button>                                       
                                </OverlayTrigger>                                          

                                <span> </span>                     
                                
                                <OverlayTrigger   overlay={
                                    <Tooltip id="btn-clientes">Cancelar Contrato</Tooltip> }>                              
                                        <Button onClick={() => cancelarContrato(contrato)} 
                                                disabled={nivelUsuario !== "1" || contrato.statusContrato === 0}                                      
                                                variant="outline-danger">
                                                <StopCircle size={18}/>
                                        </Button>                                       
                                </OverlayTrigger>                     
                            </td>                        

                        </tr>
                    )}
                </tbody>
            }

            <tfoot>
                {tipoPesquisa === "totais" ?
                   <>
                        <tr style={{backgroundColor: "lightgray"}}>
                            <td colSpan={3} className="text-right"> </td>
                            <td> <b>  Total Contratos: </b> </td>
                            <td> <b>{totalContratos}</b> </td>
                        </tr>
                        <tr style={{backgroundColor: "ghostwhite"}}>
                            <td colSpan={3} className="text-right"> </td>
                            <td> <b>  Manutenção: </b> </td>
                            <td> <b>{totalContratosManutencao}</b> </td>
                        </tr>
                        <tr style={{backgroundColor: "aquamarine"}}>
                            <td colSpan={3} className="text-right"> </td>
                            <td> <b>  Locação: </b> </td>
                            <td> <b>{totalContratosLocacao}</b> </td>
                        </tr>
                        <tr style={{backgroundColor: "lightblue"}}>
                            <td colSpan={3} className="text-right"> </td>
                            <td> <b>  Saas: </b> </td>
                            <td> <b>{totalContratosSaas}</b> </td>
                        </tr>                       
                   </>                    
                :         
                    <Paginacao
                        setRegistroFinal={setRegistroFinal}
                        setRegistroInicial={setRegistroInicial}
                        setRegistrosData={setContratos} 
                        registroInicial={registroInicial}     
                        registroFinal={registroFinal}                                                                
                        quantidadeRegistros={quantidadeRegistros}
                        itensPorPagina={itensPorPagina} 
                        urlPesquisa={`/Contrato/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}`}
                    />
                }
            </tfoot>                    
            </Table>

       </section>
    )

}

export default ContratosIndex;