import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from './ModalPedido.module.css';
import api from '../Api/apiAxios';
import { MyContext } from "../Context/MyContext";

function ModalPedidos( {
    showModal, 
    setShowModal, 
    botaoLiberar, 
    setBotaoLiberar, 
    titleModal,                  
    bodyModal1, 
    bodyModal2, 
    bodyModal3, 
    setPedidoSalvo, 
    pedidoSalvo, 
    pesquisarClick, 
    atualizarUrl
  } ) {
  
  const { setMensagemGlobal, setTipoMensagemGlobal } = React.useContext(MyContext);

  const handleClose = () => {
    setShowModal(false);
    setBotaoLiberar(false);
  }

  const handleSubmit = () => {
    setShowModal(false);
    setBotaoLiberar(true); //verificar necessidade dessa variavel
    atualizarPedido(pedidoSalvo);
  }

  async function atualizarPedido(regPedido) {
    if  (regPedido) {
        regPedido.statusPedido = 1;
        try {
            const retorno = await api.put(`${atualizarUrl}` + `?id=${regPedido.id}&status=${regPedido.statusPedido}`);

            setMensagemGlobal(retorno.data);
            setTipoMensagemGlobal("success");               
            setShowModal(false);           
            setBotaoLiberar(false);
            setPedidoSalvo(null);

            pesquisarClick();
        } catch (e) {
            alert(e);
        }
    }    
}

  return (
    <>
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>{titleModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p className={styles.paragrafo}>{bodyModal1}</p>
            {bodyModal2 && <p className={styles.paragrafo}>{bodyModal2}</p>}
            {bodyModal3 && <p className={styles.paragrafo}>{bodyModal3}</p>}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Retornar
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
                Liberar
            </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalPedidos;

