import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading";
import ModalNS from "../../Components/ModalNS";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import { Button, Form, Row, Col, FloatingLabel, Alert } from "react-bootstrap";
import { CurrencyDollar } from "react-bootstrap-icons";
import { format, isValid, parseISO, addDays } from "date-fns";
import styles from "./NovoPedidoBmaAcesso.module.css";

const NovoPedidoBmaAcesso = () => {

    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');   

    const { mensagemGlobal, setMensagemGlobal, setTipoMensagemGlobal, tipoMensagemGlobal } = React.useContext(MyContext);

    const [loading, setLoading] = React.useState(false);
    const [modalNS, setModalNS] = React.useState(false);
    const [nsSelecionado, setNsSelecionado] = React.useState(false);

    const [distribuidores, setDistribuidores] = React.useState([]);
    const [clientes, setClientes] = React.useState([]);
    const [listaNS, setListaNS] = React.useState([]);
    const [versao, setVersao] = React.useState('');
    const [versaoEditada, setVersaoEditada] = React.useState('');
    const [apenasVersaoEditada, setApenasVersaoEditada] = React.useState('');

    const [distribuidorSelecionado, setDistribuidorSelecionado] = React.useState(null);
    const [clienteSelecionado, setClienteSelecionado] = React.useState(null);
    const [distribuidorSalvo, setDistribuidorSalvo] = React.useState(null);
    const [clienteSalvo, setClienteSalvo] = React.useState(null);
    const [qtdeLicencas, setQtdeLicencas] = React.useState(0);
    const [tipoVertical, setTipoVertical] = React.useState('');
    const [tipoPedido, setTipoPedido] = React.useState('');
    const [apelido, setApelido] = React.useState('Utilizado na personalização');
    const [numeroSerie, setNumeroSerie] = React.useState('000000');
    const [equipamentoAdicional, setEquipamentoAdicional] = React.useState(0);
    const [valorEquipamentoAdicional, setValorEquipamentoAdicional] = React.useState(0);
    const [serverAdicional, setServerAdicional] = React.useState(0);
    const [valorServerAdicional, setValorServerAdicional] = React.useState(0);
    const [valorLicenca, setValorLicenca] = React.useState(0);
    const [agendaWeb, setAgendaWeb] = React.useState(false);
    const [usuariosAgenda, setUsuariosAgenda] = React.useState(0);
    const [tipoBanco, setTipoBanco] = React.useState('');
    const [integracaoAlunos, setIntegracaoAlunos] = React.useState(false);
    const [integracaoFuncionarios, setIntegracaoFuncionarios] = React.useState(false);
    const [integracaoPacientes, setIntegracaoPacientes] = React.useState(false);
    const [moduloLGPD, setModuloLGPD] = React.useState(false);
    const [portalTerceiros, setPortalTerceiros] = React.useState(false);
    const [valorModuloLGPD, setValorModuloLGPD] = React.useState(0);
    const [wpe, setWpe] = React.useState(false);
    const [locacao, setLocacao] = React.useState(false);
    const [contrato, setContrato] = React.useState(true);
    const [observacao, setObservacao] = React.useState('');
    const [valorPedido, setValorPedido] = React.useState('0.00');
    const [valorMensal, setValorMensal] = React.useState('0.00');
    const [inscricaoCliente, setInscricaoCliente] = React.useState('');
    const [dataValidade, setDataValidade] = React.useState('');

    React.useEffect(() => {  
        async  function pesquisarDistribuidores() { 
               setLoading(true);                  
               if  (nivelUsuario !== "1") {
                    const resp = await api.get(`/Distribuidor/${idDistribuidor}`);  
                    const novoArray = []; 
                    novoArray.unshift(resp.data);
                    setDistribuidores(novoArray);            
               } else {
                    const response = await api.get('/Distribuidor');
                    const registrosFiltrados = response.data.filter((distribuidor) => 
                          distribuidor.statusEmpresa === 1);          
                    registrosFiltrados.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));
                    setDistribuidores(registrosFiltrados);            
               }    
               setLoading(false);       
        }
        pesquisarDistribuidores();            

    }, []);    

    React.useEffect(() => {
        if  (distribuidorSelecionado) {
            loadClientes(distribuidorSelecionado);
        }
    }, [distribuidorSelecionado])

    React.useEffect(() => {
        if  (tipoVertical)   {
            pesquisarVersaoAtual(tipoVertical);
        }
    }, [tipoVertical])

    const handleDataValidadeChange = (target) => {
        let parsedDate = parseISO(target.value)
        
        if (isValid(parsedDate)) {
            setDataValidade(format(addDays(target.value, 1), 'yyyy-MM-dd'));
        } else {
            setDataValidade('');
        }
    }

    async function pesquisarVersaoAtual(tipoVertical) {
        const tipoSistema = 2;
        const response = await api.get(`/Versao/retornar-versao-atual?tipoSistema=${tipoSistema}&tipoVertical=${tipoVertical}`);   
        setVersao(response.data);            
        const numeroVersaoEditada = response.data.numeroVersao.toString(); 

        const iniVer = numeroVersaoEditada.substring(1,0);
        const fimVer = numeroVersaoEditada.substring(1);

        setApenasVersaoEditada(iniVer + "." + fimVer);
        setVersaoEditada(iniVer + "." + fimVer + "." + response.data.release.toString());            
    }

    async function loadClientes(id) {
            const response = await api.get(`/Cliente/${id}/A`);
            const registrosFiltrados = response.data.filter((cliente) => 
                  cliente.statusEmpresa === 1);          
            registrosFiltrados.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));
            setClientes(registrosFiltrados);
            setQtdeLicencas(0);          
    };    

    function getValueDistribuidor(ev) {
        const idSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setDistribuidorSelecionado(idSelected);      
        setDistribuidorSalvo(idSelected);     
        setApelido('Utilizado na personalização');
    };  

    function getValueCliente(ev) {
        const idClienteSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setClienteSelecionado(idClienteSelected); 
        setClienteSalvo(idClienteSelected);
        if  (idClienteSelected) {
            buscarCliente(idClienteSelected);      
        }
    };  

    function buscarCliente(id) {
        (async () => {
            try {   
                setModalNS(false);
                setNsSelecionado(false);
                setListaNS(null);
                setQtdeLicencas(0);
                const response = await api.get(`/Cliente/${id}`);    
                setApelido(response.data.nomeFantasia);
                setInscricaoCliente(response.data.numeroInscricao);

                if  (tipoPedido === "1") {
                    const retorno = await 
                          api.get(`/LicencaAcesso/retornar-ns-cliente?id=${response.data.id}`);   
                    const qtdeRegistros = retorno.data.length;   
                    setQtdeLicencas(qtdeRegistros);              

                    if  (qtdeRegistros === 1)  {
                        setNumeroSerie(retorno.data[0].numeroSerie);
                        setTipoBanco(retorno.data[0].tipoBancoDados);
                        setUsuariosAgenda(retorno.data[0].usuariosAgenda);
                        setEquipamentoAdicional(retorno.data[0].equipamentoAdicional);
                        setServerAdicional(retorno.data[0].serverAdicional);
                        setTipoVertical(retorno.data[0].tipoVertical);

        //                setObservacao(retorno.data[0].observacao);

                        if  (retorno.data[0].temContrato === 1)
                            setContrato(true)
                        else
                            setContrato(false); 

                        if  (retorno.data[0].agendaWeb === 1)
                            setAgendaWeb(true)
                        else
                            setAgendaWeb(false);

                        if  (retorno.data[0].integracaoPonto === 1)
                            setWpe(true)
                        else
                            setWpe(false);    
    
                        if  (retorno.data[0].moduloLGPD === 1)
                            setModuloLGPD(true)
                        else
                            setModuloLGPD(false);    
    
                       if  (retorno.data[0].integracaoFuncionarios === 1)
                            setIntegracaoFuncionarios(true)
                        else
                            setIntegracaoFuncionarios(false);    
    
                        if  (retorno.data[0].integracaoPacientes === 1)
                            setIntegracaoPacientes(true)
                        else
                            setIntegracaoPacientes(false);    
    
                        if  (retorno.data[0].integracaoAlunos === 1)
                            setIntegracaoAlunos(true)
                        else
                            setIntegracaoAlunos(false);    
    
                        if  (retorno.data[0].locacao === 1)
                            setLocacao(true)
                        else
                            setLocacao(false);    
    
                        if  (retorno.data[0].portalTerceiros === 1)
                            setPortalTerceiros(true)
                        else
                            setPortalTerceiros(false);   

                    }
                    else if  (qtdeRegistros > 1) {
                            setListaNS(retorno.data);
                            setModalNS(true);
                    }
                }               

            } catch (error) {
              }            
        }
        )();
    }

    function getValueVertical(value) {
        setTipoVertical(value);     

        if  (Number(value) === 2 || Number(value) === 3 || Number(value) === 6) {
            setAgendaWeb(false)
            setUsuariosAgenda(0);
        }
    }

    function modalNumeroSerie() {
        setNsSelecionado(true);
     }
 
     async function atualizarPedido() {
         setDistribuidorSalvo(distribuidorSalvo);
         setClienteSelecionado(clienteSalvo);
         setNsSelecionado(false);

         const retorno = await 
                api.get(`/LicencaAcesso/retornar-licenca?numeroSerie=${numeroSerie}&inscricao=${inscricaoCliente}`);   

         setTipoBanco(retorno.data.tipoBancoDados);
         setUsuariosAgenda(retorno.data.usuariosAgenda);
         setEquipamentoAdicional(retorno.data.equipamentoAdicional);
         setServerAdicional(retorno.data.serverAdicional);
         setTipoVertical(retorno.data.tipoVertical);
         if  (retorno.data.temContrato === 1)
              setContrato(true)
         else
              setContrato(false); 
         if  (retorno.data.agendaWeb === 1)
             setAgendaWeb(true)
         else
             setAgendaWeb(false);
         if  (retorno.data.integracaoPonto === 1)
              setWpe(true)
         else
              setWpe(false);    
         if  (retorno.data.moduloLGPD === 1)
              setModuloLGPD(true)
         else
              setModuloLGPD(false);    
         if  (retorno.data.integracaoFuncionarios === 1)
             setIntegracaoFuncionarios(true)
         else
             setIntegracaoFuncionarios(false);    

         if  (retorno.data.integracaoPacientes === 1)
              setIntegracaoPacientes(true)
         else
              setIntegracaoPacientes(false);    
         if  (retorno.data.integracaoAlunos === 1)
              setIntegracaoAlunos(true)
         else
              setIntegracaoAlunos(false);    
         if  (retorno.data.locacao === 1)
             setLocacao(true)
         else
             setLocacao(false);    
         if  (retorno.data.portalTerceiros === 1)
              setPortalTerceiros(true)
         else
              setPortalTerceiros(false);   

     }
 
    async function verificarTotais(ev) {
        ev.preventDefault();

        const dataAtual = new Date();       
        let regPedido = {
            Id: 0,
            TipoVertical: Number(tipoVertical),
            EquipamentoAdicional: Number(equipamentoAdicional),            
            ValorEquipamentoAdicional: Number(valorEquipamentoAdicional),
            ServerAdicional: Number(serverAdicional),            
            ValorServerAdicional: Number(valorServerAdicional),
            AgendaWeb: agendaWeb ? 1 : 0,
            UsuariosAgenda: Number(usuariosAgenda),
            IntegracaoFuncionarios: integracaoFuncionarios ? 1 : 0,
            IntegracaoPacientes: integracaoPacientes ? 1 : 0,
            ModuloLGPD: moduloLGPD ? 1 : 0,
            ValorModuloLGPD: Number(valorModuloLGPD),
            DistribuidorId: Number(distribuidorSelecionado),    
            TipoPedido: Number(tipoPedido),
            ClienteId: Number(clienteSelecionado),    
            NumeroSerie: Number(numeroSerie),
            StatusPedido: 0,
            TipoBancoDados: Number(tipoBanco),
            TipoInstalacao: 0,
            VersaoSistema: versao.numeroVersao.toString(),
            VersaoRelease: versao.release.toString(),
            DataPedido: dataAtual,
            Observacao: observacao,
            MotivoCancelamento: '',
            NumeroOrdemCompra: '',
            ValorBruto: 0,
            ValorDesconto: 0,
            ValorLiquido: 0,
            ValorMensal: 0,
            IntegracaoAcesso: 0,
            IntegracaoFolha: 0,
            IntegracaoPonto: wpe ? 1 : 0,
            Contrato: contrato ? 1 : 0,
            IntegracaoAlunos: integracaoAlunos ? 1 : 0,
            Locacao: locacao ? 1 : 0,
            PortalTerceiros: portalTerceiros ? 1 : 0,
        }     
        
        if (dataValidade !== '' && locacao) {
            regPedido = {...regPedido, DataValidade: dataValidade}
        }

        const ret = await api.post("CalculoAcesso/", 
                    JSON.stringify(regPedido), 
              {
                  headers: { 'Content-Type': 'application/json'}
              });

        setValorLicenca(ret.data.valorLicenca);
        setValorEquipamentoAdicional(ret.data.valorEquipamentoAdicional);
        setValorServerAdicional(ret.data.valorServerAdicional);
        setValorModuloLGPD(ret.data.valorModuloLGPD);
        setValorPedido(ret.data.valorBruto);
        setValorMensal(ret.data.valorMensal);

    }

    async function handleSubmit(ev) {
        ev.preventDefault();
        setMensagemGlobal(null);

        if  (tipoPedido.length === 0) {
            setMensagemGlobal("Faltou Selecionar o Tipo do Pedido");
            setTipoMensagemGlobal("danger");
            return;
        }

        if  (tipoBanco.length === 0) {
            setMensagemGlobal("Faltou Selecionar o Banco de Dados");
            setTipoMensagemGlobal("danger");
            return;
        }

        if  (distribuidorSelecionado === '' || distribuidorSelecionado === null || clienteSelecionado === '' || clienteSelecionado === null) {
            setMensagemGlobal("Faltou Selecionar Distribuidor e/ou Cliente");
            setTipoMensagemGlobal("danger");
            return;
        }

        if  (tipoVertical.length === 0) {
            setMensagemGlobal("Faltou Selecionar o Tipo de Vertical");
            setTipoMensagemGlobal("danger");
            return;
        }

        const dataAtual = new Date();   
           
        let regPedido = {
            Id: 0,
            TipoVertical: Number(tipoVertical),
            EquipamentoAdicional: Number(equipamentoAdicional),            
            ValorEquipamentoAdicional: Number(valorEquipamentoAdicional),
            ServerAdicional: Number(serverAdicional),            
            ValorServerAdicional: Number(valorServerAdicional),
            AgendaWeb: agendaWeb ? 1 : 0,
            UsuariosAgenda: Number(usuariosAgenda),
            IntegracaoFuncionarios: integracaoFuncionarios ? 1 : 0,
            IntegracaoPacientes: integracaoPacientes ? 1 : 0,
            ModuloLGPD: moduloLGPD ? 1 : 0,
            ValorModuloLGPD: Number(valorModuloLGPD),
            DistribuidorId: Number(distribuidorSelecionado),    
            TipoPedido: Number(tipoPedido),
            ClienteId: Number(clienteSelecionado),    
            NumeroSerie: Number(numeroSerie),
            StatusPedido: 0,
            TipoBancoDados: Number(tipoBanco),
            TipoInstalacao: 0,
            VersaoSistema: apenasVersaoEditada,
            VersaoRelease: versao.release.toString(),
            DataPedido: dataAtual,
            Observacao: observacao,
            MotivoCancelamento: '',
            NumeroOrdemCompra: '',
            ValorBruto: Number(valorPedido),
            ValorDesconto: 0,
            ValorLiquido: Number(valorPedido),
            ValorMensal: Number(valorMensal),
            IntegracaoPonto: wpe ? 1 : 0,
            IntegracaoFolha: 0,
            IntegracaoAcesso: 0,
            Contrato: contrato ? 1 : 0,
            ValorLicenca: Number(valorLicenca),
            QuantidadeParcelas: 1,
            IntegracaoAlunos: integracaoAlunos ? 1 : 0,
            Locacao: locacao ? 1 : 0,
            PortalTerceiros: portalTerceiros ? 1 : 0,
        }

        if (dataValidade !== '' && locacao) {
            regPedido = {...regPedido, DataValidade: dataValidade}
        }
      
        const ret = await api.post("BmaAcesso/", 
                    JSON.stringify(regPedido), 
              {
                  headers: { 'Content-Type': 'application/json'}
              });

        setMensagemGlobal(ret.data);
        setTipoMensagemGlobal("success");               
        navigate("/pedidosacesso");

    }


    if  (loading) return <Loading />

    if  (nsSelecionado && modalNS) return <ModalNS modalNS={modalNS} setModalNS={setModalNS} numeroSerie={numeroSerie} 
                                  setNumeroSerie={setNumeroSerie} titleModal={apelido} 
                                  listaNS={listaNS}  />                                 

    if  (nsSelecionado) {
        atualizarPedido();
    }


    return (
        <section className={styles.form}>
            {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center'}}>Inserir Pedido BmaAcesso</Alert.Heading>        
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}>  
                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Selecione o Tipo de Pedido">
                        <Form.Select value={tipoPedido} onChange={({target}) => setTipoPedido(target.value)}>
                            <option value="" disabled></option>
                            <option value="0">Cópia Nova</option>
                            <option value="1">Atualização</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='8'>
                    <FloatingLabel controlid="floatingInputGrid" label="Selecione o Distribuidor">
                        <Form.Select onChange={getValueDistribuidor} value={distribuidorSelecionado}  className=" form-select-control">
                            <option></option>                               
                            {distribuidores.map((distribuidor) => (
                                <option key={distribuidor.id} 
                                        id={distribuidor.id}
                                        value={distribuidor.id}
                                        selected={distribuidor.id === distribuidorSelecionado}>{distribuidor.razaoSocial}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>    
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>   
                <Col md='7'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Selecione o Cliente">
                        <Form.Select onChange={getValueCliente} value={clienteSelecionado}  className=" form-select-control" >
                            <option></option>
                            {clientes.map((cliente) => (
                                <option key={cliente.id} 
                                        id={cliente.id}
                                        value={cliente.id}
                                        selected={cliente.id === clienteSelecionado}>{cliente.razaoSocial} - {cliente.numeroInscricao}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>                  
                </Col>

                <Col md='5'>
                     <FloatingLabel controlid="floatingInputGrid4" label="Nome Fantasia">
                        <Form.Control placeholder="Apelido" value={apelido} disabled
                                    onChange={({ target }) => setApelido(target.value)}/>
                      </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Número de Série">
                        <Form.Control placeholder="NS" value={numeroSerie} disabled
                                    onChange={({ target }) => setNumeroSerie(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='2'>
                    <Button onClick={modalNumeroSerie} variant="outline-secondary" className={styles.btn3}
                            disabled={tipoPedido === "0" || qtdeLicencas < 2}>
                        Selecionar NS
                    </Button>
                </Col>                

                <Col md='5'>
                    <FloatingLabel controlid="floatingInputGrid7" label="Selecione a Vertical">
                        <Form.Select value={tipoVertical} onChange={({target}) => getValueVertical(target.value)}>
                            <option value="" disabled></option>
                            <option value="1">Empresa</option>
                            <option value="2">Refeitório</option>
                            <option value="3">Academia</option>
                            <option value="4">Condomínio</option>
                            <option value="5">Hospital</option>
                            <option value="6">Escola</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>
                
                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid6" label="Versão">
                            <Form.Control placeholder="Ver" value={versaoEditada} disabled
                                    onChange={({ target }) => setVersaoEditada(target.value)}/>                        
                    </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Banco De Dados">
                        <Form.Select value={tipoBanco} onChange={({target}) => setTipoBanco(target.value)}>
                            <option value="" disabled></option>
                            <option value="1">MySql</option>
                            <option value="2">SqlServer</option>
                            <option value="3">Oracle</option>
                            {/* <option value="4">SQLite</option> */}
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>                

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Equipamentos Adicionais">
                        <Form.Control placeholder="Nr.Equip." value={equipamentoAdicional} 
                                    onChange={({ target }) => setEquipamentoAdicional(target.value)}/>
                    </FloatingLabel>      
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Server Adicional">
                        <Form.Control placeholder="Nr.Equip." value={serverAdicional} 
                                    onChange={({ target }) => setServerAdicional(target.value)}/>
                    </FloatingLabel>      
                </Col>
            </Row>

            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Integrações & Módulos Adicionais</Alert.Heading>        
            <br /> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="locacao"
                        label="Modalidade Locação"                      
                        onChange={() => setLocacao(!locacao)}
                        checked={locacao}                       
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integracaoAlunos"
                        label="Alunos"                      
                        onChange={() => setIntegracaoAlunos(!integracaoAlunos)}
                        checked={integracaoAlunos}                       
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integracaoFuncionarios"
                        label="Funcionários"                      
                        onChange={() => setIntegracaoFuncionarios(!integracaoFuncionarios)}
                        checked={integracaoFuncionarios}                       
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integracaoPacientes"
                        label="Pacientes"                      
                        onChange={() => setIntegracaoPacientes(!integracaoPacientes)}
                        checked={integracaoPacientes}
                    />
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
    
                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integraWpe"
                        label="BmaPonto"                      
                        onChange={() => setWpe(!wpe)}
                        checked={wpe}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="lgpd"
                        label="Módulo LGPD"                      
                        onChange={() => setModuloLGPD(!moduloLGPD)}
                        checked={moduloLGPD}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="terceiros"
                        label="Portal Terceiros"                      
                        onChange={() => setPortalTerceiros(!portalTerceiros)}
                        checked={portalTerceiros}                       
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="contrato"
                        label="Contrato"                      
                        onChange={() => setContrato(!contrato)}
                        checked={contrato}
                    />
                </Col>
            </Row>                    

            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Outras Informações</Alert.Heading>        
            <br /> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='2' style={{paddingTop: '12px'}}>
                    <Form.Check 
                        type="switch"
                        id="agendaWeb"
                        label="Agenda Web"                      
                        onChange={() => setAgendaWeb(!agendaWeb)}
                        checked={agendaWeb}
                        disabled={tipoVertical === 2 || tipoVertical === 3 || tipoVertical === 6}
                    />
                </Col>

                <Col md='5'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Usuários Agenda">
                        <Form.Control placeholder="Usuarios Agenda Web" value={usuariosAgenda} 
                                    onChange={({ target }) => setUsuariosAgenda(target.value)}
                                    disabled={tipoVertical === "2" || tipoVertical === "3" || tipoVertical === "6"}
                                    />
                    </FloatingLabel>      
                </Col>

                <Col md='5'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Data de Validade">
                        <Form.Control 
                            value={dataValidade} 
                            type="date"
                            onChange={({ target }) => handleDataValidadeChange(target)} 
                            disabled={!locacao}
                        />
                    </FloatingLabel>      
                </Col>
            </Row> 

            <Row style={{marginBottom: '1rem'}}>
                <Col md='2' />
                <Col md='10' >
                    <FloatingLabel controlid="floatingInputGrid11" label="Observações">
                        <Form.Control placeholder="Obs" as="textarea" style={{height: '100px'}} value={observacao} 
                            onChange={({ target }) => setObservacao(target.value)} 
                        />
                    </FloatingLabel>      
                </Col>    
            </Row>                     

            <br />                
            <Row className="g-2" style={{marginBottom: '1rem'}}>

                <Col md='2'>
                    <Button onClick={verificarTotais} variant="outline-success" className={styles.btn2}>
                    <CurrencyDollar size={20} />Verificar Totais</Button>               
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Valor da Licença (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control placeholder="Total" value={valorLicenca} 
                                      onChange={({ target }) => setValorLicenca(target.value)}
                                      disabled={nivelUsuario !== "1"}
                        />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                      <FloatingLabel controlid="floatingInputGrid8" label="Valor Equip.Adicional (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control placeholder="NS" value={valorEquipamentoAdicional} 
                                      onChange={({ target }) => setValorEquipamentoAdicional(target.value)}
                                      disabled={nivelUsuario !== "1"}
                        />
                      </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid10" label="Valor Server Adicional (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control placeholder="NS" value={valorServerAdicional} 
                                      onChange={({ target }) => setValorServerAdicional(target.value)}
                                      disabled={nivelUsuario !== "1"}
                        />
                      </FloatingLabel>      
                </Col>                
            </Row>                

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='2' />
                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid14" label="Valor Módulo LGPD (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control value={valorModuloLGPD} 
                                    onChange={({ target }) => setValorModuloLGPD(target.value)}
                                    disabled={nivelUsuario !== "1"}
                                    />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Total Pedido (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control placeholder="Total" value={valorPedido} 
                                      onChange={({ target }) => setValorPedido(target.value)}
                                      disabled={nivelUsuario !== "1"}
                        />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Valor Mensal (R$)" style={{fontWeight: 'bold'}}>                       
                        <Form.Control placeholder="Total" value={valorMensal} 
                                    onChange={({ target }) => setValorMensal(target.value)}
                                    disabled={nivelUsuario !== "1"}
                        />
                    </FloatingLabel>      
                </Col>
            </Row>                    

            </Alert>

            <div className={styles.areaBtn}>                
               <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn}
                       disabled={nivelUsuario === "2" || nivelUsuario ==="4"}
               >Confirmar</Button>                         
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )

}

export default NovoPedidoBmaAcesso;