import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate, useParams } from "react-router-dom";
import { Accordion, Alert, Button, Col, FloatingLabel, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Mensagem from "../../Components/Mensagem";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import { DashLg, PlusLg, CurrencyDollar, Buildings } from "react-bootstrap-icons";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import styles from "./EditarPedidoBmaPonto.module.css";


const EditarPedidoBmaPonto = () => {
    const { id } = useParams();

    const navigate = useNavigate();  

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');    
    const idDistribuidorLogado = window.localStorage.getItem('distribuidorId');   

    const { setMensagemGlobal, setTipoMensagemGlobal } = React.useContext(MyContext);

    const [mensagem, setMensagem] = React.useState('');
    const [tipoMensagem, setTipoMensagem] = React.useState(false);

    const [loading, setLoading] = React.useState(false);

    const [clientes, setClientes] = React.useState([]);
    const [versao, setVersao] = React.useState('');
    const [release, setRelease] = React.useState('');

    const [idDistribuidor, setIdDistribuidor] = React.useState('');
    const [clienteSelecionado, setClienteSelecionado] = React.useState('');
    const [tipoPedido, setTipoPedido] = React.useState('');
    const [apelido, setApelido] = React.useState('Utilizado na personalização');
    const [numeroSerie, setNumeroSerie] = React.useState('000000');
    const [faixaFuncionarios, setFaixaFuncionarios] = React.useState(10);
    const [tipoPlano, setTipoPlano] = React.useState('');
    const [usuarioAdicional, setUsuarioAdicional] = React.useState(0);
    const [tipoBanco, setTipoBanco] = React.useState('');
    const [portaria671, setPortaria671] = React.useState(false);
    const [integradorRep, setIntegradorRep] = React.useState(false);
    const [moduloEpi, setModuloEpi] = React.useState(false);
    const [wacesso, setWacesso] = React.useState(false);
    const [wgep, setWgep] = React.useState(false);
    const [contrato, setContrato] = React.useState(true);
    const [observacao, setObservacao] = React.useState('');
    const [dataPedido, setDataPedido] = React.useState('');
    const [quantidadeParcelas, setQuantidadeParcelas] = React.useState("1");
    const [dataLiberacao, setDataLiberacao] = React.useState('');

    const [valorPedido, setValorPedido] = React.useState('0.00');
    const [valorDesconto, setValorDesconto] = React.useState('0.00');
    const [valorMensal, setValorMensal] = React.useState('0.00');
    const [statusPedido, setStatusPedido] = React.useState('');

    const [faixaFuncionariosLicenca, setFaixaFuncionariosLicenca] = React.useState('');
    const [tipoPlanoLicenca, setTipoPlanoLicenca] = React.useState('');
    const [totalUsuariosLicenca, setTotalUsuariosLicenca] = React.useState('');
    const [contratoLicenca, setContratoLicenca] = React.useState('');
    const [portaria671Licenca, setPortaria671Licenca] = React.useState('');
    const [integradorRepLicenca,  setIntegradorRepLicenca] = React.useState('');
    const [moduloEpiLicenca, setModuloEpiLicenca] = React.useState('');
    const [acessoLicenca, setAcessoLicenca] = React.useState('');
    const [folhaLicenca, setFolhaLicenca] = React.useState('');

    React.useEffect(() => {  
        (async () => {
            try {   
                setLoading(true);
                const response = await api.get(`/BmaPonto/${id}`);  

             //   O campo StatusPedido e retornado aqui como segurança, para que nenhum usuario acesse diretamente
             //   a URL da edição, modificando o id do Pedido.. Assim testamos se o pedido em questão esta realmente
             //   em Aberto... 

                setStatusPedido(response.data.statusPedido);
                setIdDistribuidor(response.data.distribuidorId);
                setTipoPedido(response.data.tipoPedido);
                setNumeroSerie(response.data.numeroSerie);
                setTipoPlano(response.data.tipoInstalacao);
                setFaixaFuncionarios(response.data.faixaFuncionarios);
                setTipoBanco(response.data.tipoBancoDados);
                setUsuarioAdicional(response.data.usuariosAdicionais);
                setObservacao(response.data.observacao);
                setValorPedido(response.data.valorBruto);
                setValorDesconto(response.data.valorDesconto);
                setValorMensal(response.data.valorMensal);
                setVersao(response.data.versaoSistema);
                setRelease(response.data.versaoRelease);
                setDataPedido(response.data.dataPedido);               
                setDataLiberacao(response.data.dataLiberacao);
                if  (response.data.quantidadeParcelas) {
                    setQuantidadeParcelas(response.data.quantidadeParcelas)
                }

                if  (response.data.contrato === 1)
                    setContrato(true)
                else
                    setContrato(false);    

                if  (response.data.portaria671 === 1)
                    setPortaria671(true)
                else
                    setPortaria671(false);    

                if  (response.data.integradorRep === 1)
                    setIntegradorRep(true)
                else
                    setIntegradorRep(false);    

                if  (response.data.moduloEpi === 1)
                    setModuloEpi(true)
                else
                    setModuloEpi(false);    

                if  (response.data.integracaoAcesso === 1)
                    setWacesso(true)
                else
                    setWacesso(false);    

                if  (response.data.integracaoFolha === 1)
                    setWgep(true)
                else
                    setWgep(false);    
                    
                loadClientes(response.data.distribuidorId);
                setClienteSelecionado(response.data.clienteId);
                buscarCliente(response.data.clienteId);

                const tipoBusca = "NS";
                const retorno = await api.get(`/LicencaPonto/${tipoBusca}/${response.data.numeroSerie}`);
                setFaixaFuncionariosLicenca(retorno.data.faixaFuncionarios);
                setTipoPlanoLicenca(retorno.data.tipoInstalacao);
                setTotalUsuariosLicenca(retorno.data.quantidadeUsuarios);
                if  (retorno.data.temContrato === 1)
                    setContratoLicenca("Sim")
                else
                    setContratoLicenca("Não");    
                if  (retorno.data.portaria671 === 1)
                    setPortaria671Licenca("Sim")
                else
                    setPortaria671Licenca("Não");    
                if  (retorno.data.integradorRep === 1)
                    setIntegradorRepLicenca("Sim")
                else
                    setIntegradorRepLicenca("Não");    
                if  (retorno.data.moduloEpi === 1)
                    setModuloEpiLicenca("Sim")
                else
                    setModuloEpiLicenca("Não");    
                if  (retorno.data.integracaoAcesso === 1)
                    setAcessoLicenca("Sim")
                else
                    setAcessoLicenca("Não");    
                if  (retorno.data.integracaoFolha === 1)
                    setFolhaLicenca("Sim")
                else
                    setFolhaLicenca("Não");    


                setLoading(false);

                const distribuidorLogado = Number(idDistribuidorLogado);
                const distribuidorPedido = Number(response.data.distribuidorId);

                if  ((distribuidorLogado !== distribuidorPedido) && (nivelUsuario === "3" || nivelUsuario === "4")) {
                    setMensagemGlobal("Ops, você não tem permissão para acessar este Pedido !");
                    setTipoMensagemGlobal("danger");
                    navigate("/pedidosponto");
                }


            } catch (error) {
                alert(error);
            }            
        }
        )();

    }, [id]);                

    async function loadClientes(idDistribuidor) {
        try {
            const response = await api.get(`/Cliente/${idDistribuidor}/A`);
            response.data.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));
            setClientes(response.data);
        } catch (e) {
            alert(e);
        }          
    };   


    function getValueCliente(ev) {
        const idClienteSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setClienteSelecionado(idClienteSelected); 
        if  (idClienteSelected) {
            buscarCliente(idClienteSelected);      
        }
    };  

    function buscarCliente(id) {
        (async () => {
            try {   
                const response = await api.get(`/Cliente/${id}`);    
                setApelido(response.data.nomeFantasia);
                
            } catch (e) {
                alert(e);
            }            
        }
        )();
    }

    function adicionar() {
        if  (faixaFuncionarios > 75) {
            setFaixaFuncionarios((faixaFuncionarios => faixaFuncionarios + 50));
       }
       else if (faixaFuncionarios >= 25) {
            setFaixaFuncionarios((faixaFuncionarios => faixaFuncionarios + 25));       
       }
       else setFaixaFuncionarios(faixaFuncionarios => faixaFuncionarios + 15);          
    }

    function subtrair() {
        if  (faixaFuncionarios > 100) {
            setFaixaFuncionarios((faixaFuncionarios => faixaFuncionarios - 50));        
        }
        else if (faixaFuncionarios >= 50) {
             setFaixaFuncionarios((faixaFuncionarios => faixaFuncionarios - 25));
        }
        else if (faixaFuncionarios === 25) {
            setFaixaFuncionarios(10);   
        } 
    }

    async function verificarTotais(ev) {
        ev.preventDefault();

        if  (tipoPlano === 2 && (!contrato)) {
            setMensagem("Plano Selecionado requer contrato !");
            setTipoMensagem("danger");
            return;
        }

        const dataAtual = new Date();       
        let regPedido = {
            Id: 0,
            FaixaFuncionarios: Number(faixaFuncionarios),            
            Portaria671: portaria671 ? 1 : 0,       
            IntegradorRep: integradorRep ? 1 : 0,       
            Supervisor: tipoPlano === "2" ? 1 : 0,
            PortalColaborador: tipoPlano === "2" ? 1 : 0,
            AplicativoRegistrador: tipoPlano === "2" ? 1 : 0,
            ColetorVirtual: 0,
            QuantidadeColetorVirtual: 0,
            UsuariosAdicionais: Number(usuarioAdicional),
            ModuloEpi: moduloEpi ? 1 : 0,
            DistribuidorId: Number(idDistribuidor),    
            TipoPedido: Number(tipoPedido),
            ClienteId: Number(clienteSelecionado),    
            NumeroSerie: Number(numeroSerie),
            StatusPedido: 0,
            TipoBancoDados: Number(tipoBanco),
            TipoInstalacao: Number(tipoPlano),
            VersaoSistema: versao,
            VersaoRelease: release,
            DataPedido: dataAtual,
            Observacao: observacao,
            MotivoCancelamento: '',
            NumeroOrdemCompra: '',
            ValorBruto: 0,
            ValorDesconto: 0,
            ValorLiquido: 0,
            ValorMensal: 0,
            IntegracaoAcesso: wacesso ? 1 : 0,
            IntegracaoFolha: wgep ? 1 : 0,
            IntegracaoPonto: 0,
            Contrato: contrato ? 1 : 0,
        }              
      
        try {
            const ret = await api.post("CalculoPonto/", JSON.stringify(regPedido), { headers: { 'Content-Type': 'application/json'}});

            setValorPedido(ret.data.valorBruto);
            setValorMensal(ret.data.valorMensal);
        } catch (e) {
            alert(e);
        }
    }


    async function handleSubmit(ev) {
        ev.preventDefault();
    //    console.log(tipoPlano);
    //    console.log(typeof(tipoPlano));

        if  (tipoPedido.lenght === 0) {
            setMensagem("Faltou Selecionar o Tipo do Pedido");
            setTipoMensagem("danger");
            return;
        }
        if  (tipoPlano > 2) {
            setMensagem("Faltou Selecionar o Tipo do Plano");
            setTipoMensagem("danger");
            return;
        }
        
        if  (tipoBanco > 3) {
            setMensagem("Faltou Selecionar o Banco de Dados");
            setTipoMensagem("danger");
            return;
        }

        if  (clienteSelecionado === null || clienteSelecionado === '') {
            setMensagem("Faltou Selecionar o Cliente");
            setTipoMensagem("danger");
            return;
        }

        let ns = Number(numeroSerie);
        if  (ns === 0 && tipoPedido === "1") {
            setMensagem("Faltou Informar o NS !");
            setTipoMensagem("danger");
            return;
        }

        let regPedido = {
            Id: Number(id),
            FaixaFuncionarios: Number(faixaFuncionarios),            
            Portaria671: portaria671 ? 1 : 0,       
            IntegradorRep: integradorRep ? 1 : 0,       
            Supervisor: tipoPlano === "2" ? 1 : 0,
            PortalColaborador: tipoPlano === "2" ? 1 : 0,
            AplicativoRegistrador: tipoPlano === "2" ? 1 : 0,
            ColetorVirtual: 0,
            QuantidadeColetorVirtual: 0,
            UsuariosAdicionais: Number(usuarioAdicional),
            ModuloEpi: moduloEpi ? 1 : 0,
            DistribuidorId: Number(idDistribuidor),    
            TipoPedido: Number(tipoPedido),
            ClienteId: Number(clienteSelecionado),    
            NumeroSerie: Number(numeroSerie),
            StatusPedido: 0,
            TipoBancoDados: Number(tipoBanco),
            TipoInstalacao: Number(tipoPlano),
            VersaoSistema: versao,
            VersaoRelease: release,
            DataPedido: dataPedido,
            Observacao: observacao,
            MotivoCancelamento: '',
            NumeroOrdemCompra: '',
            ValorBruto: Number(valorPedido),
            ValorDesconto: Number(valorDesconto),
            ValorLiquido: 0,
            ValorMensal: Number(valorMensal),
            IntegracaoAcesso: wacesso ? 1 : 0,
            IntegracaoFolha: wgep ? 1 : 0,
            IntegracaoPonto: 0,
            Contrato: contrato ? 1 : 0,
            QuantidadeParcelas: quantidadeParcelas ? Number(quantidadeParcelas) : 1,
        }              
      
        try {
            const ret = await api.put("BmaPonto/",JSON.stringify(regPedido), {headers: { 'Content-Type': 'application/json'} });
            setMensagemGlobal(ret.data);
            setTipoMensagemGlobal("success");
            navigate("/pedidosponto");
        } catch (e) {
            alert(e);
        }
    }    

    if  (loading) return <Loading />

    return (
        <section className={styles.form}>

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center'}}>Editar Pedido BmaPonto</Alert.Heading>        
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}>
    
                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Tipo de Pedido">
                        <Form.Select value={tipoPedido} onChange={({target}) => setTipoPedido(target.value)}>
                            <option value="" disabled></option>
                            <option value="0">Cópia Nova</option>
                            <option value="1">Atualização</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Número de Série">                               
                            <Form.Control placeholder="NS" value={numeroSerie} 
                                    disabled={nivelUsuario !== "1"}
                                    onChange={({ target }) => setNumeroSerie(target.value)}/>                       
                    </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid6" label="Versão">
                            <Form.Control placeholder="Ver" value={versao} 
                                    disabled={nivelUsuario !== "1"}
                                    onChange={({ target }) => setVersao(target.value)}/>                        
                    </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid7" label="Release">
                            <Form.Control placeholder="Release" value={release} 
                                    disabled={nivelUsuario !== "1"}
                                    onChange={({ target }) => setRelease(target.value)}/>             
                      </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
    
                <Col md='7'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Selecione o Cliente">
                        <Form.Select onChange={getValueCliente}  className=" form-select-control" >
                            <option></option>
                            {clientes.map((cliente) => (
                                <option key={cliente.id} 
                                        id={cliente.id}
                                        value={cliente.id}
                                        selected={cliente.id === clienteSelecionado}>{cliente.razaoSocial}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>                  
                </Col>

                <Col md='5'>
                     <FloatingLabel controlid="floatingInputGrid4" label="Nome Fantasia">
                        <Form.Control placeholder="Apelido" value={apelido} disabled
                                    onChange={({ target }) => setApelido(target.value)}/>
                      </FloatingLabel>      
                </Col>                

            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Faixa Funcionários">
                        <Form.Control placeholder="Faixa" value={faixaFuncionarios} 
                                    onChange={({ target }) => setFaixaFuncionarios(target.value)}/>
                    </FloatingLabel>      
                </Col>

                <Col md='2'>
                    <Button onClick={adicionar} variant="outline-secondary">
                           <PlusLg size={20}/>
                    </Button>   
                    <span> </span>                    
                    <Button onClick={subtrair} variant="outline-secondary">
                           <DashLg size={20}/>
                    </Button>   
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid7" label="Tipo de Plano">
                        <Form.Select value={tipoPlano} onChange={({target}) => setTipoPlano(target.value)}>
                            <option value="" disabled></option>
                            <option value="0">Licenciado</option>
                            <option value="1">SaaS (Rep-C)</option>
                            <option value="2">SaaS (Rep-P)</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid8" label="Usuários Adicionais">
                        <Form.Control placeholder="NS" value={usuarioAdicional} 
                                    onChange={({ target }) => setUsuarioAdicional(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Banco De Dados">
                        <Form.Select value={tipoBanco} onChange={({target}) => setTipoBanco(target.value)}>
                            <option value="" disabled></option>
                            <option value="1">MySql</option>
                            <option value="2">SqlServer</option>
                            <option value="3">Oracle</option>
                            {/* <option value="4">SQLite</option> */}
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>
            </Row>

            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Integrações & Módulos Adicionais</Alert.Heading>        
            <br /> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='2'>
                    <Form.Check 
                        type="switch"
                        id="portaria671"
                        label="Portaria 671"                      
                        onChange={() => setPortaria671(!portaria671)}
                        checked={portaria671}
                    />
                </Col>

                <Col md='2'>
                    <Form.Check 
                        type="switch"
                        id="integradorRep"
                        label="Integrador REP"                      
                        onChange={() => setIntegradorRep(!integradorRep)}
                        checked={integradorRep}
                    />
                </Col>

                <Col md='2'>
                    <Form.Check 
                        type="switch"
                        id="epi"
                        label="Módulo EPI"                      
                        onChange={() => setModuloEpi(!moduloEpi)}
                        checked={moduloEpi}
                    />
                </Col>

                <Col md='2'>
                    <Form.Check 
                        type="switch"
                        id="integraWacesso"
                        label="BmaAcesso"                      
                        onChange={() => setWacesso(!wacesso)}
                        checked={wacesso}
                    />
                </Col>

                <Col md='2'>
                    <Form.Check 
                        type="switch"
                        id="integraWge"
                        label="BmaFolha"                      
                        onChange={() => setWgep(!wgep)}
                        checked={wgep}
                    />
                </Col>

                <Col md='2'>
                    <Form.Check 
                        type="switch"
                        id="contrato"
                        label="Contrato"                      
                        onChange={() => setContrato(!contrato)}
                        checked={contrato}
                    />
                </Col>

            </Row>                    

            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Outras Informações</Alert.Heading>        
            <br /> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>

                <Col md='3'>

                    <Button onClick={() => navigate(`/clientecnpj/${clienteSelecionado}`)} variant="outline-dark" className={styles.btn3}
                            disabled={nivelUsuario === "4"}>
                           <Buildings size={20} /> Inscrições Adicionais</Button>               
                </Col>

                <Col md='9'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Observações">
                        <Form.Control placeholder="Obs" value={observacao} maxLength={250} 
                                      onChange={({ target }) => setObservacao(target.value)}/>
                    </FloatingLabel>      
                </Col>

            </Row>             

            {nivelUsuario === "1" &&
                <Row className="g-2" style={{marginBottom: '1rem'}}>                    
                    <Col md='12'>
                        <FloatingLabel controlid="floatingInputGrid3" label="Quantidade de Parcelas">
                            <Form.Select value={quantidadeParcelas} onChange={({target}) => setQuantidadeParcelas(target.value)}>
                                <option value="" disabled></option>
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                            </Form.Select>
                        </FloatingLabel>                    
                    </Col>
                </Row>
            }       

            <Row className="g-2" style={{marginBottom: '1rem'}}>

                <Col md='3'>

                    <Button onClick={verificarTotais} variant="outline-success" className={styles.btn2}
                         disabled={nivelUsuario !== "1"}>
                        <CurrencyDollar size={20} />Verificar Totais</Button>               
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Total Pedido (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control placeholder="Total" value={valorPedido} 
                             disabled={nivelUsuario !== "1"}
                             onChange={({ target }) => setValorPedido(target.value)}/>
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid13" label="Total Desconto (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control placeholder="Desconto" value={valorDesconto} 
                             disabled={nivelUsuario !== "1"}
                             onChange={({ target }) => setValorDesconto(target.value)}/>
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid14" label="Valor Mensal (R$)" style={{fontWeight: 'bold'}}>                       
                        <Form.Control placeholder="Mes" value={valorMensal} 
                             disabled={nivelUsuario !== "1"}
                             onChange={({ target }) => setValorMensal(target.value)}/>
                    </FloatingLabel>      
                </Col>
            </Row>                    

            </Alert>

            <Alert style={{background: 'whitesmoke', color: 'black'}}>

                <Accordion flush>
                   <Accordion.Item eventKey="0" background='blue' fontWeight='bolder'>
                    <Accordion.Header style={{textAlign: 'center'}}>INFORMAÇÕES DA ULTIMA LICENÇA...</Accordion.Header>
                    <AccordionBody>
                        <Row className="g-2" style={{marginBottom: '1rem'}}>

                            <Col md='4'>
                                <FloatingLabel controlid="floatingInputGrid34" label="Faixa Funcionários" style={{fontWeight: 'bold'}}>                       
                                    <Form.Control placeholder="Fxa Func." value={faixaFuncionariosLicenca} disabled />
                                </FloatingLabel>      
                            </Col>

                            <Col md='4'>
                                <FloatingLabel controlid="floatingInputGrid35" label="Tipo de Plano" style={{fontWeight: 'bold'}}>
                                    <Form.Select value={tipoPlanoLicenca} onChange={({target}) => setTipoPlanoLicenca(target.value)} disabled>
                                        <option value="0">Licenciado</option>
                                        <option value="1">SaaS (Rep-C)</option>
                                        <option value="2">SaaS (Rep-P)</option>
                                    </Form.Select>
                                </FloatingLabel>                    
                            </Col>

                            <Col md='4'>
                                <FloatingLabel controlid="floatingInputGrid36" label="Total Usuarios" style={{fontWeight: 'bold'}}>                       
                                    <Form.Control placeholder="Total Usuarios" value={totalUsuariosLicenca} disabled />
                                </FloatingLabel>      
                            </Col>

                        </Row>

                        <Row className="g-2" style={{marginBottom: '1rem'}}>

                            <Col md='2'>
                                <FloatingLabel controlid="floatingInputGrid38" label="Contrato" style={{fontWeight: 'bold'}}>                       
                                    <Form.Control placeholder="Contrato" value={contratoLicenca} disabled />
                                </FloatingLabel>      
                            </Col>

                            <Col md='2'>
                                <FloatingLabel controlid="floatingInputGrid48" label="Portaria 671" style={{fontWeight: 'bold'}}>                       
                                    <Form.Control placeholder="Int.Rep." value={portaria671Licenca} disabled />
                                </FloatingLabel>      
                            </Col>

                            <Col md='2'>
                                <FloatingLabel controlid="floatingInputGrid39" label="Integrador REP" style={{fontWeight: 'bold'}}>                       
                                    <Form.Control placeholder="Int.Rep." value={integradorRepLicenca} disabled />
                                </FloatingLabel>      
                            </Col>

                            <Col md='2'>
                                <FloatingLabel controlid="floatingInputGrid49" label="Módulo EPI" style={{fontWeight: 'bold'}}>                       
                                    <Form.Control placeholder="Int.Rep." value={moduloEpiLicenca} disabled />
                                </FloatingLabel>      
                            </Col>

                            <Col md='2'>
                                <FloatingLabel controlid="floatingInputGrid58" label="Integração Acesso" style={{fontWeight: 'bold'}}>                       
                                    <Form.Control placeholder="Int.Rep." value={acessoLicenca} disabled />
                                </FloatingLabel>      
                            </Col>

                            <Col md='2'>
                                <FloatingLabel controlid="floatingInputGrid59" label="Integração Folha" style={{fontWeight: 'bold'}}>                       
                                    <Form.Control placeholder="Int.Rep." value={folhaLicenca} disabled />
                                </FloatingLabel>      
                            </Col>

                        </Row>

                    </AccordionBody>
                    </Accordion.Item>
                </Accordion>

            </Alert>

            {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem} /> }                                 

            <div className={styles.areaBtn}>    
               <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                       disabled={nivelUsuario === "2" || nivelUsuario === "4" || statusPedido > 0}>Atualizar</Button>
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )
}

export default EditarPedidoBmaPonto;
