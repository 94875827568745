import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import { Button, Form, Row, Col, FloatingLabel, Alert } from "react-bootstrap";
import styles from "./EditarContrato.module.css";

const EditarContrato = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  

    const { mensagemGlobal, setMensagemGlobal, setTipoMensagemGlobal, tipoMensagemGlobal, listaGlobalContratos, setListaGlobalContratos } = React.useContext(MyContext);

    const [loading, setLoading] = React.useState(false);

    const [razaoSocialDistribuidor, setRazaoSocialDistribuidor] = React.useState('');
    const [razaoSocialCliente, setRazaoSocialCliente] = React.useState('');
    const [numeroSerie, setNumeroSerie] = React.useState('');
    const [valorContrato, setValorContrato] = React.useState(0);
    const [sistema, setSistema] = React.useState('');
    const [pedidoId, setPedidoId] = React.useState(0);
    const [clienteId, setClienteId] = React.useState(0);
    const [dataAtivacao, setDataAtivacao] = React.useState(``);
    const [dataEditada, setDataEditada] = React.useState('');
    const [tipoContrato, setTipoContrato] = React.useState('');

    React.useEffect(() => { 
        (async () => {
            try {   
                setLoading(true);
                const response = await api.get(`/Contrato/${id}`);    

//                console.log(response.data);

                setPedidoId(response.data.pedidoId);
                setClienteId(response.data.clienteId);
                setSistema(response.data.sistema);
                setTipoContrato(response.data.tipoContrato);
                setNumeroSerie(response.data.numeroSerie);
                setValorContrato(response.data.valor);
                setRazaoSocialCliente(response.data.cliente.razaoSocial);
                setRazaoSocialDistribuidor(response.data.distribuidor.razaoSocial);
                setDataAtivacao(response.data.dataAtivacao);

                const dataString = response.data.dataAtivacao.substring(8,10) + "/" +
                                   response.data.dataAtivacao.substring(5,7)  + "/" +   
                                   response.data.dataAtivacao.substring(0,4);
                setDataEditada(dataString);                                   

                setLoading(false);

            } catch (error) {
                alert(error);
            }            
        }
        )();

    }, [id]);                


    async function handleSubmit(ev) {
        ev.preventDefault();
        setMensagemGlobal(null);

        const dataAtual = new Date();       
        let regContrato = {
            Id: Number(id),
            PedidoId: Number(pedidoId),                        
            StatusContrato: 1,
            DataAtivacao: dataAtivacao,
            DataAtualizacao: dataAtual,
            ValorFixo: 1,
            Valor: Number(valorContrato),
            Sistema: sistema,
            ClienteId: Number(clienteId),
            NumeroSerie: Number(numeroSerie),
            TipoContrato: Number(tipoContrato),
        }                
               
        const ret = await api.put("Contrato/", 
                    JSON.stringify(regContrato), 
              {
                  headers: { 'Content-Type': 'application/json'}
              });

        if (listaGlobalContratos) {
            let regDesatualizado = listaGlobalContratos.find(reg => reg.id === regContrato.Id);
            let listaGlobalAtualizada = listaGlobalContratos;
            let regIndex = listaGlobalContratos.indexOf(regDesatualizado);
            let regAtualizado = await api.get(`/Contrato/retornar-ultimo-contrato-cliente?id=${regContrato.ClienteId}&numeroSerie=${regContrato.NumeroSerie}`)

            listaGlobalAtualizada[regIndex] = regAtualizado.data;
            setListaGlobalContratos(listaGlobalAtualizada);
        }

        setMensagemGlobal("Contrato atualizado com sucesso.");
        setTipoMensagemGlobal("success");               
        navigate("/contratos");
    }


    if  (loading) return <Loading />

    return (
        <section className={styles.form}>
            {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center'}}>Editar Contrato</Alert.Heading>        
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}> 
                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Tipo de Sistema">
                        <Form.Select value={sistema} onChange={({target}) => setSistema(target.value)} disabled>
                            <option value="" disabled></option>
                            <option value="PONTO">BmaPonto</option>
                            <option value="ACESSO">BmaAcesso</option>
                            <option value="FOLHA">BmaFolha</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='8'>
                    <FloatingLabel controlid="floatingInputGrid" label="Distribuidor">
                    <Form.Control placeholder="Release" value={razaoSocialDistribuidor} disabled
                                    onChange={({ target }) => setRazaoSocialDistribuidor(target.value)}/>             
                   </FloatingLabel>    
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>    
                <Col md='12'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Cliente">
                    <Form.Control placeholder="Release" value={razaoSocialCliente} disabled
                                    onChange={({ target }) => setRazaoSocialCliente(target.value)}/>             
                    </FloatingLabel>                  
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Número de Série">
                        <Form.Control placeholder="NS" value={numeroSerie} disabled
                                    onChange={({ target }) => setNumeroSerie(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Id Pedido">
                        <Form.Control placeholder="ID" value={pedidoId} disabled
                                    onChange={({ target }) => setPedidoId(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Data Ativação">
                        <Form.Control placeholder="Ativacao" value={dataEditada} disabled
                                    onChange={({ target }) => setDataEditada(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Tipo de Contrato">
                        <Form.Select value={tipoContrato} onChange={({target}) => setTipoContrato(target.value)}>
                            <option value="" disabled></option>
                            <option value="0">Manutenção</option>
                            <option value="1">Locação</option>
                            <option value="2">Saas</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Valor Contrato">
                        <Form.Control placeholder="Val.Contrato" value={valorContrato} 
                                    onChange={({ target }) => setValorContrato(target.value)}/>
                    </FloatingLabel>      
                </Col>
            </Row>

            </Alert>

            <div className={styles.areaBtn}>                
               <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                       disabled={nivelUsuario !== "1"}>Atualizar
                       {/* disabled={nivelUsuario !== "1" || pedidoId === 0}>Atualizar */}
               </Button>
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )

}

export default EditarContrato;