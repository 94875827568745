import React, { useRef } from "react";
import api from "../../Api/apiAxios";
import { Table, Alert, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import Loading from "../../Components/Loading";
import { PersonCircle } from "react-bootstrap-icons";
import ModalContato from "../../Components/ModalContato";
import styles from "./ClienteVersoes.module.css";

const ClienteVersoes = () => {

    const navigate = useNavigate();

    const tableRef = useRef(null);

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');

    const { mensagemGlobal, setMensagemGlobal, tipoMensagemGlobal, setTipoMensagemGlobal } = React.useContext(MyContext);

    const [showModal, setShowModal] = React.useState(false);
    const [bodyModal1, setBodyModal1] = React.useState('');
    const [bodyModal2, setBodyModal2] = React.useState('');
    const [bodyModal3, setBodyModal3] = React.useState('');
    const [clientes, setClientes] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
            async  function pesquisar() { 
                setLoading(true);      

                const sistema = "Todos";
                const response = await api.get(`/Cliente/pesquisar-versoes?idDistribuidor=${idDistribuidor}&sistema=${sistema}`);   
                setClientes(response.data);                

                setLoading(false);       
        }

        pesquisar();            
    }, []);            

    function exportToExcel() {        
        var tableID = "tblClientes";
        var filename = "Lista_Clientes_Versoes";
        var downloadLink;
        var dataType = 'application/vnd.ms-excel';
        var tableSelect = document.getElementById(tableID);
        var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

        // Specify file name
        filename = filename ? filename + '.xls' : 'excel_data.xls';

        // Create download link element
        downloadLink = document.createElement("a");

        document.body.appendChild(downloadLink);

        if (navigator.msSaveOrOpenBlob) {
            var blob = new Blob(['\ufeff', tableHTML], {
        type: dataType
            });
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
        // Create a link to the file
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML;

            // Setting the file name
            downloadLink.download = filename;

            //triggering the function
            downloadLink.click();
        }
}

    function modalClick(cliente) {
        setBodyModal1(`Cliente: ${cliente.nomeFantasia}`); 
        setBodyModal2(`Contato: ${cliente.nomeContato}  - Fone: ${cliente.foneContato}`);
        setBodyModal3(`Email: ${cliente.emailContato}`);
        setShowModal(true);
    }


    if (loading) return <Loading />

    if  (showModal) return <ModalContato showModal={showModal} setShowModal={setShowModal}
                           titleModal="Dados para contato com o Cliente !"
                           bodyModal1={bodyModal1} bodyModal2={bodyModal2} bodyModal3={bodyModal3} />

    return (
       <section>
            {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
               <Alert.Heading style={{textAlign: 'center'}}>Clientes x Sistemas</Alert.Heading>        
                  
                <Button onClick={exportToExcel} variant="outline-success" className={styles.btn}> 
                            Exportar para Excel
                </Button>
            </Alert>
             
            <Table id="tblClientes" striped hover responsive size="sm">
                <thead className={styles.thead}>
                    <tr className={styles.trHead}>
                            <th className={styles.th}>Cliente</th>
                            <th className={styles.th}>Versão Ponto</th>
                            <th className={styles.th}>Contrato</th>
                            <th className={styles.th}>Versão Folha</th>
                            <th className={styles.th}>Contrato</th>
                            <th className={styles.th}>Versão Acesso</th>
                            <th className={styles.th}>Contrato</th>
                        </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {clientes.map((cliente, index) => 
                        <tr key={index} className={styles.trBody}>                                     
                            <td className={styles.tdFonte1}>{cliente.nomeFantasia}</td>          
                            <td style={{backgroundColor: 'azure'}} className={styles.tdFonte2}>{cliente.versaoBmaPonto}</td>  
                            <td className={styles.tdFonte2}>{cliente.contratoBmaPonto}</td>  
                            <td style={{backgroundColor: 'azure'}} className={styles.tdFonte2}>{cliente.versaoBmaFolha}</td>  
                            <td className={styles.tdFonte2}>{cliente.contratoBmaFolha}</td>  
                            <td style={{backgroundColor: 'azure'}} className={styles.tdFonte2}>{cliente.versaoBmaAcesso}</td>  
                            <td className={styles.tdFonte2}>{cliente.contratoBmaAcesso}</td>  
                            <td>
                                <OverlayTrigger overlay={
                                    <Tooltip id="btn-editar">Contato</Tooltip> }>
                                    <Button onClick={() => modalClick(cliente)}
                                            variant="outline-success">
                                        <PersonCircle size={15}/>
                                    </Button>
                                </OverlayTrigger>                            
                            </td>                        
                        </tr>
                    )}
                </tbody>

            </Table>
       </section>
    )

}

export default ClienteVersoes;