import React from "react";
import { Alert, Button, Col, Row, FloatingLabel } from "react-bootstrap";
import { FolderPlus, Search } from "react-bootstrap-icons";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

export const HeaderServicos = ( {titulo, textoBotao, pesquisa, setPesquisa, 
                               tipoPesquisa, setTipoPesquisa, 
                               pesquisarClick, adicionarClick} ) => {

    const [pesquisarPor, setPesquisarPor] = React.useState('0');
    const [dataInicial, setDataInicial] = React.useState('');
    const [dataFinal, setDataFinal] = React.useState('');

    function atualizarTipoPesquisa( {target} ) {
        setPesquisarPor(target.value);
        setTipoPesquisa(target.value);
    }

    return (
        <Alert style={{background: 'ghostwhite', color: 'dimGray'}}>
            <Row>
              <Col md='3'>
                 <Alert.Heading style={{textAlign: 'left', fontSize: '1.5rem'}}>{titulo}</Alert.Heading>
              </Col>    

              <Col md='2'>
                <Form.Select  onChange={({target}) => atualizarTipoPesquisa({target})} value={pesquisarPor} className=" form-select-control" >
                        <option disabled value="0">Opções</option>
                        <option value="*">Todos</option>
                        <option value="distribuidor">Distribuidor</option>
                        <option value="cliente">Cliente</option>
                        <option value="sistema">Sistema</option>
                        <option value="digitados">Digitados</option>
                        <option value="liberados">Liberados</option>
                        <option value="cancelados">Cancelados</option>
                </Form.Select>      
              </Col>

              <Col md='3'>
                <InputGroup className="mb-3">
                    <Form.Control placeholder="Pesquisa Parcial:" value={pesquisa}
                                  style={{fontSize: '0.8rem'}}
                                  onChange={({ target }) => setPesquisa(target.value)}/>

                        <Button onClick={pesquisarClick} variant="outline-primary">
                            <Search size={15} />
                        </Button>
                </InputGroup>
              </Col>    

              <Col md='1'>
              </Col>

              {textoBotao !== "hide" ?
               (
                <Col md='3'>
                    <Button onClick={adicionarClick} 
                            variant="outline-primary"
                            style={{borderRadius: '8px'}}
                    >
                    <FolderPlus size={22}/>{textoBotao}</Button>
                </Col>
              )
              :
              (
                <></>
              )
             }            
             </Row>             

{/*              <Row>
                <Col md='2'>
                    <Form.Label style={{fontWeight: 'bold', fontSize: '18'}}>Data Inicial / Final</Form.Label>
                </Col>

                <Col md='3'>
                    <Form.Control
                        type="date"
                        name="dataInicial"
                        placeholder="Data Inicial"
                        value={dataInicial}
                        onChange={(e) => setDataInicial(e.target.value)}
                    />
                </Col>

                <Col md='3'>
                    <Form.Control
                        type="date"
                        name="dataFinal"
                        placeholder="Data Final"
                        value={dataFinal}
                        onChange={(e) => setDataFinal(e.target.value)}
                    />
                </Col>

             </Row>
*/}

        </Alert>
    )
}

export default HeaderServicos;