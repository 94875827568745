const url = process.env.REACT_APP_API_BASEURL;

const isDevMode = process.env.NODE_ENV === "development";

const protocol = window.location.protocol;
const host = window.location.host;
const path = window.location.pathname;

const linkApi = `${protocol}//${host}${path}api/api/`;

export const API_URL = isDevMode ? `${url}` : `${linkApi}` ;

export function USUARIOS_VALIDAR_EMAIL({ email }) {
    return {
        url: `${API_URL}Usuario/validar-email?email=${email}`, 
        options: {
            method: 'GET',
            headers: { 'Content-Type': 'application/json'},
            withCredentials: false         
            },     
    }
}        

export function USUARIO_POST(formData) {

    const token = window.localStorage.getItem('token');      

    return {
        url2: `${API_URL}Usuario/`, 
        options2: {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                withCredentials: false, 
                // Authorization: 'Bearer ' + token,
            },     
            body: JSON.stringify(formData),      
        },
    }}        

export function CNPJ_RFB_GET (cnpj) {
    return {
        url: `https://receitaws.com.br/v1/cnpj/${cnpj}`, 
        options: {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json'},
                withCredentials: false                
        },
    }}            


    export function DISTRIBUIDORES_VALIDAR_INSCRICAO(inscricao) {
        return {
            url2: `${API_URL}Distribuidor/validar-inscricao?inscricao=${inscricao}`, 
            options2: {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
                withCredentials: false         
                },     
        }
    }
    
    export function CLIENTES_VALIDAR_INSCRICAO(inscricao) {       
        return {
            url2: `${API_URL}Cliente/validar-inscricao?inscricao=${inscricao}`, 
            options2: {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
                withCredentials: false         
                },     
        }
    }

    