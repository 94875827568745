import React from "react"
import { Button } from "react-bootstrap";
import styles from "./ModalCliente.module.css";

export const ModalCliente = ( {setModalOpen, cliente} ) => {
    return(
        <div className={styles.modalBackground}>
            <div className={styles.modalContainer}>
                <h4>{cliente.razaoSocial}</h4>
                <hr/>

                <div className={styles.modalBody}>

                   <label>Nome Fantasia</label>     
                   <p>{cliente.nomeFantasia}</p>    

                   <label>CNPJ / CPF</label>     
                   <p>{cliente.numeroInscricao}</p>    

                   <label>Inscriçao Estadual</label>     
                   <p>{cliente.inscricaoEstadual}</p>    

                   <label>Endereço</label>     
                   <p>{cliente.logradouro} - {cliente.numero}</p>    

                   <label>CEP</label>     
                   <p>{cliente.cep}</p>                             

                   <label>Bairro</label>     
                   <p>{cliente.bairro}</p>                             

                   <label>Cidade/UF</label>     
                   <p>{cliente.cidade} - {cliente.uf}</p>                             

                   <label>Ramo Atividade</label>     
                   <p>{cliente.ramoAtividade}</p>                             

                </div>

                <div className={styles.modalFooter}>
                    <Button className={styles.btn} variant="outline-secondary"
                            onClick={() => setModalOpen(false)}>Fechar</Button>
                </div>
            </div>
        </div>
    )
 }

 export default ModalCliente;
