import React from "react";
import api from "../../Api/apiAxios";
import { Form, Alert, Button, Col, Row, FloatingLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading";
import Mensagem from "../../Components/Mensagem";
import styles from "./RelatorioPedidosOSFaturadas.module.css";

const RelatorioPedidosOSFaturadas = () => {
    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');   

    const [loading, setLoading] = React.useState(false);
    const [mensagem, setMensagem] = React.useState(null);
    const [tipoMensagem, setTipoMensagem] = React.useState('');
    const [dataRelatorio, setDataRelatorio] = React.useState('');
    const [dataRelatorio2, setDataRelatorio2] = React.useState('');
    const [opcao, setOpcao] = React.useState('');
    const [incluirContratos, setIncluirContratos] = React.useState(false);
    const [distribuidorSelecionado, setDistribuidorSelecionado] = React.useState('');
    const [distribuidores, setDistribuidores] = React.useState([]);

    React.useEffect(() =>{
        const data = new Date();    

        const ano = data.getFullYear();
        const mes = data.getMonth();
        const dia = 26;
        var data1 = new Date(ano, mes, dia);

        const dias = 30;
        const milissegundos_por_dia = 1000 * 60 * 60 * 24;

        var dataInicial = new Date(data1.getTime() -  (dias * milissegundos_por_dia));

        var ano1 = dataInicial.getFullYear();
        var mes1 = String(dataInicial.getMonth() + 1).padStart(2,'0')
        var dia1 = dataInicial.getDate().toString().padStart(2,'0')
        setDataRelatorio(ano1 + "-" + mes1 + "-" + dia1);

        const mesEditado = String(data.getMonth() + 1).padStart(2,'0');
        setDataRelatorio2(ano + "-" + mesEditado + "-" + 25);

        async  function pesquisarDistribuidores() { 
            setLoading(true);                  

            if  (nivelUsuario !== "1") {
                 const resp = await api.get(`/Distribuidor/${idDistribuidor}`);  
                 const novoArray = []; 
                 novoArray.unshift(resp.data);
                 setDistribuidores(novoArray);            
            } else {
                   const response = await api.get('/Distribuidor');
                   const registrosFiltrados = response.data.filter((distribuidor) => 
                         distribuidor.statusEmpresa === 1);          
                   registrosFiltrados.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));

                   var todos = {
                    id: 9999,
                    razaoSocial: '*** TODOS ***',
                   }
                   registrosFiltrados.push(todos);
                   
                   setDistribuidores(registrosFiltrados);  

            }    
                   
            setLoading(false);       
     }

     pesquisarDistribuidores();
    }, [])

    function getValueDistribuidor(ev) {
        const idSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setDistribuidorSelecionado(idSelected);   
    };  

    async function handleSubmit(ev) {
        ev.preventDefault();

        if  (distribuidorSelecionado.length === 0) {
            alert("Faltou Selecionar o Distribuidor...");
            return;
        }

        if  (dataRelatorio.length === 0) {
            setMensagem("Fatou Selecionar o Período");
            setTipoMensagem("danger");
            return;
        }

        if  (dataRelatorio2.length === 0) {
            setMensagem("Fatou Selecionar o Período 2");
            setTipoMensagem("danger");
            return;
        }

        try {
            setLoading(true);        

            const contratos = incluirContratos ? 1 : 0;

            const response = 
                await api.get(`/Totais/relatorio-pedidos-os-periodo?id=${distribuidorSelecionado}&nivel=${nivelUsuario}&dataInicial=${dataRelatorio}&dataFinal=${dataRelatorio2}&opcao=${opcao}&contratos=${contratos}`, 
                      { responseType: 'blob' }
            );   

            var pdfBlob = new Blob([response.data], { type: 'application/pdf'} );
            var url = window.URL.createObjectURL(pdfBlob)
            window.open(url);

            navigate(-1);

//             const link = document.createElement('a')
//                   link.href = url
//                   link.setAttribute('download', `Faturamento_Periodo_${periodo}_Distribuidor_${idDistribuidor}.pdf`)
//                   link.click();
//                   link.remove();
//             URL.revokeObjectURL(url);         

        }
        catch (error) {
              alert("Não foi possível gerar o relatório.");
        } finally {
            setLoading(false);
        }
    }


    if  (loading) return <Loading />

    return (
        <section className={styles.form}>

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center', fontSize: 'x-large'}}>Relação Pedidos & Ordens Serviço Faturadas</Alert.Heading>        

            <br />

            <Row>
                <Col md='1'>
                </Col>
                <Col md='9'>
                    <FloatingLabel controlid="floatingInputGrid" label="Selecione o Distribuidor">
                        <Form.Select onChange={getValueDistribuidor} value={distribuidorSelecionado} className=" form-select-control">
                            <option></option>                               
                            {distribuidores.map((distribuidor) => (
                                <option key={distribuidor.id} 
                                        id={distribuidor.id}
                                        value={distribuidor.id}
                                        selected={distribuidor.id === distribuidorSelecionado}>{distribuidor.razaoSocial}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>    
                </Col>
            </Row>
            <br />

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='1'>
                </Col>

                <Col md='2'>
                    <Form.Label controlid="floatingInputGrid14" style={{fontWeight: 'normal', textAlign: 'left'}}>Período Inicial</Form.Label>                       
                        <Form.Control type="date" placeholder="Periodo" value={dataRelatorio} style={{alignSelf: 'left'}}         
                                    onChange={({ target }) => setDataRelatorio(target.value)}/>
                </Col>            

                <Col md='1'>
                </Col>

                <Col md='2'>
                    <Form.Label controlid="floatingInputGrid14" style={{fontWeight: 'normal', textAlign: 'left'}}>Período Final</Form.Label>                       
                        <Form.Control type="date" placeholder="Periodo" value={dataRelatorio2} style={{alignSelf: 'left'}}         
                                    onChange={({ target }) => setDataRelatorio2(target.value)}/>
                </Col>

                <Col md='1'>
                </Col>

                <Col md='2'>
                    <Form.Label controlid="floatingInputGrid7">Listar</Form.Label>                    
                    <Form.Select value={opcao} onChange={({target}) => setOpcao(target.value)}>
                        <option value="" disabled></option>
                        <option value="pedido">Pedidos</option>
                        <option value="os">Ordens Serviço</option>
                        <option value="ambos">Ambos</option>
                    </Form.Select>
                </Col>

                <Col md='1'>
                </Col>

                <Col md='2'>
                    <Form.Check 
                        type="switch"
                        id="Contratos"
                        label="Incluir Contratos"                      
                        onChange={() => setIncluirContratos(!incluirContratos)}
                        checked={incluirContratos}
                        style={{marginTop: '2rem'}}
                    />
                </Col>

            </Row>                    

            </Alert>

            <div className={styles.areaBtn}>    
               <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                        disabled={nivelUsuario === "2" || nivelUsuario === "4"}>Gerar Relatório
               </Button>               
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

            {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem} /> }                                 

         </section>   
    )
}

export default RelatorioPedidosOSFaturadas;