import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from './ModalContato.module.css';

function ModalContato( {showModal, setShowModal, titleModal, 
                        bodyModal1, bodyModal2, bodyModal3} ) {
  
  const handleClose = () => {
    setShowModal(false);
  }

  return (
    <>
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>{titleModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p className={styles.paragrafo}>{bodyModal1}</p>
            {bodyModal2 && <p className={styles.paragrafo}>{bodyModal2}</p>}
            {bodyModal3 && <p className={styles.paragrafo}>{bodyModal3}</p>}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Retornar
            </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalContato;

