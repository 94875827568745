import React from "react";
import api from "../../Api/apiAxios";
import apiImg from "../../Api/apiAxiosImagem";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import { Button, Form, Row, Col, FloatingLabel, Alert } from "react-bootstrap";
import styles from "./NovaVersao.module.css";

const NovaVersao = () => {

    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    
    const { mensagemGlobal, setMensagemGlobal, setTipoMensagemGlobal, tipoMensagemGlobal } = React.useContext(MyContext);

    const [loading, setLoading] = React.useState(false);  

    const [tipoSistema, setTipoSistema] = React.useState('');
    const [versao, setVersao] = React.useState(0);
    const [release, setRelease] = React.useState(0);
    const [tipoVertical, setTipoVertical] = React.useState('');
    const [ultimaVersao, setUltimaVersao] = React.useState(''); 

    React.useEffect(() => {
        if  (tipoSistema) {

            if  (tipoSistema === "2" && tipoVertical.length === 0) {
                setMensagemGlobal("Faltou Selecionar o tipo de Vertical para Acesso !")
                setTipoMensagemGlobal("danger");
                setTipoSistema(null);
                return;
            }

            (async () => { 
                try {    
                    const sistema = Number(tipoSistema);
                    if  (sistema === 2) {
                        const response = await api.get(`/Versao/retornar-versao-atual?tipoSistema=${sistema}&tipoVertical=${tipoVertical}`);   
                        const numeroVersaoEditada = response.data.numeroVersao.toString();
                        const iniVer = numeroVersaoEditada.substring(1,0);
                        const fimVer = numeroVersaoEditada.substring(2,1);
                        setUltimaVersao(iniVer + "." + fimVer + "." + response.data.release);                                      
                    }
                    else
                    {
                        const response = await api.get(`/Versao/retornar-versao-atual?tipoSistema=${sistema}`);   
                        const numeroVersaoEditada = response.data.numeroVersao.toString();
                        const iniVer = numeroVersaoEditada.substring(2,0);
                        const fimVer = numeroVersaoEditada.substring(2);
                        setUltimaVersao(iniVer + "." + fimVer + "." + response.data.release);                                      
                    }
                }
                catch (error) {
                    alert(error);
                }
            }
            )();
        }
    }, [tipoSistema])

    async function handleSubmit(ev) {
        ev.preventDefault();
        setMensagemGlobal(null);

        const dataAtual = new Date();       
        let regVersao = {
            Id: 0,
            TipoSistema: Number(tipoSistema),
            NumeroVersao: Number(versao),
            Release: release,
            DataLiberacao: dataAtual,
            TipoVertical: tipoSistema === "2" ? Number(tipoVertical) : null,
        }                
               
        const ret = await api.post("Versao/", 
                    JSON.stringify(regVersao), 
              {
                  headers: { 'Content-Type': 'application/json'}
              });

        setMensagemGlobal(ret.data);
        setTipoMensagemGlobal("success");               
        navigate("/versoes");

    }

    async function pegarArquivo(e) {
        const fd = new FormData();
        fd.append('imagem', e.target.files[0]);

        const cpf = "948.252.899-91";
        const resp = await apiImg.post(`/Image?cpf=${cpf}`, fd);
        console.log(resp);    

    }



    if  (loading) return <Loading />

    return (
        <section className={styles.form}>
            {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center'}}>Inserir Versão</Alert.Heading>        
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                </Col>

                <Col md='6'>
                    <FloatingLabel controlid="floatingInputGrid7" label="Selecione a Vertical">
                        <Form.Select value={tipoVertical} onChange={({target}) => setTipoVertical(target.value)}>
                            <option value="" disabled></option>
                            <option value="1">Empresa</option>
                            <option value="2">Refeitório</option>
                            <option value="3">Academia</option>
                            <option value="4">Condomínio</option>
                            <option value="5">Hospital</option>
                            <option value="6">Escola</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>

                <Col md='3'>
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Selecione o Tipo de Sistema">
                        <Form.Select value={tipoSistema} onChange={({target}) => setTipoSistema(target.value)}>
                            <option value="" disabled></option>
                            <option value="1">BmaPonto</option>
                            <option value="2">BmaAcesso</option>
                            <option value="3">BmaFolha</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Versão Atual">
                        <Form.Control placeholder="Versao" value={ultimaVersao} disabled
                                    onChange={({ target }) => setUltimaVersao(target.value)}/>
                      </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>    
                <Col md='3'>
                </Col>

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Versão">
                        <Form.Control placeholder="Versao" value={versao} 
                                    onChange={({ target }) => setVersao(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid6" label="Release">
                        <Form.Control placeholder="Release" value={release} 
                                    onChange={({ target }) => setRelease(target.value)}/>
                      </FloatingLabel>      
                </Col>                
            </Row>

            </Alert>

            {/* <input type="file" name="image" onChange={pegarArquivo} /> */}
            
            <div className={styles.areaBtn}>                
               <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                       disabled={nivelUsuario !== "1"}>Confirmar
               </Button>
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )

}

export default NovaVersao;