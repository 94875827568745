export default function TipoInstalacao({tipoInstalacao, isPonto = false}) {
    let color = '';
    let statusTitle = '';

    if (tipoInstalacao === 0) {
        color = 'tomato';
        statusTitle = 'Licenciado';
    } else if (tipoInstalacao === 1) {
        color = 'deepskyblue'
        statusTitle = isPonto ? 'SaaS (Rep-C)' : 'SaaS'
    } else if (isPonto && tipoInstalacao === 2) {
        color = 'blueviolet';
        statusTitle = 'SaaS (Rep-P)'
    }
    return ( 
        <td style={{fontSize: "small", color: color, fontWeight: "bold"}}>{statusTitle}</td>  
    )
}