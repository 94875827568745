import React from "react";
import api from "../../Api/apiAxios";
import { Button, Table } from "react-bootstrap";
import { Pencil, PersonVcard } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import HeaderCadastros from "../../Components/HeaderCadastros";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'
import styles from "./DistribuidoresIndex.module.css";

const DistribuidoresIndex = () => {

    const navigate = useNavigate();

    const { mensagemGlobal, setMensagemGlobal, tipoMensagemGlobal,
            setListaGlobalClientes, setListaGlobalContratos, setDistribuidorIdGlobal } = React.useContext(MyContext);

    const [pesquisa, setPesquisa] = React.useState('');
    const [distribuidores, setDistribuidores] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setListaGlobalClientes(null);
        setListaGlobalContratos(null);
        setDistribuidorIdGlobal('');

        async  function pesquisar() { 
               setLoading(true);                  
               const response = await api.get('/Distribuidor');
               response.data.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));                
               setDistribuidores(response.data);            
               setLoading(false);       
        }

        pesquisar();            
    }, []);            

    function pesquisarClick(event) { 
        event.preventDefault();     
        (async () => {
            try {
                setLoading(true);
                const pesquisaLowerCase = pesquisa.toLowerCase();                       

                const response = await api.get('/Distribuidor');                             
                if  (pesquisa.length > 0) {
                    const registrosFiltrados = response.data.filter((distribuidor) => 
                          distribuidor.razaoSocial.toLowerCase().includes(pesquisaLowerCase));               
                    setDistribuidores(registrosFiltrados);            
                } else {
                    response.data.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));                
                    setDistribuidores(response.data);
                }    
                setLoading(false);
                setPesquisa('');
                

            } catch (error) {
                alert(error);
            }
        }
        )();
    }

    function adicionarClick(event) {
        event.preventDefault();
        navigate("/novodistribuidor");
    }

    function editarClick(distribuidor) {              
        navigate(`/editardistribuidor/${distribuidor.id}`);
    }

    function clientesDistribuidorClick(distribuidor) {
        navigate(`/distribuidorclientes/${distribuidor.id}`);
    }

    if (loading) return <Loading />

    return (
       <section>
         {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

          <HeaderCadastros titulo={"Distribuidores"} textoBotao={" Adicionar Distribuidor"} 
                pesquisa={pesquisa} setPesquisa={setPesquisa}
                pesquisarClick={pesquisarClick} adicionarClick={adicionarClick} />

            <Table striped hover responsive>
                <thead className={styles.thead}>
                    <tr className={styles.trHead}>
                            <th className={styles.th}>Razão Social</th>
                            <th className={styles.th}>Cnpj/Cpf</th>                          
                            <th className={styles.th}>Contato</th>                        
                            <th className={styles.th}>E-Mail</th>
                            <th className={styles.th}>Fone</th>
                            <th className={styles.th}>Status</th>
                            <th className={styles.th}>Tipo</th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                {distribuidores.map(distribuidor => 

                    <tr key={distribuidor.id} className={styles.trBody}>    
                        {distribuidor.statusEmpresa === 0 ?
                              <td className={styles.td} style={{color: "silver"}}>{distribuidor.razaoSocial}</td>          
                           :  <td className={styles.td} style={{color: "black"}}>{distribuidor.razaoSocial}</td>          
                        }
                        {distribuidor.statusEmpresa === 0 ?
                             <td className={styles.inscricao} style={{color: "silver"}}>{distribuidor.numeroInscricao}</td>
                           : <td className={styles.inscricao} style={{color: "black"}}>{distribuidor.numeroInscricao}</td>
                        }
                        {distribuidor.statusEmpresa === 0 ?
                             <td className={styles.td} style={{color: "silver"}}>{distribuidor.nomeContato}</td>
                           : <td className={styles.td} style={{color: "black"}}>{distribuidor.nomeContato}</td>
                        }
                        {distribuidor.statusEmpresa === 0 ?
                             <td className={styles.td} style={{color: "silver"}}>{distribuidor.emailContato}</td>
                           : <td className={styles.td} style={{color: "black"}}>{distribuidor.emailContato}</td>
                        }
                        {distribuidor.statusEmpresa === 0 ?
                             <td className={styles.td} style={{color: "silver"}}>{distribuidor.foneContato}</td>
                           : <td className={styles.td} style={{color: "black"}}>{distribuidor.foneContato}</td>
                        }
                        {distribuidor.statusEmpresa === 0 ?
                             <td className={styles.td} style={{backgroundColor: "tomato"}}>Inativo</td>
                           : <td className={styles.td} style={{backgroundColor: "palegreen"}}>Ativo</td>
                        }


                        {distribuidor.tipoDistribuidor === 1 &&
                            <td className={styles.td}>BMA</td>
                        }
                        {distribuidor.tipoDistribuidor === 2 &&
                            <td className={styles.td}>Revenda</td>
                        }
                        {distribuidor.tipoDistribuidor === 3 &&
                            <td className={styles.td}>Integ.</td>
                        }
                        <td style={{display: 'flex'}}>
                            <OverlayTrigger overlay={
                                <Tooltip id="btn-editar">Editar</Tooltip> }> 
                                <Button onClick={() => editarClick(distribuidor)}
                                        variant="outline-warning">
                                    <Pencil size={15}/>
                                </Button>   
                            </OverlayTrigger>                                                         

                            <span> </span>                     
                            
                            <OverlayTrigger   overlay={
                                <Tooltip id="btn-clientes">Lista de Clientes</Tooltip> }>                              
                                <Button onClick={() => clientesDistribuidorClick(distribuidor)}                                       
                                        variant="outline-success">
                                        <PersonVcard size={15}/>
                                </Button>                                       
                            </OverlayTrigger>                     
                        </td>                        

                    </tr>
                )}
            </tbody>

            </Table>

       </section>
    )

}

export default DistribuidoresIndex;