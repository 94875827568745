import axios from "axios";

const url = process.env.REACT_APP_API_BASEURL;
const isDevMode = process.env.NODE_ENV === "development";

const protocol = window.location.protocol;
const host = window.location.host;
const path = window.location.pathname;

const linkApi = `${protocol}//${host}${path}api/api/`;


const apiImg = axios.create({
  baseURL: isDevMode
    ? "http://192.168.2.115/Image/api"

    : `${linkApi}`,     

});

apiImg.interceptors.request.use(
  async config => {
    config.headers = { };       
    const token = window.localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return Promise.resolve(config);
  },
  error => {
    return Promise.reject(error);
  },
);

export default apiImg;