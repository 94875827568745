import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Col, FloatingLabel, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Loading from "../../Components/Loading";
import styles from "./ViewPedidoBmaAcesso.module.css";

const ViewPedidoBmaAcesso = () => {
    const { id } = useParams();

    const navigate = useNavigate();  

    const [loading, setLoading] = React.useState(false);
    const [registro, setRegistro] = React.useState({});
    const [razaoSocial, setRazaoSocial] = React.useState('');
    const [apelido, setApelido] = React.useState('Utilizado na personalização');
    const [inscricao, setInscricao] = React.useState('');
    const [statusPedido, setStatusPedido] = React.useState('');
    const [valorParcela, setValorParcela] = React.useState('0,00');

    const [distribuidor, setDistribuidor] = React.useState('');    

    React.useEffect(() => {
        (async () => {
            setLoading(true);

            try {
                const response = await api.get(`/BmaAcesso/${id}`);    
                    
                buscarCliente(response.data.clienteId);
                buscarDistribuidor(response.data.distribuidorId);
                
                let calcValorParcela = (Number(response.data.valorBruto)/Number(response.data.quantidadeParcelas)).toFixed(2);
                
                setValorParcela(calcValorParcela);

                switch(response.data.statusPedido) {
                    case 0 : setStatusPedido('Pendente');
                        break;                             
                    case 1 : setStatusPedido('Liberado');
                        break;                
                    case 2 : setStatusPedido('Cancelado');
                        break;
                    default: setStatusPedido('');
                }
                const dataString = response.data.dataPedido.substring(8,10) + "/" +
                                   response.data.dataPedido.substring(5,7)  + "/" +   
                                   response.data.dataPedido.substring(0,4);
                response.data.dataPedido = dataString;   

                if (response.data.dataLiberacao !== null) {
                    const dataLiberacaoString = response.data.dataLiberacao.substring(8,10) + "/" +
                                                response.data.dataLiberacao.substring(5,7)  + "/" +   
                                                response.data.dataLiberacao.substring(0,4);
                     response.data.dataLiberacao = dataLiberacaoString;
                }
                else response.data.dataLiberacao = '* * * * * *';

                if (response.data.dataValidade !== null) {
                    const dataValidadeString = response.data.dataValidade.substring(8,10) + "/" +
                                                response.data.dataValidade.substring(5,7)  + "/" +   
                                                response.data.dataValidade.substring(0,4);
                     response.data.dataValidade = dataValidadeString;
                }
                else response.data.dataValidade = '* * * * * *';

                if (response.data.dataCancelamento !== null) {
                    const dataCancelamentoString = response.data.dataCancelamento.substring(8,10) + "/" +
                                                   response.data.dataCancelamento.substring(5,7)  + "/" +   
                                                   response.data.dataCancelamento.substring(0,4);
                    response.data.dataCancelamento = dataCancelamentoString;
                }
                else {
                    response.data.dataCancelamento = '* * * * * *';
                    response.data.motivoCancelamento = '* * * * * *';
                }
                setRegistro(response.data);
            } catch (error) {
                alert(error);
            } finally {
                setLoading(false);
            }  
            
        }
        )();

    }, [id]);                

    function buscarCliente(id) {
        (async () => {
            try {   
                const response = await api.get(`/Cliente/${id}`);    
                setApelido(response.data.nomeFantasia);   
                setRazaoSocial(response.data.razaoSocial);             
                setInscricao(response.data.numeroInscricao);
            } catch (error) {
            }            
        }
        )();
    }

    function buscarDistribuidor(id) {
        (async () => {
            try {   
                const response = await api.get(`/Distribuidor/${id}`);    
                setDistribuidor(response.data);                
            } catch (error) {
                alert(error);
            }            
        }
        )();
    }


    if  (loading) return <Loading />

    return (
        <section className={styles.form}>

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                {statusPedido === 'Liberado' &&
                    <Alert.Heading style={{textAlign: 'center', color: 'seagreen'}}>Pedido BmaAcesso ( {statusPedido} )</Alert.Heading>        
                }
                {statusPedido === 'Cancelado' &&
                    <Alert.Heading style={{textAlign: 'center', color: 'tomato'}}>Pedido BmaAcesso ( {statusPedido} )</Alert.Heading>        
                }
                {statusPedido === 'Pendente' &&
                    <Alert.Heading style={{textAlign: 'center'}}>Pedido BmaAcesso ( {statusPedido} )</Alert.Heading>        
                }
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}>
    
                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Tipo de Pedido">
                        <Form.Select value={registro.tipoPedido} disabled>                          
                            <option value="0">Cópia Nova</option>
                            <option value="1">Atualização</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Número de Série">                               
                            <Form.Control value={registro.numeroSerie} disabled />                       
                    </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid6" label="Versão">
                            <Form.Control value={registro.versaoSistema} disabled />                        
                    </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid7" label="Release">
                            <Form.Control value={registro.versaoRelease} disabled />             
                      </FloatingLabel>      
                </Col>                

            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
    
                <Col md='6'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Cliente">
                        <Form.Control value={razaoSocial} disabled />             
                    </FloatingLabel>                  
                </Col>

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid4" label="Nome Fantasia">
                        <Form.Control value={apelido} disabled />
                      </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid4" label="CNPJ / CPF">
                        <Form.Control value={inscricao} disabled />
                      </FloatingLabel>      
                </Col>                

            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>

                <FloatingLabel controlid="floatingInputGrid7" label="Vertical">
                        <Form.Select value={registro.tipoVertical} disabled>
                            <option value="" disabled></option>
                            <option value="1">Empresa</option>
                            <option value="2">Refeitório</option>
                            <option value="3">Academia</option>
                            <option value="4">Condomínio</option>
                            <option value="5">Hospital</option>
                            <option value="6">Escola</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>                

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Banco De Dados">
                        <Form.Select value={registro.tipoBancoDados} disabled>
                            <option value="1">MySql</option>
                            <option value="2">SqlServer</option>
                            <option value="3">Oracle</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>
                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Equip.Adicional">
                        <Form.Control value={registro.equipamentoAdicional} disabled>
                        </Form.Control>
                    </FloatingLabel>                    
                </Col>
                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Server Adicional">
                        <Form.Control value={registro.serverAdicional} disabled>
                        </Form.Control>
                    </FloatingLabel>                    
                </Col>
            </Row>

            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Integrações & Módulos Adicionais</Alert.Heading>        
            <br /> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integraAlunos"
                        label="Alunos"                      
                        readOnly
                        checked={registro.integracaoAlunos === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integracaoFuncionarios"
                        label="Funcionários"                      
                        readOnly
                        checked={registro.integracaoFuncionarios === 1}                       
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integracaoPacientes"
                        label="Pacientes"                      
                        readOnly
                        checked={registro.integracaoPacientes === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="terceiros"
                        label="Portal Terceiros"                      
                        readOnly
                        checked={registro.portalTerceiros === 1}                       
                    />
                </Col>

            </Row>                    

            <Row className="g-2" style={{marginBottom: '1rem'}}>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="locacao"
                        label="Locacao"                      
                        readOnly
                        checked={registro.locacao === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integraWpe"
                        label="BmaPonto"                      
                        readOnly
                        checked={registro.wpe === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="lgpd"
                        label="Módulo LGPD"                      
                        readOnly
                        checked={registro.moduloLGPD === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="contrato"
                        label="Contrato"                      
                        readOnly
                        checked={registro.contrato === 1}
                    />
                </Col>

            </Row>                    

            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Outras Informações</Alert.Heading>        
            <br /> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='1' style={{alignItems: 'center', paddingTop: '12px'}}>
                    <div className={styles.agendaWeb}>
                        <Form.Check 
                            type="switch"
                            id="agendaWeb"
                            label="Agenda Web"                      
                            readOnly
                            checked={registro.agendaWeb === 1}                        
                        />
                    </div>
                </Col>

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Usuários Agenda">
                        <Form.Control value={registro.usuariosAgenda} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Data Pedido">
                        <Form.Control value={registro.dataPedido} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Data de Validade">
                        <Form.Control value={registro.dataValidade} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Data Liberação">
                        <Form.Control value={registro.dataLiberacao} disabled />
                    </FloatingLabel>      
                </Col>

            </Row>                    

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='6'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Data Cancelamento">
                        <Form.Control value={registro.dataCancelamento} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='6'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Motivo">
                        <Form.Control value={registro.motivoCancelamento} disabled />
                    </FloatingLabel>      
                </Col>

            </Row>
            <Row style={{marginBottom: '1rem'}}>
                <Col md='12' >
                    <FloatingLabel controlid="floatingInputGrid11" label="Observações">
                        <Form.Control as="textarea" style={{height: '100px'}} value={registro.observacao} disabled />
                    </FloatingLabel>      
                </Col>    
            </Row>  
            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md= '6'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Quantidade de Parcelas">
                        <Form.Control value={registro.quantidadeParcelas} disabled />
                    </FloatingLabel>
                </Col>

                <Col md= '6'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Valor da Parcela (R$)">
                        <Form.Control value={valorParcela} disabled />
                    </FloatingLabel>
                </Col>
            </Row>                    

            <Row className="g-2" style={{marginBottom: '1rem'}}>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid13" label="Valor Modulo LGPD (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control value={registro.valorModuloLGPD} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid19" label="Valor Equip.Adicional (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control value={registro.valorEquipamentoAdicional} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid22" label="Valor Server Adicional (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control value={registro.valorServerAdicional} disabled />
                    </FloatingLabel>      
                </Col>
            </Row>                    

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid13" label="Valor Licença (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control value={registro.valorLicenca} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Total Pedido (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control value={registro.valorBruto} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid14" label="Valor Mensal (R$)" style={{fontWeight: 'bold'}}>                       
                        <Form.Control value={registro.valorMensal} disabled />
                    </FloatingLabel>      
                </Col>
            </Row>

            </Alert>

            <div className={styles.areaBtn}>    
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )
}

export default ViewPedidoBmaAcesso;
