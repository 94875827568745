import React from "react";
import api from "../../Api/apiAxios";
import { USUARIOS_VALIDAR_EMAIL } from "../../Api/apiFetch";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Col, FloatingLabel, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Loading from "../../Components/Loading";
import Mensagem from "../../Components/Mensagem";
import { MyContext } from "../../Context/MyContext";
import styles from "./NovoUsuario.module.css";

const NovoUsuario = () => {

    const navigate = useNavigate();

    const { mensagemGlobal, setMensagemGlobal, tipoMensagemGlobal, 
                            setTipoMensagemGlobal } = React.useContext(MyContext);

    const [loading, setLoading] = React.useState(false);

    const tipos = [
        {id2: 1, tipo: 'Admin - BMA'}, 
        {id2: 2, tipo: 'Consulta - BMA'}, 
        {id2: 3, tipo: 'Admin - Revenda'}, 
        {id2: 4, tipo: 'Consulta - Revenda'},         
     ];         

    var regexEmail = /\S+@\S+\.\S+/;

    const [distribuidores, setDistribuidores] = React.useState([]);
    const [distribuidorSelecionado, setDistribuidorSelecionado] = React.useState(null);
    const [tipoUsuarioSelecionado, setTipoUsuarioSelecionado] = React.useState(null);

    const [nome, setNome] = React.useState('');
    const [senha, setSenha] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [fone, setFone] = React.useState('');
    const [celular, setCelular] = React.useState('');

    const [mensagem, setMensagem] = React.useState('');
    const [tipoMensagem, setTipoMensagem] = React.useState(false);

    React.useEffect(() => {
        async  function pesquisarDistribuidores() { 
            setLoading(true);                  
            const response = await api.get('/Distribuidor');
            setDistribuidores(response.data);
            setLoading(false); 
        }

        pesquisarDistribuidores();            
            
    }, [])

    function getValueDistribuidor(ev) {
        const idSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setDistribuidorSelecionado(idSelected);       
    };  

    function getValueTipoUsuario(e) {
        const idSelected2 = e.target.children[e.target.selectedIndex].getAttribute('id2');
        setTipoUsuarioSelecionado(idSelected2);       
    };  

    async function handleSubmit(event) {
        event.preventDefault();     
        setMensagemGlobal(null);

        //#region Validação FORM 
        if  (distribuidorSelecionado === null) {
            setMensagem("Faltou Selecionar o Distribuidor");
            setTipoMensagem("danger");
            return;
        }

        if  (tipoUsuarioSelecionado === null) {
            setMensagem("Faltou Selecionar o Tipo do Usuário");
            setTipoMensagem("danger");
            return;
        }

        if  (!regexEmail.test(email))
        {
            setMensagem("Formato do E-mail Invalido !");
            setTipoMensagem("danger");
            return;
        }

        if  (nome.length === 0 || senha.length <5) {
            setMensagem("Nome não preenchido ou Senha com numero de caracteres inferior a 5");
            setTipoMensagem("danger");
            return;
        }

        const { url, options } = USUARIOS_VALIDAR_EMAIL({ email });        
        const response = await fetch(url, options);       
        var retorno = await(response.json());
        if  (retorno.id > 0) {
            setMensagem("E-mail já cadastrado, favor informar outro e-mail !");
            setTipoMensagem("danger");
            return;
        }
        //#endregion

        const dataAtual = new Date();
        let regUsuario = {
            Id: 0,
            DistribuidorId: Number(distribuidorSelecionado),
            Nome: nome,
            Email: email,
            Senha: senha,
            Telefone: fone, 
            Celular: celular, 
            Status: 1,
            TipoUsuario: Number(tipoUsuarioSelecionado),
            DataHoraCadastro: dataAtual,
            DataHoraUltimoAcesso: dataAtual,
        }       
       
        const ret = await api.post("Usuario/", 
                    JSON.stringify(regUsuario), 
              {
                  headers: { 'Content-Type': 'application/json'}
              });

        setMensagemGlobal(ret.data);
        setTipoMensagemGlobal("success");               
        navigate("/usuarios");       
    }

    if  (loading) return <Loading />
    
    return (

        <section className={styles.form}>

            {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
            <Alert.Heading style={{textAlign: 'center'}}>Inserir Novo Usuário</Alert.Heading>        
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                    <FloatingLabel controlid="floatingInputGrid" label="Selecione o Distribuidor">
                        <Form.Select onChange={getValueDistribuidor}  className=" form-select-control" >
                            <option></option>
                            {distribuidores.map((distribuidor) => (
                                <option key={distribuidor.id} 
                                        id={distribuidor.id}
                                        value={distribuidor.id}
                                        selected={distribuidor.id === distribuidorSelecionado}>{distribuidor.razaoSocial}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>                  
                </Col>
            </Row>

            <Row className="g2" style={{marginBottom: '1rem'}}>

                <Col md='7'>
                    <FloatingLabel controlid="floatingInputGrid" label="Nome">
                        <Form.Control type="text" placeholder="name@example.com" required
                            value={nome} onChange={({ target }) => setNome(target.value)} />
                    </FloatingLabel>
                </Col>                

                <Col md='5'>
                    <FloatingLabel controlid="floatingInputGrid" label="Senha">
                        <Form.Control type="text" placeholder="name@example.com" required
                            value={senha} onChange={({ target }) => setSenha(target.value)} /> 
                    </FloatingLabel>
                </Col>                

            </Row>                

            <Row className="g2" style={{marginBottom: '1rem'}}>

                <Col md='12'>
                    <FloatingLabel controlid="floatingInputGrid" label="E-mail">
                        <Form.Control type="email" placeholder="name@example.com" 
                            value={email} onChange={({ target }) => setEmail(target.value)} /> 
                    </FloatingLabel>
                </Col>     

            </Row>               

            <Row className="g2" style={{marginBottom: '1rem'}}>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid" label="Fone (Fixo)">
                        <Form.Control type="text" placeholder="name@example.com" 
                            value={fone} onChange={({ target }) => setFone(target.value)} />
                    </FloatingLabel>
                </Col>                

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid" label="Celular">
                        <Form.Control type="text" placeholder="name@example.com" 
                            value={celular} onChange={({ target }) => setCelular(target.value)} />
                    </FloatingLabel>
                </Col>                

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid" label="Tipo do Usuário">
                        <Form.Select onChange={getValueTipoUsuario}  className=" form-select-control" >
                            <option></option>
                            {tipos.map((reg) => (
                                    <option key={reg.id2} 
                                            id2={reg.id2}
                                            value={reg.id2}
                                            selected={reg.id2 === tipoUsuarioSelecionado}>{reg.tipo}</option>
                            ))}

                        </Form.Select>                        
                    </FloatingLabel>
                </Col>                
            </Row>                


            </Alert>              

            <div className={styles.areaBtn}>                
               <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn}>Confirmar</Button>                         
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )
}

export default NovoUsuario;