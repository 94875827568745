import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import { Button, Form, Row, Col, FloatingLabel, Alert } from "react-bootstrap";
import styles from "./NovoContrato.module.css";

const NovoContrato = () => {

    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');   

    const { mensagemGlobal, setMensagemGlobal, setTipoMensagemGlobal, tipoMensagemGlobal } = React.useContext(MyContext);

    const [loading, setLoading] = React.useState(false);

    const [distribuidores, setDistribuidores] = React.useState([]);
    const [clientes, setClientes] = React.useState([]);

    const [distribuidorSelecionado, setDistribuidorSelecionado] = React.useState('');
    const [clienteSelecionado, setClienteSelecionado] = React.useState('');
    const [numeroSerie, setNumeroSerie] = React.useState('');
    const [valorContrato, setValorContrato] = React.useState(0);
    const [sistema, setSistema] = React.useState('');
    const [pedidoId, setPedidoId] = React.useState(0);

    React.useEffect(() => {     
        async  function pesquisarDistribuidores() { 
               setLoading(true);                  
               const response = await api.get('/Distribuidor');

               if  (nivelUsuario !== "1") {
                   const distribuidoresFiltrados = response.data.filter((distribuidor) => 
                       distribuidor.id.toString().includes(idDistribuidor));               
                       setDistribuidores(distribuidoresFiltrados);            
               } else {
                        setDistribuidores(response.data);            
               }    
//                console.log(response.data);
               setLoading(false);       
        }

        pesquisarDistribuidores();            

    }, []);    

     React.useEffect(() => {
        if  (distribuidorSelecionado) {
            loadClientes(distribuidorSelecionado);
        }
    }, [distribuidorSelecionado])


    async function loadClientes(id) {
            const response = await api.get(`/Cliente/${id}/A`);
            setClientes(response.data);            
    };    

    function getValueDistribuidor(ev) {
        const idSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setDistribuidorSelecionado(idSelected);            
    };  

    function getValueCliente(ev) {
        const idClienteSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setClienteSelecionado(idClienteSelected); 
        if  (idClienteSelected) {
            buscarPedido(idClienteSelected);      
        }
    };  

    function buscarPedido(id) {
        (async () => {
            try {   
                setMensagemGlobal(null);

                switch (sistema) {
                    case "PONTO":
                        const response = await api.get(`/BmaPonto/ultimo-pedido-cliente?idCliente=${id}`);   
                        setNumeroSerie(response.data.numeroSerie);
                        setPedidoId(response.data.id);
                        if  (response.data.contrato === 1) {
                            setMensagemGlobal("Cliente ja possui contrato deste Módulo !");
                            setTipoMensagemGlobal("danger");
                            setPedidoId(0);
                            return;                
                        }
                        else 
                            if  (response.data.contrato === 0 && response.data.statusPedido !== 1) {
                                setMensagemGlobal("Cliente não possui Pedido Liberado. Contrato não pode ser inserido.");
                                setTipoMensagemGlobal("danger");
                                setPedidoId(0);
                                return;                
                            }
                        break;

                    case "ACESSO":
                        const response1 = await api.get(`/BmaAcesso/ultimo-pedido-cliente?idCliente=${id}`);   
                        setNumeroSerie(response1.data.numeroSerie);
                        setPedidoId(response1.data.id);
                        if  (response1.data.contrato === 1) {
                            setMensagemGlobal("Cliente ja possui contrato deste Módulo !");
                            setTipoMensagemGlobal("danger");
                            setPedidoId(0);
                            return;                
                        }
                        else 
                            if  (response1.data.contrato === 0 && response1.data.statusPedido !== 1) {
                                setMensagemGlobal("Cliente não possui Pedido Liberado. Contrato não pode ser inserido.");
                                setTipoMensagemGlobal("danger");
                                return;                
                            }
                        break;
    
                    case "FOLHA":
                        const response2 = await api.get(`/BmaFolha/ultimo-pedido-cliente?idCliente=${id}`);   
                        setNumeroSerie(response2.data.numeroSerie);
                        setPedidoId(response2.data.id);
                        if  (response2.data.contrato === 1) {
                            setMensagemGlobal("Cliente ja possui contrato deste Módulo !");
                            setTipoMensagemGlobal("danger");
                            return;                
                        }
                        else 
                            if  (response2.data.contrato === 0 && response2.data.statusPedido !== 1) {
                                setMensagemGlobal("Cliente não possui Pedido Liberado. Contrato não pode ser inserido.");
                                setTipoMensagemGlobal("danger");
                                return;                
                            } 
                        break;
                }
            } catch (error) {
            }            
        }
        )();
    }

    async function handleSubmit(ev) {
        ev.preventDefault();
        setMensagemGlobal(null);

        if  (distribuidorSelecionado === '' || distribuidorSelecionado === null || clienteSelecionado ===  '' || clienteSelecionado === null) {
            setMensagemGlobal("Faltou Selecionar Distribuidor e/ou Cliente");
            setTipoMensagemGlobal("danger");
            return;
        }

        if  (clienteSelecionado) {
            buscarPedido(clienteSelecionado);      
            if  (pedidoId === 0) return;
        }

        const dataAtual = new Date();       
        let regContrato = {
            Id: 0,
            PedidoId: Number(pedidoId),                        
            StatusContrato: 1,
            DataAtivacao: dataAtual,
            DataAtualizacao: dataAtual,
            ValorFixo: 1,
            Valor: Number(valorContrato),
            Sistema: sistema,
        }                
               
        const ret = await api.post("Contrato/", 
                    JSON.stringify(regContrato), 
              {
                  headers: { 'Content-Type': 'application/json'}
              });

        setMensagemGlobal(ret.data);
        setTipoMensagemGlobal("success");               
        navigate("/contratos");

    }


    if  (loading) return <Loading />

    return (
        <section className={styles.form}>
            {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center'}}>Inserir Contrato</Alert.Heading>        
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}> 
                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Selecione o Tipo de Sistema">
                        <Form.Select value={sistema} onChange={({target}) => setSistema(target.value)}>
                            <option value="" disabled></option>
                            <option value="PONTO">BmaPonto</option>
                            <option value="ACESSO">BmaAcesso</option>
                            <option value="FOLHA">BmaFolha</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='8'>
                    <FloatingLabel controlid="floatingInputGrid" label="Selecione o Distribuidor">
                        <Form.Select onChange={getValueDistribuidor}  className=" form-select-control">
                            <option></option>                               
                            {distribuidores.map((distribuidor) => (
                                <option key={distribuidor.id} 
                                        id={distribuidor.id}
                                        value={distribuidor.id}
                                        selected={distribuidor.id === distribuidorSelecionado}>{distribuidor.razaoSocial}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>    
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>    
                <Col md='12'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Selecione o Cliente">
                        <Form.Select onChange={getValueCliente}  className=" form-select-control" >
                            <option></option>
                            {clientes.map((cliente) => (
                                <option key={cliente.id} 
                                        id={cliente.id}
                                        value={cliente.id}
                                        selected={cliente.id === clienteSelecionado}>{cliente.razaoSocial}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>                  
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='4'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Número de Série">
                        <Form.Control placeholder="NS" value={numeroSerie} disabled
                                    onChange={({ target }) => setNumeroSerie(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='4'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Id Pedido">
                        <Form.Control placeholder="ID" value={pedidoId} disabled
                                    onChange={({ target }) => setPedidoId(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Valor Contrato">
                        <Form.Control placeholder="Val.Contrato" value={valorContrato} 
                                    onChange={({ target }) => setValorContrato(target.value)}/>
                    </FloatingLabel>      
                </Col>
            </Row>

            </Alert>

            <div className={styles.areaBtn}>                
               <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                       disabled={nivelUsuario !== "1" || pedidoId === 0}>Confirmar
               </Button>
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )

}

export default NovoContrato;