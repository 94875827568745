import React from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { FolderPlus, Search } from "react-bootstrap-icons";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

export const HeaderVersoes = ( {titulo, textoBotao, pesquisa, setPesquisa, 
                               tipoPesquisa, setTipoPesquisa, 
                               pesquisarClick, adicionarClick} ) => {

    const [pesquisarPor, setPesquisarPor] = React.useState('0');

    function atualizarTipoPesquisa( {target} ) {
        setPesquisarPor(target.value);
        setTipoPesquisa(target.value);
    }

    return (
        <Alert style={{background: 'ghostwhite', color: 'dimGray'}}>
            <Row>
              <Col md='2'>
                 <Alert.Heading style={{textAlign: 'left', fontSize: '1.5rem'}}>{titulo}</Alert.Heading>
              </Col>    

              <Col md='2'>
                <Form.Select  onChange={({target}) => atualizarTipoPesquisa({target})} value={pesquisarPor} className=" form-select-control" >
                        <option disabled value="0">Opções</option>
                        <option value="*">Todos</option>
                        <option value="ponto">Ponto</option>
                        <option value="acesso">Acesso</option>
                        <option value="folha">Folha</option>
                        <option value="versao">Número Versão</option>
                </Form.Select>      
              </Col>

              <Col md='3'>
                <InputGroup className="mb-3">
                    <Form.Control placeholder="Pesquisa Parcial:" value={pesquisa}
                                  style={{fontSize: '0.8rem'}}
                                  onChange={({ target }) => setPesquisa(target.value)}/>

                        <Button onClick={pesquisarClick} variant="outline-primary">
                            <Search size={15} />
                        </Button>
                </InputGroup>
              </Col>    

              {textoBotao !== "hide" ?
               (
                <Col md='3'>
                    <Button onClick={adicionarClick} 
                            variant="outline-primary"
                            style={{borderRadius: '8px'}}
                    >
                    <FolderPlus size={22}/>{textoBotao}</Button>
                </Col>
              )
              :
              (
                <></>
              )
             }            
             </Row>             
        </Alert>
    )
}

export default HeaderVersoes;