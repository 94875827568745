import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import { Button, Form, Row, Col, FloatingLabel, Alert } from "react-bootstrap";
import { DashLg, PlusLg } from "react-bootstrap-icons";
import styles from "./EditarPedidoBmaFolha.module.css";

const EditarPedidoBmaFolha = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidorLogado = window.localStorage.getItem('distribuidorId');   

    const { mensagemGlobal, setMensagemGlobal, setTipoMensagemGlobal, tipoMensagemGlobal } = React.useContext(MyContext);

    const [loading, setLoading] = React.useState(false);

    const [clientes, setClientes] = React.useState([]);
    const [idDistribuidor, setIdDistribuidor] = React.useState('');

    const [versao, setVersao] = React.useState('');
    const [release, setRelease] = React.useState('');
    const [clienteSelecionado, setClienteSelecionado] = React.useState('');
    const [tipoPedido, setTipoPedido] = React.useState('');
    const [apelido, setApelido] = React.useState('Utilizado na personalização');
    const [numeroSerie, setNumeroSerie] = React.useState('000000');
    const [faixaFuncionarios, setFaixaFuncionarios] = React.useState(25);
    const [tipoPlano, setTipoPlano] = React.useState('');
    const [quantidadeUsuarios, setQuantidadeUsuarios] = React.useState(0);
    const [tipoBanco, setTipoBanco] = React.useState('');
    const [moduloSST, setModuloSST] = React.useState(false);
    const [moduloPAJ, setModuloPAJ] = React.useState(false);
    const [moduloFolha, setModuloFolha] = React.useState(false);
    const [moduloExamesMedicos, setModuloExamesMedicos] = React.useState(false);
    const [moduloCompetenciaTreinamento, setModuloCompetenciaTreinamento] = React.useState(false);
    const [moduloCargosSalarios, setModuloCargosSalarios] = React.useState(false);
    const [moduloRpa, setModuloRpa] = React.useState(false);
    const [moduloEpi, setModuloEpi] = React.useState(false);
    const [moduloPpp, setModuloPpp] = React.useState(false);
    const [wpe, setWpe] = React.useState(false);
    const [controleEmpresas, setControleEmpresas] = React.useState(false);
    const [contrato, setContrato] = React.useState(true);
    const [observacao, setObservacao] = React.useState('');
    const [valorPedido, setValorPedido] = React.useState('0.00');
    const [valorMensal, setValorMensal] = React.useState('0.00');
    const [dataPedido, setDataPedido] = React.useState('');
    const [quantidadeParcelas, setQuantidadeParcelas] = React.useState('');
    const [statusPedido, setStatusPedido] = React.useState('');

    React.useEffect(() => {
        (async () => {
            try {   
                setLoading(true);
                const response = await api.get(`/BmaFolha/${id}`);    

             //   O campo StatusPedido e retornado aqui como segurança, para que nenhum usuario acesse diretamente
             //   a URL da edição, modificando o id do Pedido.. Assim testamos se o pedido em questão esta realmente
             //   em Aberto... 

                setStatusPedido(response.data.statusPedido);
                setIdDistribuidor(response.data.distribuidorId);
                setTipoPedido(response.data.tipoPedido);
                setNumeroSerie(response.data.numeroSerie);
                setTipoPlano(response.data.tipoInstalacao);
                setFaixaFuncionarios(response.data.faixaFuncionarios);
                setTipoBanco(response.data.tipoBancoDados);
                setQuantidadeUsuarios(response.data.quantidadeUsuarios);
                setObservacao(response.data.observacao);
                setValorPedido(response.data.valorBruto);
                setValorMensal(response.data.valorMensal);
                setVersao(response.data.versaoSistema);
                setRelease(response.data.versaoRelease);
                setDataPedido(response.data.dataPedido);               
                if  (response.data.quantidadeParcelas) {
                    setQuantidadeParcelas(response.data.quantidadeParcelas)
                }

                if  (response.data.controleEmpresas === 1)
                    setControleEmpresas(true)
                else
                    setControleEmpresas(false);    

                if  (response.data.moduloFolha === 1)
                    setModuloFolha(true)
                else
                    setModuloFolha(false);    

                if  (response.data.moduloExamesMedicos === 1)
                    setModuloExamesMedicos(true)
                else
                    setModuloExamesMedicos(false);    

                if  (response.data.moduloSST === 1)
                    setModuloSST(true)
                else
                    setModuloSST(false);    

                if  (response.data.moduloPAJ === 1)
                    setModuloPAJ(true)
                else
                    setModuloPAJ(false);    

                if  (response.data.moduloEpi === 1)
                    setModuloEpi(true)
                else
                    setModuloEpi(false);    

                if  (response.data.moduloRpa === 1)
                    setModuloRpa(true)
                else
                    setModuloRpa(false);    

                if  (response.data.moduloPpp === 1)
                    setModuloPpp(true)
                else
                    setModuloPpp(false);    

                if  (response.data.moduloCompetenciaTreinamento === 1)
                    setModuloCompetenciaTreinamento(true)
                else
                    setModuloCompetenciaTreinamento(false);    

                if  (response.data.moduloCargosSalarios === 1)
                    setModuloCargosSalarios(true)
                else    
                    setModuloCargosSalarios(false);
                     
                if  (response.data.wpe === 1)
                    setWpe(true)
                else
                    setWpe(false);    

                if  (response.data.contrato === 1)
                    setContrato(true)
                else
                    setContrato(false);                  
                    
                loadClientes(response.data.distribuidorId);
                setClienteSelecionado(response.data.clienteId);
                buscarCliente(response.data.clienteId);
                setLoading(false);

                const distribuidorLogado = Number(idDistribuidorLogado);
                const distribuidorPedido = Number(response.data.distribuidorId);

                if  ((distribuidorLogado !== distribuidorPedido) && (nivelUsuario === "3" || nivelUsuario === "4")) {
                    setMensagemGlobal("Ops, você não tem permissão para acessar este Pedido !");
                    setTipoMensagemGlobal("danger");
                    navigate("/pedidosfolha");
                }

            } catch (error) {
                alert(error);
            }            
        }
        )();

    }, [id]);                

    async function loadClientes(id) {
            const response = await api.get(`/Cliente/${id}/A`);
            setClientes(response.data);            
    };    

    function getValueCliente(ev) {
        const idClienteSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setClienteSelecionado(idClienteSelected); 
        if  (idClienteSelected) {
            buscarCliente(idClienteSelected);      
        }
    };  

    function buscarCliente(id) {
        (async () => {
            try {   
                const response = await api.get(`/Cliente/${id}`);    
                setApelido(response.data.nomeFantasia);                
            } catch (error) {
            }            
        }
        )();
    }

    function adicionar() {
        if  (faixaFuncionarios > 25) {
             setFaixaFuncionarios((faixaFuncionarios => faixaFuncionarios + 50));
        }
        else setFaixaFuncionarios((faixaFuncionarios => faixaFuncionarios + 25));
        
    }

    function subtrair() {
        if  (faixaFuncionarios > 50) {
            setFaixaFuncionarios((faixaFuncionarios => faixaFuncionarios - 50));        
        }
        else if  (faixaFuncionarios > 0) {
            setFaixaFuncionarios(25);
        }
    }

    function buscarCliente(id) {
        (async () => {
            try {   
                const response = await api.get(`/Cliente/${id}`);    
                setApelido(response.data.nomeFantasia);
                
            } catch (error) {
            }            
        }
        )();
    }  

    async function handleSubmit(ev) {
        ev.preventDefault();
        setMensagemGlobal(null);

        if  (tipoPedido.length === 0) {
            setMensagemGlobal("Faltou Selecionar o Tipo do Pedido");
            setTipoMensagemGlobal("danger");
            return;
        }
        if  (tipoPlano.length === 0) {
            setMensagemGlobal("Faltou Selecionar o Tipo do Plano");
            setTipoMensagemGlobal("danger");
            return;
        }
        
        if  (tipoBanco.length === 0) {
            setMensagemGlobal("Faltou Selecionar o Banco de Dados");
            setTipoMensagemGlobal("danger");
            return;
        }

        if  (clienteSelecionado === null || clienteSelecionado === '') {
            setMensagemGlobal("Faltou Selecionar o Cliente");
            setTipoMensagemGlobal("danger");
            return;
        }

        let regPedido = {
            Id: Number(id),
            ModuloFolha: moduloFolha ? 1 : 0,       
            ModuloSST: moduloSST ? 1 : 0,       
            ModuloPAJ: moduloPAJ ? 1 : 0,       
            ModuloCompetenciaTreinamento: moduloCompetenciaTreinamento ? 1 : 0,
            ModuloCargosSalarios: moduloCargosSalarios ? 1 : 0,
            ModuloEpi: moduloEpi ? 1 : 0,
            ModuloRpa: moduloRpa ? 1 : 0,
            ModuloExamesMedicos: moduloExamesMedicos ? 1 : 0,
            ModuloPpp: moduloPpp ? 1 : 0,
            ControleEmpresas: controleEmpresas ? 1 : 0,
            DistribuidorId: Number(idDistribuidor),    
            TipoPedido: Number(tipoPedido),
            ClienteId: Number(clienteSelecionado),    
            NumeroSerie: Number(numeroSerie),
            StatusPedido: 0,
            TipoBancoDados: Number(tipoBanco),
            TipoInstalacao: Number(tipoPlano),
            VersaoSistema: versao,
            VersaoRelease: release,
            DataPedido: dataPedido,
            Observacao: observacao,
            MotivoCancelamento: '',
            NumeroOrdemCompra: '',
            ValorBruto: Number(valorPedido),
            ValorDesconto: 0,
            ValorLiquido: Number(valorPedido),
            ValorMensal: Number(valorMensal),
            IntegracaoAcesso: 0,
            IntegracaoFolha: 0,
            IntegracaoPonto: wpe ? 1 : 0,
            Contrato: contrato ? 1 : 0,
            FaixaFuncionarios: Number(faixaFuncionarios),            
            QuantidadeUsuarios: Number(quantidadeUsuarios),
            QuantidadeParcelas: quantidadeParcelas ? Number(quantidadeParcelas) : 1,
        }              
      
        const ret = await api.put("BmaFolha/", 
                    JSON.stringify(regPedido), 
              {
                  headers: { 'Content-Type': 'application/json'}
              });

        setMensagemGlobal(ret.data);
        setTipoMensagemGlobal("success");               
        navigate("/pedidosfolha");

    }


    if  (loading) return <Loading />

    return (
        <section className={styles.form}>
            {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center'}}>Editar Pedido BmaFolha</Alert.Heading>        
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}>   
                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Selecione o Tipo de Pedido">
                        <Form.Select value={tipoPedido} onChange={({target}) => setTipoPedido(target.value)}>
                            <option value="" disabled></option>
                            <option value="0">Cópia Nova</option>
                            <option value="1">Atualização</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>
                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Número de Série">                               
                            <Form.Control placeholder="NS" value={numeroSerie} 
                                    disabled={nivelUsuario !== "1"}
                                    onChange={({ target }) => setNumeroSerie(target.value)}/>                       
                    </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid6" label="Versão">
                            <Form.Control placeholder="Ver" value={versao} 
                                    disabled={nivelUsuario !== "1"}
                                    onChange={({ target }) => setVersao(target.value)}/>                        
                    </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid7" label="Release">
                            <Form.Control placeholder="Release" value={release} 
                                    disabled={nivelUsuario !== "1"}
                                    onChange={({ target }) => setRelease(target.value)}/>             
                      </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>    
                <Col md='7'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Selecione o Cliente">
                        <Form.Select onChange={getValueCliente}  className=" form-select-control" >
                            <option></option>
                            {clientes.map((cliente) => (
                                <option key={cliente.id} 
                                        id={cliente.id}
                                        value={cliente.id}
                                        selected={cliente.id === clienteSelecionado}>{cliente.razaoSocial}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>                  
                </Col>

                <Col md='5'>
                     <FloatingLabel controlid="floatingInputGrid4" label="Nome Fantasia">
                        <Form.Control placeholder="Apelido" value={apelido} disabled
                                    onChange={({ target }) => setApelido(target.value)}/>
                      </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Faixa Funcionários">
                        <Form.Control placeholder="Faixa" value={faixaFuncionarios} 
                                    onChange={({ target }) => setFaixaFuncionarios(target.value)}/>
                    </FloatingLabel>      
                </Col>

                <Col md='2'>
                    <Button onClick={adicionar} variant="outline-secondary">
                           <PlusLg size={20}/>
                    </Button>   
                    <span> </span>                    
                    <Button onClick={subtrair} variant="outline-secondary">
                           <DashLg size={20}/>
                    </Button>   
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid7" label="Selecione o Tipo de Instalação">
                        <Form.Select value={tipoPlano} onChange={({target}) => setTipoPlano(target.value)}>
                            <option value="" disabled></option>
                            <option value="0">Licenciado</option>
                            <option value="1">SaaS</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid8" label="Quantidade Usuários">
                        <Form.Control placeholder="NS" value={quantidadeUsuarios} 
                                    onChange={({ target }) => setQuantidadeUsuarios(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Banco De Dados">
                        <Form.Select value={tipoBanco} onChange={({target}) => setTipoBanco(target.value)}>
                            <option value="" disabled></option>
                            <option value="1">MySql</option>
                            <option value="2">SqlServer</option>
                            <option value="3">Oracle</option>
                            {/* <option value="4">SQLite</option> */}
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>                
            </Row>

            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Integrações & Módulos Adicionais</Alert.Heading>        
            <br /> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="ControleEmpresa"
                        label="Controle Empresas"                      
                        onChange={() => setControleEmpresas(!controleEmpresas)}
                        checked={controleEmpresas}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="CalculoFolha"
                        label="Cálculo Folha"                      
                        onChange={() => setModuloFolha(!moduloFolha)}
                        checked={moduloFolha}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="Rpa"
                        label="RPA"                      
                        onChange={() => setModuloRpa(!moduloRpa)}
                        checked={moduloRpa}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="Treinamento"
                        label="Competencia/Treinamento"                      
                        onChange={() => setModuloCompetenciaTreinamento(!moduloCompetenciaTreinamento)}
                        checked={moduloCompetenciaTreinamento}
                    />
                </Col>
            </Row>                             

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="Cargos"
                        label="Cargos e Salários"                      
                        onChange={() => setModuloCargosSalarios(!moduloCargosSalarios)}
                        checked={moduloCargosSalarios}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="SST"
                        label="SST"                      
                        onChange={() => setModuloSST(!moduloSST)}
                        checked={moduloSST}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="EM"
                        label="Exames Médicos"                      
                        onChange={() => setModuloExamesMedicos(!moduloExamesMedicos)}
                        checked={moduloExamesMedicos}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="ppp"
                        label="PPP"                      
                        onChange={() => setModuloPpp(!moduloPpp)}
                        checked={moduloPpp}
                    />
                </Col>
            </Row>                    

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="epi"
                        label="EPI"                      
                        onChange={() => setModuloEpi(!moduloEpi)}
                        checked={moduloEpi}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="Paj"
                        label="PAJ"                      
                        onChange={() => setModuloPAJ(!moduloPAJ)}
                        checked={moduloPAJ}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integraPonto"
                        label="BmaPonto"                      
                        onChange={() => setWpe(!wpe)}
                        checked={wpe}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="contrato"
                        label="Contrato"                      
                        onChange={() => setContrato(!contrato)}
                        checked={contrato}
                    />
                </Col>
            </Row>                    

            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Outras Informações</Alert.Heading>        
            <br /> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Observações">
                        <Form.Control placeholder="Obs" value={observacao} maxLength={250} 
                                      onChange={({ target }) => setObservacao(target.value)}/>
                    </FloatingLabel>      
                </Col>
            </Row>                    

            {nivelUsuario === "1" &&
                <Row className="g-2" style={{marginBottom: '1rem'}}>                    
                    <Col md='12'>
                        <FloatingLabel controlid="floatingInputGrid3" label="Quantidade de Parcelas">
                            <Form.Select value={quantidadeParcelas} onChange={({target}) => setQuantidadeParcelas(target.value)}>
                                <option value="" disabled></option>
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                            </Form.Select>
                        </FloatingLabel>                    
                    </Col>
                </Row>
            }
            
            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='2'>
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Total Pedido (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control placeholder="Total" value={valorPedido} 
                                      disabled={nivelUsuario !== "1"}
                                      onChange={({ target }) => setValorPedido(target.value)}/>
                    </FloatingLabel>      
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Valor Mensal (R$)" style={{fontWeight: 'bold'}}>                       
                        <Form.Control placeholder="Total" value={valorMensal} 
                                      disabled={nivelUsuario !== "1"}
                                      onChange={({ target }) => setValorMensal(target.value)}/>
                    </FloatingLabel>      
                </Col>
            </Row>                    

            </Alert>

            <div className={styles.areaBtn}>                
                <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn}
                       disabled={nivelUsuario === "2" || nivelUsuario ==="4" || statusPedido > 0}>Atualizar
                </Button>                         
                      
                <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )

}

export default EditarPedidoBmaFolha;