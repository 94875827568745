import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button,Row, Col, FloatingLabel, Form, Table } from "react-bootstrap";
import { Pencil, Trash3 } from "react-bootstrap-icons";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import Loading from "../../Components/Loading";
import HeaderCadastros from "../../Components/HeaderCadastros";
import styles from "./ClientesCnpjIndex.module.css";
import { ModalExclusao } from "../../Components/ModalExclusao";

const ClientesCnpjIndex = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');    

    const { mensagemGlobal, setMensagemGlobal, tipoMensagemGlobal, 
            setTipoMensagemGlobal } = React.useContext(MyContext);

    const [loading, setLoading] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [exclusaoOK, setExclusaoOk] = React.useState(false);
    const [mensagemExclusao, setMensagemExclusao] = React.useState('');
    const [regCnpj, setRegCnpj] = React.useState('');

    const [razaoSocialCliente, setRazaoSocialCliente] = React.useState('');
    const [pesquisa, setPesquisa] = React.useState('');
    const [cnpjs, setCnpjs] = React.useState([]);
    const [tipoInscricao, setTipoInscricao] = React.useState('1');
    const [inscricao, setInscricao] = React.useState('');
    const [razaoSocial, setRazaoSocial] = React.useState('');
    const [status, setStatus] = React.useState(true);

    const tiposInscricao = [
        {idins: 1, tipoIns: 'CNPJ'}, 
        {idins: 2, tipoIns: 'CPF'}, 
     ];       

    function getValueTipoInscricao(e) {
        const idSelected1 = e.target.children[e.target.selectedIndex].getAttribute('idins');
        setTipoInscricao(idSelected1);       
    };  

    React.useEffect(() => {
        (async () => {
            try {   

                setLoading(true);

                const response = await api.get(`/Cliente/${id}`);    
                setRazaoSocialCliente(response.data.razaoSocial);
                
                const resp = await api.get(`/ClienteCnpj/${id}`);
                setCnpjs(resp.data);

                setLoading(false);

            } catch (error) {
                alert(error);
            }            
        }
        )();

    }, [id]);    

    function pesquisarClick(event) {
        event.preventDefault();     
        (async () => {
            try {
                setLoading(true);
                const pesquisaLowerCase = pesquisa.toLowerCase();                       

                const response = await api.get(`/ClienteCnpj/${id}`);
                response.data.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));

                if  (pesquisa !== null) {
                    const registrosFiltrados = response.data.filter((cnpj) => 
                        cnpj.razaoSocial.toLowerCase().includes(pesquisaLowerCase));               
                        setCnpjs(registrosFiltrados);            
                } else {
                       setCnpjs(response.data);
                }    
                setLoading(false);
                setPesquisa('');
                

            } catch (error) {
                alert(error);
            }
        }
        )();

    }

    function atualizarTable() {
        (async () => {
            try {   

                setLoading(true);
                
                const resp = await api.get(`/ClienteCnpj/${id}`);
                setCnpjs(resp.data);

                setLoading(false);
                setExclusaoOk(false);
                setModalOpen(false);

            } catch (error) {
                alert(error);
            }            
        }
        )();
    }

    async function salvarClick(event) {
        event.preventDefault();     

        if  (tipoInscricao === null) {
            setMensagemGlobal("Faltou Selecionar o Tipo da Inscrição");
            setTipoMensagemGlobal("danger");
            return;
        }
    
        if  (razaoSocial.length === 0 || razaoSocial.length <5) {
            setMensagemGlobal("Razão Social não preenchida ou com numero de caracteres inferior a 5");
            setTipoMensagemGlobal("danger");
            return;
        }

        const inscricaoOriginal = inscricao;

        const inscricaoOnlyNumbers = inscricao.toString().replace(/[^0-9]/g,'');

        const tamanho = inscricaoOnlyNumbers.length;

        if  (tamanho > 14) {
            setMensagemGlobal("Campo Inscrição com tamanho inválido !");
            setTipoMensagemGlobal("danger");
        return;
        }

        if  (tipoInscricao === 2 && tamanho !== 11) {
            setMensagemGlobal("CPF inválido !");
            setTipoMensagemGlobal("danger");
            return;
        }

        if  (tipoInscricao === 1 && tamanho !== 14) {
            setMensagemGlobal("CNPJ inválido !");
            setTipoMensagemGlobal("danger");
            return;
        }    

/*         const { url2, options2 } = CLIENTES_VALIDAR_INSCRICAO(inscricaoOnlyNumbers);        
        const response = await fetch(url2, options2);       
        var retorno = await(response.json());
        if  (retorno.distribuidorId > 0 && retorno.distribuidorId === idDistribuidor) 
        {
            setMensagem("CPF/CNPJ já cadastrado, favor informar outra inscrição !");
            setTipoMensagem("danger");
            return;
        }
 */       
        let regClienteCnpj = {
            ClienteId: Number(id),
            InscricaoAdicional: inscricaoOriginal, 
            TipoInscricao: Number(tipoInscricao),           
            RazaoSocial: razaoSocial,
            AtestadoEmitido: 0,
            PedidoId: 0,
            Status: status ? 1 : 0,
        }              
      
     //   console.log(regClienteCnpj);

        const ret = await api.post("ClienteCnpj/", 
                    JSON.stringify(regClienteCnpj), 
              {
                  headers: { 'Content-Type': 'application/json'}
              });          

        if  (ret.data.match(/Sucesso/)) {
            atualizarTable();
            setInscricao('');
            setRazaoSocial('');
            setStatus(true);
        }       
    }
     

    function editarClick(cnpj) {
        setTipoInscricao(cnpj.tipoInscricao);
        setInscricao(cnpj.inscricaoAdicional);
        setRazaoSocial(cnpj.razaoSocial);
        if  (cnpj.status === 1) {
            setStatus(true)
        }
        else { setStatus(false)
        }
    }

    function modalExclusao(cnpj) {
        setRegCnpj(cnpj);
        setExclusaoOk(false);
        setMensagemExclusao(`Deseja Excluir a Inscrição Adicional ${cnpj.inscricaoAdicional} ?`);
        setModalOpen(true);
    }

    function excluirClick(cnpj) {
        (async () => {
            try {
                setLoading(true);
                const response = await api.delete(`/ClienteCnpj/${id}/${cnpj.inscricaoAdicional}`);
                if  (response.data === true) {
                    setLoading(false);
                    setExclusaoOk(false);
                    setModalOpen(false);
                    setMensagemExclusao('');
                    atualizarTable();
                }                
            } catch (error) {
                alert(error);
            }
        }
        )();
    }


    if  (loading) return <Loading />

    if  (modalOpen) return <ModalExclusao setModalOpen={setModalOpen} mensagem={mensagemExclusao} setExclusaoOk={setExclusaoOk}/>

    if  (exclusaoOK) {
        excluirClick(regCnpj);
    }

    return (
        <section className={styles.form}>
            {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

            <HeaderCadastros titulo={"Inscrições"} textoBotao={"hide"} 
                pesquisa={pesquisa} setPesquisa={setPesquisa}
                pesquisarClick={pesquisarClick} />

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                  <Alert.Heading style={{textAlign: 'left'}}>Cliente: {razaoSocialCliente}</Alert.Heading>        
                  <br />

                  <Alert.Heading style={{textAlign: 'left', fontSize: 'large'}}>Inserir / Alterar Inscrição Adicional</Alert.Heading>        
                  <hr />

                  <Row className="g2" style={{marginBottom: '1rem'}}>

                    <Col md='2'>
                        <FloatingLabel controlid="floatingSelectGrid1" label="Tipo Inscrição">
                        <Form.Select onChange={getValueTipoInscricao}  className=" form-select-control" >
                            <option value="" disabled></option>
                            {tiposInscricao.map((reg) => (
                                        <option key={reg.idins} 
                                                idins={reg.idins}
                                                value={reg.idins}
                                                selected={reg.idins === tipoInscricao}>{reg.tipoIns}</option>
                                ))}

                        </Form.Select>                        
                                
                        </FloatingLabel>
                    </Col>            
                    
                    <Col md='3'>
                        <FloatingLabel controlid="floatingSelectGrid1" label="Número Inscrição">
                            <Form.Control placeholder="Número da Inscrição" value={inscricao}
                                        onChange={({ target }) => setInscricao(target.value)}/>
                        </FloatingLabel>      
                    </Col>                

                    <Col md='7'>
                     <FloatingLabel controlid="floatingSelectGrid3" label="Razão Social / Nome">
                        <Form.Control placeholder="Razão Social / Nome" value={razaoSocial}
                                    onChange={({ target }) => setRazaoSocial(target.value)}/>
                      </FloatingLabel>      
                    </Col>                

                  </Row>

                  <Row>
                    <Col md='2'>
                            <Form.Check 
                                type="switch"
                                id="status"
                                label="Ativo"                      
                                onChange={() => setStatus(!status)}
                                checked={status}
                            />
                    </Col> 

                    <Col md='3'>
                        <Form.Label style={{fontSize: "small", fontStyle: "italic", fontWeight: "bold"}}>(Na Inclusão, utilize somente números)</Form.Label>
                    </Col> 

                  </Row>

                  <div className={styles.areaBtn}>                
                       <Button onClick={salvarClick} variant="outline-primary" className={styles.btn}
                               disabled={nivelUsuario === "2" || nivelUsuario === "4"}>Salvar Inscrição Adicional</Button>                         
                       <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
                  </div>    

            </Alert>

            <Table striped hover responsive>
                <thead className={styles.thead}>
                    <tr className={styles.trHead}>
                            <th className={styles.th}>Razão Social</th>
                            <th className={styles.th}>Cnpj / Cpf</th>                          
                            <th className={styles.th}>Status</th>    
                            <th className={styles.th}>Data Inativação</th>    
                            <th className={styles.th}>Data Reativação</th>    
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                {cnpjs.map(cnpj => 
                    <tr key={cnpj.inscricaoAdicional} className={styles.trBody}>                                     
                        <td className={styles.td}>{cnpj.razaoSocial}</td>          
                        <td className={styles.td}>{cnpj.inscricaoAdicional}</td>
                        <td className={styles.td}>{cnpj.status === 1 ? "Ativo" : "Inativo"}</td>
                        {cnpj.dataInativacao ?
                            <td className={styles.td}>
                                 {cnpj.dataInativacao.substring(8,10)}/ 
                                 {cnpj.dataInativacao.substring(5,7)}/   
                                 {cnpj.dataInativacao.substring(0,4)}    
                            </td>
                          : <td> * * * * * * </td>  
                        }
                        {cnpj.dataReativacao ?
                            <td className={styles.td}>
                                 {cnpj.dataReativacao.substring(8,10)}/ 
                                 {cnpj.dataReativacao.substring(5,7)}/   
                                 {cnpj.dataReativacao.substring(0,4)}    
                            </td>
                          : <td> * * * * * * </td>  
                        }
                      
                        {nivelUsuario === "1" || nivelUsuario === "3" ?
                            <td className={styles.td}>
                                <Button onClick={() => editarClick(cnpj)}
                                        variant="outline-warning">
                                        <Pencil size={15}/>
                                </Button>   

{/*                                 <span> </span>
                                <Button onClick={() => modalExclusao(cnpj)}
                                        variant="outline-danger" disabled>
                                        <Trash3 size={15}/>
                                </Button>   
 */}
                            </td> 
                        : <></>    
                       }
                    </tr>
                )}
            </tbody>

            </Table>

      </section>

    )
}

export default ClientesCnpjIndex;