import React from "react";
import api from "../../../Api/apiAxios";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Col, FloatingLabel, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Loading from "../../../Components/Loading";
import styles from "./ViewPersonalizacaoAcesso.module.css";

const ViewPersonalizacaoAcesso = () => {
    const { id } = useParams();

    const navigate = useNavigate();     

    const [loading, setLoading] = React.useState(false);
    const [registro, setRegistro] = React.useState({});
    const [dataValidade, setDataValidade] = React.useState('');
    const [dataPedido, setDataPedido] = React.useState('');
    const [dataLiberacao, setDataLiberacao] = React.useState('');
    const [dataCancelamentoContrato, setDataCancelamentoContrato] = React.useState('');
    const [ultimoValorContrato, setUltimoValorContrato] = React.useState('');

    React.useEffect(() => {
        (async () => {
            setLoading(true);

            try {   
                const tipo = "ID";
                const response = await api.get(`/LicencaAcesso/${tipo}/${id}`);    

                if   (response.data.dataValidade) {                    
                     var data = response.data.dataValidade.toString();
                     var ano = data.substr(0,4);
                     var mes = data.substr(5,2);
                     var dia = data.substr(8,2);                  
                     setDataValidade(dia + "/" + mes + "/" + ano);            
                }
                else setDataValidade("Cópia Vitalicia!");                        
                    
                if (response.data.dataRegistro !== null) {
                    const dataRegistroString = response.data.dataRegistro.substring(8,10) + "/" +
                                               response.data.dataRegistro.substring(5,7)  + "/" +   
                                               response.data.dataRegistro.substring(0,4);
                    response.data.dataRegistro = dataRegistroString;
                }
                else response.data.dataRegistro = '* * * * * *';

                if  (response.data.pedidoId > 0) {
                    const resp2 = await api.get(`/BmaAcesso/${response.data.pedidoId}`); 
                    const data = resp2.data.dataPedido.substring(8,10) + "/" +
                                 resp2.data.dataPedido.substring(5,7)  + "/" +   
                                 resp2.data.dataPedido.substring(0,4);
                    setDataPedido(data);

                    if (resp2.data.dataLiberacao !== null) {
                        const data2 = resp2.data.dataLiberacao.substring(8,10) + "/" +
                                      resp2.data.dataLiberacao.substring(5,7)  + "/" +   
                                      resp2.data.dataLiberacao.substring(0,4);
                        setDataLiberacao(data2);
                    }
                    else setDataLiberacao('* * * * * *');

                }
                else {
        //            response.data.valorContrato = 0;
                    setDataPedido('* * * * * *');
                    setDataLiberacao('* * * * * *');
                }

                const retorno = await api.get(`/Contrato/retornar-situacao-contrato?numeroSerie=${response.data.numeroSerie}`);
                setUltimoValorContrato(retorno.data.valor);

                if (retorno.data.dataCancelamento !== null) {
                   const dataCancelamento = retorno.data.dataCancelamento.substring(8,10) + "/" +
                                            retorno.data.dataCancelamento.substring(5,7)  + "/" +   
                                            retorno.data.dataCancelamento.substring(0,4);
                     setDataCancelamentoContrato(dataCancelamento);
                }
                else setDataCancelamentoContrato('* * * * * *');

                setRegistro(response.data);

            } catch (error) {
                alert(error);
            } finally {
                setLoading(false);
            }   
        }
        )();

    }, [id]);                

    if  (loading) return <Loading />

    return (
        <section className={styles.form}>

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
               <Alert.Heading style={{textAlign: 'center'}}>Licença BmaAcesso</Alert.Heading>        
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}>    
                <Col md='8'>
                     <FloatingLabel controlid="floatingInputGrid4" label="Distribuidor">
                        <Form.Control value={registro.distribuidor?.razaoSocial} disabled />
                      </FloatingLabel>      
                </Col>                
    
                <Col md='4'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Número de Série">                               
                            <Form.Control value={registro.numeroSerie} disabled />                       
                    </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>    
                <Col md='8'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Cliente">
                        <Form.Control value={registro.razaoSocial} disabled />             
                    </FloatingLabel>                  
                </Col>

                <Col md='4'>
                     <FloatingLabel controlid="floatingInputGrid4" label="CNPJ / CPF">
                        <Form.Control value={registro.numeroInscricao} disabled />
                      </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid7" label="Vertical">
                        <Form.Select value={registro.tipoVertical} disabled>
                            <option value="" disabled></option>
                            <option value="1">Empresa</option>
                            <option value="2">Refeitório</option>
                            <option value="3">Academia</option>
                            <option value="4">Condomínio</option>
                            <option value="5">Hospital</option>
                            <option value="6">Escola</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>                

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Banco De Dados">
                        <Form.Select value={registro.tipoBancoDados} disabled>
                            <option value="1">MySql</option>
                            <option value="2">SqlServer</option>
                            <option value="3">Oracle</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>
                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Equip.Adicional">
                        <Form.Control value={registro.equipamentoAdicional} disabled />
                    </FloatingLabel>                    
                </Col>
                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Server Adicional">
                        <Form.Control value={registro.serverAdicional} disabled />
                    </FloatingLabel>                    
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid6" label="Versão">
                            <Form.Control value={registro.versao} disabled />                        
                    </FloatingLabel>      
                </Col>                

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Chave Registro">
                        <Form.Control value={registro.chaveRegistro} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid27" label="Valor Pedido (R$)">
                        <Form.Control value={registro.valorPedido} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid28" label="Valor Contrato (R$)">
                        <Form.Control value={registro.valorContrato} disabled />
                    </FloatingLabel>      
                </Col>

            </Row>                    

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid26" label="Data Pedido">
                            <Form.Control value={dataPedido} disabled />                        
                    </FloatingLabel>      
                </Col>                

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid43" label="Data Liberação">
                        <Form.Control value={dataLiberacao} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Data Validade">
                        <Form.Control value={dataValidade} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Data Registro">
                        <Form.Control value={registro.dataRegistro} disabled />
                    </FloatingLabel>      
                </Col>

            </Row>                    

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='6'>
                    <FloatingLabel controlid="floatingInputGrid51" label="Contrato Cancelado em">
                        <Form.Control value={dataCancelamentoContrato} style={{color: 'tomato'}}  disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='6'>
                    <FloatingLabel controlid="floatingInputGrid52" label="Ultimo Valor Contrato">
                        <Form.Control value={ultimoValorContrato} disabled />
                    </FloatingLabel>      
                </Col>

            </Row>                    

            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Integrações & Módulos Adicionais</Alert.Heading>        
            <br /> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integraWpe"
                        label="BmaPonto"                      
                        readOnly
                        checked={registro.wpe === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integracaoFuncionarios"
                        label="Funcionários"                      
                        readOnly
                        checked={registro.integracaoFuncionarios === 1}                       
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integracaoPacientes"
                        label="Pacientes"                      
                        readOnly
                        checked={registro.integracaoPacientes === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integracaoAlunos"
                        label="Alunos"                      
                        readOnly
                        checked={registro.integracaoAlunos === 1}
                    />
                </Col>

            </Row>                    

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="contrato"
                        label="Contrato"                      
                        readOnly
                        checked={registro.temContrato === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="locacao"
                        label="Locação"                      
                        readOnly
                        checked={registro.locacao === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="agendaWeb"
                        label="Agenda Web"                      
                        readOnly
                        checked={registro.agendaWeb === 1}                        
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="lgpd"
                        label="Módulo LGPD"                      
                        readOnly
                        checked={registro.moduloLGPD === 1}
                    />
                </Col>

            </Row>                    

            </Alert>

            <div className={styles.areaBtn}>    
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )
}

export default ViewPersonalizacaoAcesso;
