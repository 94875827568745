import React from "react";
import { Alert } from "react-bootstrap";

const Mensagem = ( {mensagem, setMensagem, variant, time=5000, width='40rem'} ) => {

    setTimeout(() => {
        setMensagem('');
      }, time);

    if (mensagem) {
        return (
            <Alert variant={variant} style={{position: 'fixed', zIndex: 2, bottom: '1.5rem', left: '0.5rem', width: width}} onClose={() => setMensagem('')} dismissible>
                <Alert.Heading style={{textAlign: 'center', fontSize: '1.25rem'}}>{mensagem}</Alert.Heading>   
            </Alert>
        )
    }
}

export default Mensagem;