import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import { Button, Form, Row, Col, FloatingLabel, Alert } from "react-bootstrap";
import styles from "./EditarServico.module.css";

const EditarServico = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    var RegexValorDecimal = /[0-9]{1,3}[, ]*[0-9]{0,2}/;

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  

    const { mensagemGlobal, setMensagemGlobal, setTipoMensagemGlobal, tipoMensagemGlobal } = React.useContext(MyContext);

    const [loading, setLoading] = React.useState(false);

    const [distribuidorId, setDistribuidorId] = React.useState('');
    const [clienteId, setClienteId] = React.useState('');
    const [usuarioId, setUsuarioId] = React.useState('');
    const [razaoSocialDistribuidor, setRazaoSocialDistribuidor] = React.useState('');
    const [razaoSocialCliente, setRazaoSocialCliente] = React.useState('');
    const [sistema, setSistema] = React.useState('');
    const [valorServico, setValorServico] = React.useState(0);
    const [valorParcela, setValorParcela] = React.useState(0);
    const [quantidadeParcelas, setQuantidadeParcelas] = React.useState('');
    const [descricaoServico, setDescricaoServico] = React.useState('');
    const [dataInclusao, setDataInclusao] = React.useState('');
    const [dataEditada, setDataEditada] = React.useState('');
    const [ressarcimento, setRessarcimento] = React.useState(false);
    const [integrarMesAnterior, setIntegrarMesAnterior] = React.useState('');
    const [statusMesAnterior, setStatusMesAnterior] = React.useState(false);

    React.useEffect(() => {
        const data = new Date();
        const dia = data.getDate();     
        if  (dia > 25)
            setStatusMesAnterior(false)
        else
            setStatusMesAnterior(true);    
    
        async  function pesquisar() { 
               setLoading(true);                  
               const response = await api.get(`/Servico/${id}`);    

               // console.log(response.data);            
               setDistribuidorId(response.data.distribuidorId);
               setClienteId(response.data.clienteId);
               setUsuarioId(response.data.usuarioId); 
               setRazaoSocialCliente(response.data.cliente.razaoSocial);
               setRazaoSocialDistribuidor(response.data.distribuidor.razaoSocial);
               setSistema(response.data.tipoSistema);

               setDescricaoServico(response.data.descricaoServico);
               setValorServico(response.data.valorServico);
               setQuantidadeParcelas(response.data.quantidadeParcelas);
               setValorParcela((Number(valorServico) / Number(quantidadeParcelas)).toFixed(2));               
               setDataInclusao(response.data.dataInclusao);               

               if  (response.data.ressarcimento === 1) {
                    setRessarcimento(true)
               }
               else setRessarcimento(false);     

               if  (response.data.integrarMesAnterior === 1) {
                    setIntegrarMesAnterior(true)
               }
               else setIntegrarMesAnterior(false);     

               const dataString = response.data.dataInclusao.substring(8,10) + "/" +
                                  response.data.dataInclusao.substring(5,7)  + "/" +   
                                  response.data.dataInclusao.substring(0,4);
               setDataEditada(dataString);                                   
               
               setLoading(false);       
        }

        pesquisar();            

    }, []);    

    React.useEffect(() => {
        if  (quantidadeParcelas)
        {
            setValorParcela((Number(valorServico) / Number(quantidadeParcelas)).toFixed(2));
        }
    }, [quantidadeParcelas])

    async function handleSubmit(ev) {
        ev.preventDefault();
        setMensagemGlobal(null);

        var valido = RegexValorDecimal.test(Number(valorServico));
        if (!valido) {
            setMensagemGlobal("Valor inválido. Utilize somente numeros e ponto decimal para centavos !");
            setTipoMensagemGlobal("danger");
            return;
        }

        if  (quantidadeParcelas.length === 0) {
            setMensagemGlobal("Faltou Definir a quantidade de parcelas...");
            setTipoMensagemGlobal("danger");
            return;
        }

        const dataAtual = new Date();       
        let regServico = {
            Id: Number(id),
            DistribuidorId: Number(distribuidorId),                        
            ClienteId: Number(clienteId),
            UsuarioId: Number(usuarioId),
            TipoSistema: Number(sistema),
            DescricaoServico: descricaoServico,
            QuantidadeParcelas: Number(quantidadeParcelas),
            StatusServico: 0,
            ValorServico: Number(valorServico),
            DataInclusao: dataInclusao,
            DataLiberacao: dataAtual,
            Ressarcimento: ressarcimento ? 1 : 0,
            IntegrarMesAnterior: integrarMesAnterior ? 1: 0,

        }                
               
        const ret = await api.put("Servico/", 
                    JSON.stringify(regServico), 
              {
                  headers: { 'Content-Type': 'application/json'}
              });

        setMensagemGlobal(ret.data);
        setTipoMensagemGlobal("success");               
        navigate("/servicos");

    }


    if  (loading) return <Loading />

    return (
        <section className={styles.form}>
            {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center'}}>Editar Serviço</Alert.Heading>        
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}> 
                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Tipo de Sistema">
                        <Form.Select value={sistema} onChange={({target}) => setSistema(target.value)}>
                            <option value="" disabled></option>
                            <option value="1">BmaPonto</option>
                            <option value="2">BmaAcesso</option>
                            <option value="3">BmaFolha</option>
                            <option value="4">SisDm</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='9'>
                    <FloatingLabel controlid="floatingInputGrid" label="Distribuidor">
                        <Form.Control placeholder="Release" value={razaoSocialDistribuidor} disabled
                                      onChange={({ target }) => setRazaoSocialDistribuidor(target.value)}/>             
                    </FloatingLabel>    
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>    
                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Data Inclusão">
                        <Form.Control placeholder="Release" value={dataEditada} disabled
                                      onChange={({ target }) => setDataEditada(target.value)}/>             
                    </FloatingLabel>                  
                </Col>

                <Col md='9'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Cliente">
                        <Form.Control placeholder="Release" value={razaoSocialCliente} disabled
                                      onChange={({ target }) => setRazaoSocialCliente(target.value)}/>             
                    </FloatingLabel>                  
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Valor Serviço">
                        <Form.Control placeholder="Val.Servico" value={valorServico} 
                                    onChange={({ target }) => setValorServico(target.value)}/>
                    </FloatingLabel>      
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Quantidade de Parcelas">
                        <Form.Select value={quantidadeParcelas} onChange={({target}) => setQuantidadeParcelas(target.value)}>
                            <option value="" disabled></option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Valor Parcela">
                        <Form.Control placeholder="Val.Servico" value={valorParcela} disabled
                                    onChange={({ target }) => setValorParcela(target.value)}/>
                    </FloatingLabel>      
                </Col>

            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Descrição do Serviço">
                        <Form.Control placeholder="Descrição Serviço" value={descricaoServico} 
                                    onChange={({ target }) => setDescricaoServico(target.value)}/>
                    </FloatingLabel>      
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                    <Form.Check 
                        type="switch"
                        id="status-servico"
                        label="Assinale se o Serviço for de Ressarcimento a Revenda"                      
                        onChange={() => setRessarcimento(!ressarcimento)}
                        checked={ressarcimento}
                    />
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                    <Form.Check 
                        type="switch"
                        id="integrar-servico-mes-ant"
                        label="Assinale para integrar o Serviço no faturamento anterior (somente para OS´s com data entre 26 e 31)"                      
                        onChange={() => setIntegrarMesAnterior(!integrarMesAnterior)}
                        checked={integrarMesAnterior}
                        disabled={statusMesAnterior}
                    />
                </Col>
            </Row>

            </Alert>

            <div className={styles.areaBtn}>                
               <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                       disabled={nivelUsuario !== "1"}>Atualizar
               </Button>
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )

}

export default EditarServico;