import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom'

import api from '../Api/apiAxios';
import { MyContext } from '../Context/MyContext';

export const ProtectedRoute = ({ changes = false, children }) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (changes) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    const handlePageHide = (event) => {
      event.preventDefault();
      logoutApp();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('pagehide', handlePageHide);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('pagehide', handlePageHide);
    };
  });

  const { logoutApp } = React.useContext(MyContext);

  const token = window.localStorage.getItem('token');
      if (token === null) {
          logoutApp();
          return;
      }

  (async () => {
    try {   
      const response = await api.get(`/Token/validar-token?tokenAtual=${token}`);
      if  (response.data === false) {
          logoutApp(); 
      }  
    } catch (e) {
      console.log(e);
    } 
  })();

  return children ? children : <Outlet />;
}