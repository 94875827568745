import React from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { FolderPlus, Search } from "react-bootstrap-icons";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";

export const HeaderPedidos = ( {
    titulo, 
    textoBotao, 
    pesquisa, 
    setPesquisa, 
    setTipoPesquisa, 
    isFolha = false,
    isPonto = false,
    pesquisarClick
} ) => {

    const navigate = useNavigate();

    const [pesquisarPor, setPesquisarPor] = React.useState('0');

    const adicionarClick = (isFolha, isPonto) => { 
        let url = ''
        if (isFolha) {
            url = "/novopedidobmafolha"
        } else if ( isPonto) {
            url = "/novopedidobmaponto"
        } else { 
            url = "/novopedidobmaacesso"
        }
        navigate(url);
    }

    function atualizarTipoPesquisa( {target} ) {
        setPesquisarPor(target.value);
        setTipoPesquisa(target.value);
    }

    const handleAddClickButton = () => { adicionarClick(isFolha, isPonto) };

    return (
        <Alert style={{background: 'ghostwhite', color: 'dimGray'}}>
            <Row>
              <Col md='3'>
                 <Alert.Heading style={{textAlign: 'left', fontSize: '1.5rem'}}>{titulo}</Alert.Heading>
              </Col>    

              <Col md='2'>
                <Form.Select  onChange={({target}) => atualizarTipoPesquisa({target})} value={pesquisarPor} className=" form-select-control" >
                        <option disabled value="0">Opções</option>
                        <option value="*">Todos</option>
                        <option value="ns">Número Série</option>
                        <option value="distribuidor">Distribuidor</option>
                        <option value="cliente">Cliente</option>
                        <option value="liberados">Liberados</option>
                        <option value="pendentes">Pendentes</option>
                        <option value="cancelados">Cancelados</option>
                </Form.Select>      
              </Col>

              <Col md='3'>
                <InputGroup className="mb-3">
                    <Form.Control placeholder="Pesquisa Parcial:" value={pesquisa}
                                  style={{fontSize: '0.8rem'}}
                                  onChange={({ target }) => setPesquisa(target.value)}/>

                        <Button onClick={pesquisarClick} variant="outline-primary">
                            <Search size={15} />
                        </Button>
                </InputGroup>
              </Col>    

              <Col md='1'>
              </Col>

              {textoBotao !== "hide" ?
               (
                <Col md='3'>
                    <Button onClick={handleAddClickButton} 
                            variant="outline-primary"
                            style={{borderRadius: '8px'}}
                    >
                    <FolderPlus size={22}/>{textoBotao}</Button>
                </Col>
              )
              :
              (
                <></>
              )
             }            
             </Row>             
        </Alert>
    )
}