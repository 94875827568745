export default function StatusPedido({status}) {
    let color = '';
    let statusTitle = '';
    
    if (status === 0) {
        color = 'lavender';
        statusTitle = 'Pendente';
    } else if (status === 1) {
        color = 'palegreen';
        statusTitle = 'Liberado';
    } else if (status === 2) {
        color = 'tomato';
        statusTitle = 'Cancelado';
    }
    return (
        <td style={{fontSize: "small", backgroundColor: color}}>{statusTitle}</td>                        
    )
}