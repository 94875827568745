import React from "react";
import api from "../../Api/apiAxios";
import { Button, Table } from "react-bootstrap";
import { CardText, FolderSymlink, Pencil, StopCircle } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import HeaderServicos from "../../Components/HeaderServicos";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'
import styles from "./ServicosIndex.module.css";
import ModalPedidos from "../../Components/ModalPedido";
import { TableItemName } from "../../Components/TableItemName";
import { Paginacao } from "../../Components/Paginacao";

const ServicosIndex = () => {

    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');   

    const { mensagemGlobal, setMensagemGlobal, tipoMensagemGlobal, setTipoMensagemGlobal,
            setListaGlobalClientes, setDistribuidorIdGlobal, itensPorPagina } = React.useContext(MyContext);

    const [showModal, setShowModal] = React.useState(false);
    const [botaoLiberar, setBotaoLiberar] = React.useState(false);
    const [servicoSalvo, setServicoSalvo] = React.useState('');
    const [bodyModal1, setBodyModal1] = React.useState('');
    const [bodyModal2, setBodyModal2] = React.useState('');
    const [bodyModal3, setBodyModal3] = React.useState('');

    const [pesquisa, setPesquisa] = React.useState('');
    const [servicos, setServicos] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [tipoPesquisa, setTipoPesquisa] = React.useState('*');
    const [botaoAdicionar, setBotaoAdicionar] = React.useState('');
    const [registroInicial, setRegistroInicial] = React.useState(1);
    const [registroFinal, setRegistroFinal] = React.useState(20);
    const [quantidadeRegistros, setQuantidadeRegistros] = React.useState(0);
    const [totalServicos, setTotalServicos] = React.useState(0);
    const [totalServicosEmAberto, setTotalServicosEmAberto] = React.useState(0);  

    React.useEffect(() => {
        setListaGlobalClientes(null);   
        setDistribuidorIdGlobal('');

        if  (nivelUsuario === "2" || nivelUsuario === "4")    
        {
            setBotaoAdicionar("hide");
        }
        else {
            setBotaoAdicionar(' Novo Serviço');
        }
    
        async  function pesquisar() { 
            setLoading(true);                  
            const response = await api.get('/Servico');
            setServicos(response.data);  
            setQuantidadeRegistros(response.data.length)        
            totalizar(response.data);

            setLoading(false);       
        }

        pesquisar();            
    }, []);            

    function totalizar(data) {
        var sum = 0;
        var sum2 = 0;
        for(var i =0;i<data.length;i++){ 
            if  (data[i].statusServico === 1) {
                sum+=data[i].valorServico; 
            }
            if  (data[i].statusServico === 0) {
                sum2+=data[i].valorServico; 
            }
        }         
        setTotalServicos(sum.toFixed(2));
        setTotalServicosEmAberto(sum2.toFixed(2));
    }

    function pesquisarClick() { 
        (async () => {
            try {
                if  (pesquisa.length === 0) {           
                    if  (tipoPesquisa === "digitados" || tipoPesquisa === "cancelados" || tipoPesquisa === "liberados") {
                        const pesq = 'status';
                        const response = await 
                              api.get(`/Servico/parametros-consulta?id=${idDistribuidor}&nivel=${nivelUsuario}&tipo=${tipoPesquisa}&args=${pesq}`);   
                        setServicos(response.data);            
                        totalizar(response.data);
                    
                    }
                    else {
                        const pesq = '*';
                        const response = await 
                              api.get(`/Servico/parametros-consulta?id=${idDistribuidor}&nivel=${nivelUsuario}&tipo=${tipoPesquisa}&args=${pesq}`);   
                        setServicos(response.data);            
                        totalizar(response.data);
                    }
                }
                else {
                    const response = await 
                          api.get(`/Servico/parametros-consulta?id=${idDistribuidor}&nivel=${nivelUsuario}&tipo=${tipoPesquisa}&args=${pesquisa}`);   
                    setServicos(response.data);            
                    totalizar(response.data);
                }
                setPesquisa('');
                setLoading(false);              

            } catch (error) {
                alert(error);
            }
        }
        )();
    }

    function adicionarClick(event) {
        event.preventDefault();
        navigate("/novoservico");
    }

    function editarClick(servico) {              
        navigate(`/editarservico/${servico.id}`);
    }

    function viewContrato(servico) {
        navigate(`/viewservico/${servico.id}`);
    }

    function cancelarContrato(servico) {
        navigate(`/cancelarservico/${servico.id}`);
    }

    function modalLiberarServico(servico) {

        setBodyModal1(`Cliente: ${servico.cliente.razaoSocial}`); 
        setBodyModal2(`Valor: ${servico.valorServico.toFixed(2)} - Parcelas: ${servico.quantidadeParcelas}`);
        setBodyModal3(`Descrição: ${servico.descricaoServico}`);

        setServicoSalvo(servico);
        setShowModal(true);
        setBotaoLiberar(false);

    }    

    async function atualizarServico(regServico) {
        if  (regServico) {
            regServico.statusServico = 1;
            const retorno = await api.put(`/Servico/atualizar-status-servico?id=${regServico.id}&status=${regServico.statusServico}`);   
            setMensagemGlobal(retorno.data);
            setTipoMensagemGlobal("success");               
            setShowModal(false);           
            setBotaoLiberar(false);
            setServicoSalvo(null);

            pesquisarClick();
        }    
    }



    if (loading) return <Loading />

    if  (showModal) return <ModalPedidos showModal={showModal} setShowModal={setShowModal}
                           botaoLiberar={botaoLiberar} setBotaoLiberar={setBotaoLiberar}
                           titleModal="Confirmação de Liberação Serviço !"
                           bodyModal1={bodyModal1} bodyModal2={bodyModal2} bodyModal3={bodyModal3} />

    if  (botaoLiberar) {
        atualizarServico(servicoSalvo);
    }


    return (
       <section>
         {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

             <HeaderServicos titulo={"Serviços"} textoBotao={botaoAdicionar} 
                pesquisa={pesquisa} setPesquisa={setPesquisa}
                tipoPesquisa={tipoPesquisa} setTipoPesquisa={setTipoPesquisa}
                pesquisarClick={pesquisarClick} adicionarClick={adicionarClick}
            />

            <Table striped hover responsive>
                <thead className={styles.thead}>
                    <tr className={styles.trHead}>
                            <th className={styles.th}>Distribuidor</th>
                            <th className={styles.th}>Cliente</th>                          
                            <th className={styles.th}>Sistema</th>                        
                            <th className={styles.th}>Data Inclusão</th>
                            <th className={styles.th}>Valor (R$)</th>
                            <th className={styles.th}>Parc.</th>
                            <th className={styles.th}>Ressarc.</th>
                            <th className={styles.th}>Status</th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                {servicos.map(servico => 
                    <tr key={servico.id} className={styles.trBody}>                                     
                        <TableItemName title={servico.distribuidor.nomeFantasia} />
                        <TableItemName title={servico.cliente.razaoSocial} />

                        {servico.tipoSistema === 1 && <td className={styles.td}>Ponto</td>}
                        {servico.tipoSistema === 2 && <td className={styles.td}>Acesso</td>}
                        {servico.tipoSistema === 3 && <td className={styles.td}>Folha</td>}
                        {servico.tipoSistema === 4 && <td className={styles.td}>Sisdm</td>}

                        <td className={styles.td}> 
                            {servico.dataInclusao.substring(8,10)}/   
                            {servico.dataInclusao.substring(5,7)}/   
                            {servico.dataInclusao.substring(0,4)}  
                        </td>

                        {servico.statusServico < 2 && servico.ressarcimento === 0 &&
                           <td>{servico.valorServico.toFixed(2)}</td>  
                        }
                        {servico.statusServico < 2 && servico.ressarcimento === 1 &&
                           <td style={{color: 'red', fontWeight: 'bold'}}>{servico.valorServico.toFixed(2)}</td>  
                        }
                        {servico.statusServico === 2 && <td className={styles.td}> * * * * * *  </td>}

                        <td className={styles.td}>{servico.quantidadeParcelas}</td>
                        {servico.ressarcimento === 1 ?
                            <td className={styles.td}>Sim</td>
                          : <td className={styles.td}>Não</td>
                        }

                        {servico.statusServico === 0 &&
                            <td className={styles.td} style={{backgroundColor: "lightGray"}}>Digitado</td>
                        }
                        {servico.statusServico === 1 &&
                            <td className={styles.td} style={{backgroundColor: "palegreen"}}>Liberado</td>
                        }
                        {servico.statusServico === 2 &&
                            <td className={styles.td} style={{backgroundColor: "tomato"}}>Cancelado</td>
                        }

                        <td style={{display: 'flex'}}>   
                            <OverlayTrigger overlay={
                                <Tooltip id="btn-editar">Editar</Tooltip> }> 
                                <Button onClick={() => editarClick(servico)}
                                        disabled={nivelUsuario !== "1" || servico.statusServico > 0}                                      
                                        variant="outline-warning">
                                        <Pencil size={18}/>
                                </Button>   
                            </OverlayTrigger>                                                         
                            <span> </span>                     
                            <OverlayTrigger   overlay={
                            <Tooltip id="btn-view-pedido">Visualizar Serviço</Tooltip> }>                              
                                <Button onClick={() => viewContrato(servico)}                                       
                                        variant="outline-success">
                                        <CardText size={18}/>
                                </Button>                                       
                            </OverlayTrigger>                                          
                            <span> </span>                     
                            <OverlayTrigger   overlay={
                                <Tooltip id="btn-view-liberar-servico">Liberar Serviço</Tooltip> }>                              
                                    <Button onClick={() => modalLiberarServico(servico)} 
                                            disabled={nivelUsuario !== "1" || servico.statusServico > 0}                                      
                                            variant="outline-primary">
                                            <FolderSymlink size={18}/>
                                    </Button>                                       
                            </OverlayTrigger>              
                            <span> </span>                     
                            <OverlayTrigger   overlay={
                                <Tooltip id="btn-clientes">Cancelar Serviço</Tooltip> }>                              
                                    <Button onClick={() => cancelarContrato(servico)} 
                                            disabled={nivelUsuario !== "1" || servico.statusServico > 1}   
                                            variant="outline-danger">
                                            <StopCircle size={18}/>
                                    </Button>                                       
                            </OverlayTrigger>                     
                        </td>                        

                    </tr>
                )}
            </tbody>

             <tfoot>
                {/* <tr>
                    <td colSpan={1} className="text-right"> </td>
                    <td> <b> Total Serviços Em Aberto: </b> </td>
                    <td> <b>{totalServicosEmAberto}</b> </td>
                    <td> <b> Total Serviços Liberados: </b> </td>
                    <td> <b>{totalServicos}</b> </td>
                </tr> */}

                <Paginacao 
                    registroInicial={registroInicial}     
                    registroFinal={registroFinal}
                    urlPesquisa={`/Servico/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}`}
                    quantidadeRegistros={quantidadeRegistros}
                    itensPorPagina={itensPorPagina}   
                    setRegistrosData={setServicos}
                    setRegistroFinal={setRegistroFinal}
                    setRegistroInicial={setRegistroInicial}                            
                />
            </tfoot>                    
           
            </Table>

       </section>
    )

}

export default ServicosIndex;