import React from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { PersonAdd, Search } from "react-bootstrap-icons";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

export const HeaderCadastros = ( {titulo, textoBotao, pesquisa, setPesquisa, 
                                  pesquisarClick, adicionarClick} ) => {

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  

    return (
        <Alert style={{background: 'ghostwhite', color: 'dimGray'}}>
            <Row>
              <Col md='3'>
                 <Alert.Heading style={{textAlign: 'left', fontSize: '2rem'}}>{titulo}</Alert.Heading>
              </Col>    
              <Col md='4'>
                <InputGroup className="mb-3">
                    <Form.Control placeholder="Pesquisa Parcial" value={pesquisa}
                                  style={{fontSize: '0.8rem'}}
                                  onChange={({ target }) => setPesquisa(target.value)}/>

                        <Button onClick={pesquisarClick} variant="outline-primary">
                            <Search size={15} />
                        </Button>
                </InputGroup>
              </Col>    
              <Col md='2'>
              </Col>
              {textoBotao !== "hide" ?
               (
                <Col md='3'>
                    <Button onClick={adicionarClick} 
                            variant="outline-primary"
                            style={{borderRadius: '8px'}}
                            disabled={nivelUsuario === "2" || nivelUsuario === "4"}
                    >
                    <PersonAdd size={22}/>{textoBotao}</Button>
                </Col>
              )
              :
              (
                <></>
              )
             }            
             </Row>             
        </Alert>
    )
}

export default HeaderCadastros;