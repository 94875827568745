import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../Components/Loading";
import Mensagem from "../../Components/Mensagem";
import { Alert, Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { MyContext } from "../../Context/MyContext";
import styles from "./CancelarPedidoBmaFolha.module.css";

const CancelarPedidoBmaFolha = () => {
    const { id } = useParams();

    const navigate = useNavigate();  

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');    
    const idDistribuidorLogado = window.localStorage.getItem('distribuidorId');   

    const {mensagemGlobal, setMensagemGlobal, tipoMensagemGlobal, setTipoMensagemGlobal} = React.useContext(MyContext);

    const [mensagem, setMensagem] = React.useState('');
    const [tipoMensagem, setTipoMensagem] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [idDistribuidor, setIdDistribuidor] = React.useState('');
    const [razaoSocial, setRazaoSocial] = React.useState('');
    const [inscricao, setInscricao] = React.useState('');
    const [faixaFuncionarios, setFaixaFuncionarios] = React.useState(25);
    const [tipoPedido, setTipoPedido] = React.useState('');
    const [tipoPlano, setTipoPlano] = React.useState('');
    const [tipoBanco, setTipoBanco] = React.useState('');
    const [contrato, setContrato] = React.useState('');
    const [versao, setVersao] = React.useState('');
    const [release, setRelease] = React.useState('');
    const [valorPedido, setValorPedido] = React.useState('');
    const [valorMensal, setValorMensal] = React.useState('');
    const [dataPedido, setDataPedido] = React.useState('');
    const [MotivoCancelamento, setMotivoCancelamento] = React.useState('');
    const [statusPedido, setStatusPedido] = React.useState('');

    React.useEffect(() => {
        (async () => {
            try {   
                setLoading(true);
                const response = await api.get(`/BmaFolha/${id}`);    

                setStatusPedido(response.data.statusPedido);
                setIdDistribuidor(response.data.distribuidorId);

                if (response.data.tipoPedido === 0)  {
                     setTipoPedido("Cópia Nova");
                }
                else setTipoPedido("Atualização");

                switch (response.data.tipoInstalacao) {
                    case 0 : setTipoPlano("Licenciado");
                        break;
                    case 1 : setTipoPlano("Saas");
                        break;
                }

                switch (response.data.tipoBancoDados) {
                    case 1 : setTipoBanco("MySql");
                        break;
                    case 2 : setTipoBanco("Sql-Server");
                        break;
                    case 3 : setTipoBanco("Oracle");
                        break;
                }

                setFaixaFuncionarios(response.data.faixaFuncionarios);               
                setValorPedido(response.data.valorBruto);
                setValorMensal(response.data.valorMensal);
                setVersao(response.data.versaoSistema);
                setRelease(response.data.versaoRelease);

                const dataString = response.data.dataPedido.substring(8,10) + "/" +
                response.data.dataPedido.substring(5,7)  + "/" +   
                response.data.dataPedido.substring(0,4);
                setDataPedido(dataString);   

                if  (response.data.contrato === 1)
                    setContrato("Sim")
                else
                    setContrato("Não");    
                    
                buscarCliente(response.data.clienteId);
                setLoading(false);

                const distribuidorLogado = Number(idDistribuidorLogado);
                const distribuidorPedido = Number(response.data.distribuidorId);

                if  ((distribuidorLogado !== distribuidorPedido) && (nivelUsuario === "3" || nivelUsuario === "4")) {
                    setMensagemGlobal("Ops, você não tem permissão para acessar este Pedido !");
                    setTipoMensagemGlobal("danger");
                    navigate("/pedidosfolha");
                }

            } catch (error) {
                alert(error);
            }            
        }
        )();

    }, [id]);                

    function buscarCliente(id) {
        (async () => {
            try {   
                const response = await api.get(`/Cliente/${id}`);    
                setRazaoSocial(response.data.razaoSocial);
                setInscricao(response.data.numeroInscricao);
                
            } catch (error) {
            }            
        }
        )();
    }

    async function handleSubmit(ev) {
        ev.preventDefault();

        if  (MotivoCancelamento.length < 10) {
            setMensagem("Motivo Cancelamento precisa ter no minimo 10 caracteres !");
            setTipoMensagem("danger");
            return;
        }

        const statusPedido = 2;
        const retorno = await api.put(`/BmaFolha/atualizar-status-pedido?id=${id}&status=${statusPedido}&motivo=${MotivoCancelamento}`);   
        setMensagemGlobal(retorno.data);
        setTipoMensagemGlobal("success");               
        navigate("/pedidosfolha");
    }


    if  (loading) return <Loading />

    return (
        <section className={styles.form}>

            {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center', color: 'tomato'}}>Cancelar Pedido BmaFolha</Alert.Heading>        
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}>
    
                <Col md='8'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Cliente">
                        <Form.Control value={razaoSocial} disabled />
                    </FloatingLabel>                    
                </Col>

                <Col md='4'>
                     <FloatingLabel controlid="floatingInputGrid6" label="CNPJ / CPF">
                         <Form.Control value={inscricao} disabled />
                    </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Plano">
                        <Form.Control value={tipoPlano} disabled />
                    </FloatingLabel>                    
                </Col>

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Faixa Funcionarios">
                        <Form.Control value={faixaFuncionarios} disabled />
                    </FloatingLabel>                    
                </Col>

                <Col md='1'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Contrato">
                        <Form.Control value={contrato} disabled />
                    </FloatingLabel>                    
                </Col>

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Valor Pedido">
                        <Form.Control value={valorPedido} disabled />
                    </FloatingLabel>                    
                </Col>

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Valor Mensal">
                        <Form.Control value={valorMensal} disabled />
                    </FloatingLabel>                    
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Data Pedido">
                        <Form.Control value={dataPedido} disabled />
                    </FloatingLabel>                    
                </Col>

            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
    
                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Tipo de Pedido">
                        <Form.Control value={tipoPedido} disabled />
                    </FloatingLabel>                    
                </Col>

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid6" label="Versão">
                         <Form.Control value={versao} disabled />
                    </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid7" label="Release">
                         <Form.Control value={release} disabled />
                      </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid7" label="Banco de Dados">
                         <Form.Control value={tipoBanco} disabled />
                      </FloatingLabel>      
                </Col>               
            </Row>

            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Motivo Cancelamento</Alert.Heading>        
            <br /> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                     <FloatingLabel controlid="floatingInputGrid4" label="Mínimo (10 caracteres)">
                        <Form.Control value={MotivoCancelamento} minLength={10} maxLength={250}
                                    onChange={({ target }) => setMotivoCancelamento(target.value)}/>
                      </FloatingLabel>      
                </Col>                
            </Row>

            </Alert>

            <div className={styles.areaBtn}>    
               <Button onClick={handleSubmit} variant="outline-danger" className={styles.btn} 
                       disabled={nivelUsuario !== "1" || statusPedido > 1}>Confirmar Cancelamento</Button>
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
)}

export default CancelarPedidoBmaFolha;