import React from "react";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Button } from "react-bootstrap";
import { CardText, Pencil, FolderSymlink, StopCircle } from "react-bootstrap-icons";

import styles from "./Buttons.module.css";
import { MyContext } from "../Context/MyContext";

export default function PedidosButtons({
    nivelUsuario, 
    pedido, 
    setBodyModal1, 
    setBodyModal2, 
    setBodyModal3,
    setShowModal,
    setBotaoLiberar,
    setPedidoSalvo,
    editarUrl,
    viewUrl,
    cancelarUrl,
}) {
    const navigate = useNavigate();

    const { setMensagemGlobal, setTipoMensagemGlobal } = React.useContext(MyContext);

    function editarClick() {
        navigate(`${editarUrl}`);
    }

    function viewPedido() {
        navigate(`${viewUrl}`);
    }

    function modalLiberarPedido(pedido) {
        setMensagemGlobal(null);
        if  (pedido.numeroSerie === 0 && pedido.tipoPedido === 1) {
            setMensagemGlobal("Atenção, pedido sem NS, informe antes de prosseguir...");
            setTipoMensagemGlobal("warning"); 
            return;
        }              

        setBodyModal1(`Cliente: ${pedido.cliente.razaoSocial}`); 
        if  (pedido.contrato === 1) {
             setBodyModal2(`Inscricao: ${pedido.cliente.numeroInscricao}  - Contrato: Sim`);
        }
        else setBodyModal2(`Inscricao: ${pedido.cliente.numeroInscricao}  - Contrato: Não`);

        if  (pedido.tipoInstalacao === 0) {
             setBodyModal3("Tipo Cópia: Licenciado");
        }
        if  (pedido.tipoInstalacao === 1) {
            setBodyModal3("Tipo Cópia: SaaS Rep-C");
        }
        if  (pedido.tipoInstalacao === 2) {
            setBodyModal3("Tipo Cópia: SaaS Rep-P");
        }              

        setPedidoSalvo(pedido);
        setShowModal(true);
        setBotaoLiberar(false);
    }

    function modalCancelarPedido() {
        navigate(`${cancelarUrl}`);
    }

    return (
        <td>     
            <div className={styles.buttons_container}>                               
                <OverlayTrigger overlay={<Tooltip id="btn-editar">Editar</Tooltip> }> 
                    <Button onClick={() => editarClick(pedido)}
                            variant="outline-warning" 
                            disabled={nivelUsuario === "2" || nivelUsuario === "4" || pedido.statusPedido > 0}
                    >
                        <Pencil size={18} />
                    </Button>   
                </OverlayTrigger>                                                         
                
                <span />

                <OverlayTrigger overlay={<Tooltip id="btn-view-pedido">Visualizar Pedido</Tooltip> }>                              
                    <Button onClick={() => viewPedido(pedido)}                                       
                            variant="outline-success"
                    >
                        <CardText size={18}/>
                    </Button>                                       
                </OverlayTrigger>                                          

                <span />                   

                <OverlayTrigger overlay={<Tooltip id="btn-view-liberar-pedido">Liberar Pedido</Tooltip> }>                              
                    <Button onClick={() => modalLiberarPedido(pedido)} 
                            disabled={nivelUsuario !== "1" || pedido.statusPedido > 0}                                      
                            variant="outline-primary"
                    >
                        <FolderSymlink size={18}/>
                    </Button>                                       
                </OverlayTrigger>                                          

                <span />                   

                <OverlayTrigger overlay={<Tooltip id="btn-view-cancelar-pedido">Cancelar Pedido</Tooltip> }>                              
                    <Button onClick={() => modalCancelarPedido(pedido)} 
                            disabled={nivelUsuario !== "1" || pedido.statusPedido > 1}                                      
                            variant="outline-danger"
                    >
                        <StopCircle size={18}/>
                    </Button>                                       
                </OverlayTrigger>   
            </div>
        </td>
    )
}