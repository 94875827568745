import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate } from "react-router-dom";
import { VictoryChart, VictoryBar, VictoryTooltip } from "victory";
import { Card } from "react-bootstrap";
import { MyContext } from "../../Context/MyContext";
import styles from "./GraficosIniciais.module.css";

const GraficosIniciais = () => {
    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');   

    const [grafico, setGrafico] = React.useState([]);
    const [grafico2, setGrafico2] = React.useState([]);
    const [copiasAcesso, setCopiasAcesso] = React.useState(0);
    const [copiasPonto, setCopiasPonto] = React.useState(0);
    const [atualizacoesAcesso, setAtualizacoesAcesso] = React.useState(0);
    const [atualizacoesPonto, setAtualizacoesPonto] = React.useState(0);
    const [copiasFolha, setCopiasFolha] = React.useState(0);
    const [atualizacoesFolha, setAtualizacoesFolha] = React.useState(0);

    const data = new Date();
    const anoAtual = data.getFullYear();
    
    React.useEffect(() => {
        async function getFaturamento() {

            const qtdeMeses = 4;
            let tipo = 0;
            const response = await 
                  api.get(`/Totais/grafico-por-periodo?id=${idDistribuidor}&nivel=${nivelUsuario}&qtdeMeses=${qtdeMeses}&tipo=${tipo}`);   

            const listaGrafico = response.data.map((item) => {
                  return {
                    x: item.detalheTotal,
                    y: Number(item.valorDetalheTotal / 1000),
                  };
            });       
            setGrafico(listaGrafico);     
        }

        async function getFaturamento2() {

            const qtdeMeses = 4;
            let tipo = 1;
            const response = await 
                  api.get(`/Totais/grafico-por-periodo?id=${idDistribuidor}&nivel=${nivelUsuario}&qtdeMeses=${qtdeMeses}&tipo=${tipo}`);   

            const listaGrafico2 = response.data.map((item) => {
                  return {
                    x: item.detalheTotal,
                    y: Number(item.valorDetalheTotal / 1000),
                  };
            });       
            setGrafico2(listaGrafico2);                            
        }

        async function getLicencas(sistema, tipo) {
            const response = await 
                  api.get(`/Totais/licencas-por-ano?id=${idDistribuidor}&nivel=${nivelUsuario}&ano=${anoAtual}&sistema=${sistema}&tipo=${tipo}`);   

            if  (sistema === "BmaPonto" &&  tipo === 0)                  
                setCopiasPonto(response.data);                  
            if  (sistema === "BmaAcesso" && tipo === 0)                  
                setCopiasAcesso(response.data);    
            if  (sistema === "BmaFolha" && tipo === 0)                  
                setCopiasFolha(response.data);    
            if  (sistema === "BmaPonto" &&  tipo === 1)                  
                setAtualizacoesPonto(response.data);                  
            if  (sistema === "BmaAcesso" && tipo === 1)                  
                setAtualizacoesAcesso(response.data);    
            if  (sistema === "BmaFolha" && tipo === 1)                  
                setAtualizacoesFolha(response.data);    

        }

        getFaturamento();        
        getFaturamento2();
        getLicencas("BmaPonto", 0);
        getLicencas("BmaAcesso", 0);
        getLicencas("BmaFolha",0);
        getLicencas("BmaPonto", 1);
        getLicencas("BmaAcesso", 1);
        getLicencas("BmaFolha",1);
       
    }, []);       
   

    if  (grafico)
        return(
            <section className={styles.formulario}>
              <br />
              <div className={styles.cardcontainer}>
                <div className={styles.cards}>
                        <div className={styles.cardItem} key={1}>
                            <Card bg="primary" border="dark" text="white" className="text-center" key={1} >
                                <Card.Header as="h4">BmaPonto</Card.Header>
                                <Card.Body>
                                    <Card.Title className={styles.cardValor}>{copiasPonto}</Card.Title>
                                </Card.Body>                                      
                                <Card.Footer>Quantidade Licenças Vendidas em {anoAtual}</Card.Footer>
                            </Card>
                        </div>                         

                        <div className={styles.cardItem} key={2}>
                            <Card bg="primary" border="dark" text="white" className="text-center" key={2} >
                                <Card.Header as="h4">BmaAcesso</Card.Header>
                                <Card.Body>
                                    <Card.Title className={styles.cardValor}>{copiasAcesso}</Card.Title>
                                </Card.Body>                                      
                                <Card.Footer>Quantidade Licenças Vendidas em {anoAtual}</Card.Footer>
                            </Card>
                        </div>                         

                        <div className={styles.cardItem} key={3}>
                            <Card bg="primary" border="dark" text="white" className="text-center" key={2} >
                                <Card.Header as="h4">BmaFolha</Card.Header>
                                <Card.Body>
                                    <Card.Title className={styles.cardValor}>{copiasFolha}</Card.Title>
                                </Card.Body>                                      
                                <Card.Footer>Quantidade Licenças Vendidas em {anoAtual}</Card.Footer>
                            </Card>
                        </div>                         

                        <div className={styles.cardItem} key={4}>
                            <Card bg="success" border="dark" text="white" className="text-center" key={3} >
                                <Card.Header as="h4">BmaPonto</Card.Header>
                                <Card.Body>
                                    <Card.Title className={styles.cardValor}>{atualizacoesPonto}</Card.Title>
                                </Card.Body>                                      
                                <Card.Footer>Quantidade Licenças Atualizadas em {anoAtual}</Card.Footer>
                            </Card>
                        </div>                         

                        <div className={styles.cardItem} key={5}>
                            <Card bg="success" border="dark" text="white" className="text-center" key={4} >
                                <Card.Header as="h4">BmaAcesso</Card.Header>
                                <Card.Body>
                                    <Card.Title className={styles.cardValor}>{atualizacoesAcesso}</Card.Title>
                                </Card.Body>                                      
                                <Card.Footer>Quantidade Licenças Atualizadas em {anoAtual}</Card.Footer>
                            </Card>
                        </div>      

                        <div className={styles.cardItem} key={6}>
                            <Card bg="success" border="dark" text="white" className="text-center" key={6} >
                                <Card.Header as="h4">BmaFolha</Card.Header>
                                <Card.Body>
                                    <Card.Title className={styles.cardValor}>{atualizacoesFolha}</Card.Title>
                                </Card.Body>                                      
                                <Card.Footer>Quantidade Licenças Atualizadas em {anoAtual}</Card.Footer>
                            </Card>
                        </div>      

                        <br />
                        <br />
                </div>                
              </div>

              <div className={styles.container}> 
                <h2 className={styles.titulo}>Novas Licenças (ultimos meses)</h2>
                <div className={styles.grafico}>
                    <VictoryChart>
                        <VictoryBar                           
                            alignment="start" 
                            labelComponent={<VictoryTooltip/>}                        
                            data={grafico}
                            labels={({ datum }) => `${datum.y}k`}
                            style={{ data: { fill: '#00BFFF' }, labels: {fontSize: 14} }}
                        >
                        </VictoryBar>
                    </VictoryChart>
                </div>
                {(grafico2) && 
                   <>
                        <h2 className={styles.titulo}>Atualizações (ultimos meses)</h2>
                        <div className={styles.grafico}>
                            <VictoryChart>
                                <VictoryBar                                 
                                    alignment="start" 
                                    labelComponent={<VictoryTooltip/>}                        
                                    data={grafico2}
                                    labels={({ datum }) => `${datum.y}k`}
                                    style={{ data: { fill: '#00FA9A' } }}
                                >
                                </VictoryBar>
                            </VictoryChart>
                        </div>
                    </>                        
                }
              </div>             
              <br />             
            </section>


        )
    else return <p>Não Existem dados no periodo.</p>
}

export default GraficosIniciais;