import React from "react";
import api from "../../Api/apiAxios";
import { Form, Alert, Button, Col, Row, Card, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading";
import Mensagem from "../../Components/Mensagem";
import { MyContext } from "../../Context/MyContext";
import styles from "./RelatorioParcialFaturamento.module.css";

const RelatorioParcialFaturamento = () => {
    const navigate = useNavigate();

    const { setListaGlobalClientes, setListaGlobalContratos, setDistribuidorIdGlobal } = React.useContext(MyContext);

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');   

    const [loading, setLoading] = React.useState(false);
    const [mensagem, setMensagem] = React.useState(null);
    const [tipoMensagem, setTipoMensagem] = React.useState('');
    const [dataRelatorio, setDataRelatorio] = React.useState('');
    const [dataRelatorio2, setDataRelatorio2] = React.useState('');
    const [tipoView, setTipoView] = React.useState('');
    const [totalGeral, setTotalGeral] = React.useState(0);
    const [totais, setTotais] = React.useState([]);

    React.useEffect(() =>{
        setListaGlobalClientes(null);
        setListaGlobalContratos(null);
        setDistribuidorIdGlobal('');

        var data = new Date();
       
        const ano = data.getFullYear();
        const mes = data.getMonth();
        const dia = 26;
        var data1 = new Date(ano, mes, dia);

        const dias = 30;
        const milissegundos_por_dia = 1000 * 60 * 60 * 24;

        var dataInicial = new Date(data1.getTime() -  (dias * milissegundos_por_dia));

        var ano1 = dataInicial.getFullYear();
        var mes1 = String(dataInicial.getMonth() + 1).padStart(2,'0')
        var dia1 = dataInicial.getDate().toString().padStart(2,'0')
        setDataRelatorio(ano1 + "-" + mes1 + "-" + 26);

        const mesEditado = String(data.getMonth() + 1).padStart(2,'0');
        setDataRelatorio2(ano + "-" + mesEditado + "-" + 25);

    }, [])

    async function handleSubmit(ev) {
        ev.preventDefault();

        if  (dataRelatorio.length === 0) {
            setMensagem("Faltou Selecionar o Período");
            setTipoMensagem("danger");
            return;
        }

        if  (dataRelatorio2.length === 0) {
            setMensagem("Faltou Selecionar o Período");
            setTipoMensagem("danger");
            return;
        }

        if  (tipoView.length === 0) {
            setMensagem("Faltou Selecionar a opção para Visualização");
            setTipoMensagem("danger");
            return;
        }

        try {
            setLoading(true); 
            setMensagem('');               

            const response = 
                await api.get(`/Totais/${idDistribuidor}/${nivelUsuario}/${dataRelatorio}/${dataRelatorio2}`);   
           //     console.log(response.data);
                setTotais(response.data);

                var sum = response.data.reduce(function(accumulator,object){ 
                    return accumulator + object.valorDetalheTotal
                  },0);                 
                setTotalGeral(sum);               
        }
        catch (error) {
              alert("Não foi possível gerar o relatório - Erro " + error);
        } finally {
            setLoading(false);
        }
    }


    if  (loading) return <Loading />

    if  (tipoView === "1") {
        return (
            <section className={styles.form}>

                <Alert style={{background: 'ghostwhite', color: 'black'}}>
                    <Alert.Heading style={{textAlign: 'center', fontSize: 'x-large'}}>Faturamento Parcial do Mês</Alert.Heading>        

                <br />

                <Row className="g-2" style={{marginBottom: '1rem'}}>

                <Col md='1'>
                </Col>

                    <Col md='2'>
                        <Form.Label controlid="floatingInputGrid14" style={{fontWeight: 'normal', textAlign: 'left'}}>Período Inicial</Form.Label>                       
                            <Form.Control type="date" placeholder="Periodo" value={dataRelatorio} style={{alignSelf: 'left'}}         
                                        onChange={({ target }) => setDataRelatorio(target.value)}/>
                    </Col>            

                    <Col md='1'>
                    </Col>

                    <Col md='2'>
                        <Form.Label controlid="floatingInputGrid14" style={{fontWeight: 'normal', textAlign: 'left'}}>Período Final</Form.Label>                       
                            <Form.Control type="date" placeholder="Periodo" value={dataRelatorio2} style={{alignSelf: 'left'}}         
                                        onChange={({ target }) => setDataRelatorio2(target.value)}/>
                    </Col>

                    <Col md='1'>
                    </Col>

                    <Col md='4'>
                        <Form.Label controlid="floatingInputGrid7">Tipo Visualização</Form.Label>                    
                        <Form.Select value={tipoView} onChange={({target}) => setTipoView(target.value)}>
                            <option value="" disabled></option>
                            <option value="1">Tabela</option>
                            <option value="2">Card</option>
                        </Form.Select>
                    </Col>
                </Row>                    

                </Alert>

                <div className={styles.areaBtn}>    
                <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                            disabled={nivelUsuario === "2" || nivelUsuario === "4"}>Gerar
                </Button>               
                <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
                </div>    

                {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem} /> }                                 


                {totais && 
                    <>
                        <br />
                        <h4>Detalhamento</h4>
                        <hr /> 
                        <br />

                        <Table hover responsive bordered>
{/*                             <thead style={{backgroundColor: 'dodgerblue', color: 'whitesmoke'}}>
                                <tr className={styles.trHead}>
                                <th className={styles.th}>Detalhamento Pedidos - Contratos - Serviços</th>
                                <th className={styles.th}>Valor</th>                          
                            </tr>
                            </thead>                            
 */}                          
                        <tbody className={styles.tbody}>                        
                        {totais.map(total => 
                            <tr key={total.id}>                                     
                                <td className={styles.td1}>{total.detalheTotal}</td>          
                                <td className={styles.td2}>R$ {total.valorDetalheTotal.toFixed(2)}</td>
                            </tr>
                        )}   
                        </tbody>    
                        <tfoot>
                            <tr>
                                <td className={styles.td1}> <b> Total Geral: </b> </td>
                                <td className={styles.td2}> <b>R$ {totalGeral.toFixed(2)}</b> </td>
                            </tr>
                        </tfoot>                  
                        </Table>         
                    </>
                }
            </section>   
    )}
    else {
        return (
            <section className={styles.form}>

                <Alert style={{background: 'ghostwhite', color: 'black'}}>
                    <Alert.Heading style={{textAlign: 'center', fontSize: 'x-large'}}>Faturamento Parcial do Mês</Alert.Heading>        

                <br />

                <Row className="g-2" style={{marginBottom: '1rem'}}>

                    <Col md='1'>
                    </Col>

                    <Col md='2'>
                        <Form.Label controlid="floatingInputGrid14" style={{fontWeight: 'normal', textAlign: 'left'}}>Período Inicial</Form.Label>                       
                            <Form.Control type="date" placeholder="Periodo" value={dataRelatorio} style={{alignSelf: 'left'}}         
                                        onChange={({ target }) => setDataRelatorio(target.value)}/>
                    </Col>            

                    <Col md='1'>
                    </Col>

                    <Col md='2'>
                        <Form.Label controlid="floatingInputGrid14" style={{fontWeight: 'normal', textAlign: 'left'}}>Período Final</Form.Label>                       
                            <Form.Control type="date" placeholder="Periodo" value={dataRelatorio2} style={{alignSelf: 'left'}}         
                                        onChange={({ target }) => setDataRelatorio2(target.value)}/>
                    </Col>

                    <Col md='1'>
                    </Col>

                    <Col md='4'>
                        <Form.Label controlid="floatingInputGrid7">Tipo Visualização</Form.Label>                    
                        <Form.Select value={tipoView} onChange={({target}) => setTipoView(target.value)}>
                            <option value="" disabled></option>
                            <option value="1">Tabela</option>
                            <option value="2">Card</option>
                        </Form.Select>
                    </Col>
                </Row>                    

                </Alert>

                <div className={styles.areaBtn}>    
                <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                            disabled={nivelUsuario === "2" || nivelUsuario === "4"}>Gerar
                </Button>               
                <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
                </div>    

                {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem} /> }                                 

                {totais && 
                    <>
                        <br />
                        <br />
                        <h4>Detalhamento</h4>
                        <hr /> 
                        <br />
                       
                        {totais.map(total => 
                          <div className={styles.card} key={total.id}>
                                <Card className="text-center" key={total.id} >
                                    <Card.Header as="h5">{total.detalheTotal}</Card.Header>
                                    <Card.Body>
                                        <Card.Title className={styles.valor}>R$ {total.valorDetalheTotal.toFixed(2)}</Card.Title>
                                    </Card.Body>                                      
                                </Card>
                          </div>                         
                        )}   

                        {totalGeral>0 &&
                            <div className={styles.card} key={99}>
                                <Card className="text-center" key={99} >
                                    <Card.Header as="h5">* * * Total Geral * * * </Card.Header>
                                    <Card.Body style={{backgroundColor: 'Gainsboro'}}>
                                        <Card.Title className={styles.valor}>R$ {totalGeral.toFixed(2)}</Card.Title>
                                    </Card.Body>                                      
                                </Card>
                            </div>
                        }                   
                    </>
                }
            </section>    
    )
  }        
    
}

export default RelatorioParcialFaturamento;