import React from 'react';

export default function ItemStatus({dataRegistro, baixadaPorNovaVersao}) {
    let color = '';
    let statusTitle = '';

    if (baixadaPorNovaVersao === 0) {
        if (dataRegistro) {
            color = 'PowderBlue';
            statusTitle = 'Registrada';
        } else {
            color = 'palegreen';
            statusTitle = 'Disponível';
        }
    } else {
        color = 'tomato';
        statusTitle = 'Baixada';
    }
    
    return (
        <td style={{ fontSize: "small", backgroundColor: color }}>{statusTitle}</td>                        
    );
}
