import axios from "axios";

const apiRFB = axios.create({
  //  baseURL: 'https://receitaws.com.br/v1/cnpj/'    
    baseURL: 'https://publica.cnpj.ws/cnpj/'
});

apiRFB.interceptors.request.use(
  async config => {
    config.headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      
    };   
    return Promise.resolve(config);
  },
  error => {
    return Promise.reject(error);
  },
);

export default apiRFB;