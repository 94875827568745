import React from "react";
import api from "../../Api/apiAxios";
import apiRFB from "../../Api/apiAxiosRFB";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Col, FloatingLabel, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Mensagem from "../../Components/Mensagem";
import { DISTRIBUIDORES_VALIDAR_INSCRICAO } from "../../Api/apiFetch";
import { Search } from "react-bootstrap-icons";
import Loading from "../../Components/Loading";
import styles from "./EditarDistribuidor.module.css";

const EditarDistribuidor = () => {
    const { id } = useParams();

    const navigate = useNavigate();  
    
    const nivelUsuario = window.localStorage.getItem('nivelUsuario');    

    const tiposDistribuidor = [
        {id2: 1, descricaoTipo: 'Bma'}, 
        {id2: 2, descricaoTipo: 'Revenda'}, 
        {id2: 3, descricaoTipo: 'Integrador'}, 
     ];         

     const tiposInscricao = [
        {idins: 1, tipoIns: 'CNPJ'}, 
        {idins: 2, tipoIns: 'CPF'}, 
     ];         

    var regexEmail = /\S+@\S+\.\S+/;

    const [loading, setLoading] = React.useState(false);

    const [mensagem, setMensagem] = React.useState('');
    const [tipoMensagem, setTipoMensagem] = React.useState(false);

    const [ufs, setListaUfs] = React.useState([]);
    const [cidades, setCidades] = React.useState([]);

    const [tipoInscricao, setTipoInscricao] = React.useState('1');
    const [inscricao, setInscricao] = React.useState('');
    const [tipoDistribuidor, setTipoDistribuidor] = React.useState('');
    const [ramoAtividade, setRamoAtividade] = React.useState('');
    const [inscricaoEstadual, setInscricaoEstadual] = React.useState('');
    const [razaoSocial, setRazaoSocial] = React.useState('');
    const [apelido, setApelido] = React.useState('');
    const [uf, setUf] = React.useState('');
    const [cidade, setCidade] = React.useState('');
    const [bairro, setBairro] = React.useState('');
    const [logradouro, setLogradouro] = React.useState('');
    const [numero, setNumero] = React.useState('');
    const [cep, setCep] = React.useState('');
    const [nomeContato, setNomeContato] = React.useState('');
    const [foneContato, setFoneContato] = React.useState('');
    const [site, setSite] = React.useState('');
    const [emailContato, setEmailContato] = React.useState('');
    const [emailFinanceiro, setEmailFinanceiro] = React.useState('');
    const [emailSuporte, setEmailSuporte] = React.useState('');
    const [observacoes, setObservacoes] = React.useState('');
    const [ordemCompra, setOrdemCompra] = React.useState(false);
    const [status, setStatus] = React.useState(false);
    const [dataInclusao, setDataInclusao] = React.useState('');
    const [dataLiberacao, setDataLiberacao] = React.useState('');
    const [codigoInterno, setCodigoInterno] = React.useState(0);

    function loadUf() {
        let url = 'https://servicodados.ibge.gov.br/';
        url = url + 'api/v1/localidades/estados';
        fetch(url)
          .then(response => response.json())
          .then(data => {        
            data.sort((a,b) => a.nome.localeCompare(b.nome));
            setListaUfs([...data]);
           });
    }

    function loadCidades(id) {
        let url = 'https://servicodados.ibge.gov.br/api/v1/';
        url = url + `localidades/estados/${id}/municipios`;
        fetch(url)
            .then(response => response.json())
            .then(data => {        
            data.sort((a,b) => a.nome.localeCompare(b.nome));
            setCidades([...data]);
            });
    }  
    
    React.useEffect(() => {
        if (uf) {
            loadCidades(uf);
        }
    }, [uf]);            


    React.useEffect(() => {
        loadUf();

        (async () => {
            try {   
                const response = await api.get(`/Distribuidor/${id}`);    
                setTipoInscricao(response.data.tipoInscricao);
                setInscricao(response.data.numeroInscricao);
                setRazaoSocial(response.data.razaoSocial);
                setApelido(response.data.nomeFantasia);
                setTipoDistribuidor(response.data.tipoDistribuidor);
                setInscricaoEstadual(response.data.inscricaoEstadual);
                setRamoAtividade(response.data.ramoAtividade);
                setUf(response.data.uf);
                setCidade(response.data.cidade);
                setCep(response.data.cep);
                setLogradouro(response.data.logradouro);
                setNumero(response.data.numero);
                setBairro(response.data.bairro);
                setSite(response.data.site);
                setNomeContato(response.data.nomeContato);
                setFoneContato(response.data.foneContato);
                setEmailContato(response.data.emailContato);
                setEmailFinanceiro(response.data.emailFinanceiro);
                setEmailSuporte(response.data.emailSuporte);
                setObservacoes(response.data.observacao);

                setDataInclusao(response.data.dataCadastro);               
                setDataLiberacao(response.data.dataLiberacao);
                setCodigoInterno(response.data.codigoInterno);

                if  (response.data.solicitarOrdemCompra === 1)
                    setOrdemCompra(true)
                else
                    setOrdemCompra(false);    

                if  (response.data.statusEmpresa === 1)
                    setStatus(true)
                else
                    setStatus(false);    
                
            } catch (error) {
                alert(error);
            }            
        }
        )();

    }, [id]);                


    function getValueTipoInscricao(e) {
        const idSelected1 = e.target.children[e.target.selectedIndex].getAttribute('idins');
        setTipoInscricao(idSelected1);       
    };  

    function getValueTipoDistribuidor(e) {
        const idSelected2 = e.target.children[e.target.selectedIndex].getAttribute('id2');
        setTipoDistribuidor(idSelected2);       
    };  

    function pesquisarClick() { 
   
        (async () => {
           try {
               if  (tipoInscricao === "1") {

                   if  (inscricao.length !== 14)
                   {
                       setMensagem("CNPJ precisa ter 14 caracteres !")
                       setTipoMensagem("danger");
                       return;
                   }

                   setLoading(true);
                   const response = await apiRFB.get(`${inscricao}`);     
                   console.log(response); 

                   setTipoInscricao("1");
                   setRazaoSocial(response.data.razao_social);
                   setEmailContato(response.data.estabelecimento.email);
                   setUf(response.data.estabelecimento.estado.sigla);
                   setCidade(response.data.estabelecimento.cidade.nome);
                   setLogradouro(response.data.estabelecimento.tipo_logradouro + " " + response.data.estabelecimento.logradouro);
                   setNumero(response.data.estabelecimento.numero);
                   setBairro(response.data.estabelecimento.bairro);
                   setCep(response.data.estabelecimento.cep);
                   setRamoAtividade(response.data.estabelecimento.atividade_principal.descricao);
                   setFoneContato(response.data.estabelecimento.telefone1);
           
                   setLoading(false);
               }

           } catch (error) {
               alert
               ("Problemas de Conexão ou limite de consultas por minuto atingido ! Aguarde 1 minuto e tente novamente !");
               setLoading(false);
           }
       }
       )();        

   }    

    async function handleSubmit(event) {
        event.preventDefault();     

      //#region ValidacaoForm
      if  (tipoInscricao === null) {
        setMensagem("Faltou Selecionar o Tipo da Inscrição");
        setTipoMensagem("danger");
        return;
    }

    if  (tipoDistribuidor === null) {
        setMensagem("Faltou Selecionar o Tipo de Distribuidor");
        setTipoMensagem("danger");
        return;
    }      

    if  (emailContato.length > 5 && !regexEmail.test(emailContato))
    {
        setMensagem("Formato do E-mail de Contato Invalido !");
        setTipoMensagem("danger");
        return;
    }

    if  (emailFinanceiro.length > 5 && !regexEmail.test(emailFinanceiro))
    {
        setMensagem("Formato do E-mail Financeiro Invalido !");
        setTipoMensagem("danger");
        return;
    }

    if  (emailSuporte.length > 5 && !regexEmail.test(emailSuporte))
    {
        setMensagem("Formato do E-mail Suporte Invalido !");
        setTipoMensagem("danger");
        return;
    }

    if  (razaoSocial.length === 0 || razaoSocial.length <5) {
        setMensagem("Razão Social não preenchida ou com numero de caracteres inferior a 5");
        setTipoMensagem("danger");
        return;
    }

    if  (uf === null || cidade === null) {
        setMensagem("Faltou Selecionar UF e/ou Cidade !");
        setTipoMensagem("danger");
        return;
    }

    const inscricaoOnlyNumbers = inscricao.toString().replace(/[^0-9]/g,'');
    const tamanho = inscricaoOnlyNumbers.length;

    if  (tamanho > 14) {
        setMensagem("Campo Inscrição com tamanho inválido !");
        setTipoMensagem("danger");
        return;
    }

    if  (tipoInscricao === 2 && tamanho !== 11) {
        setMensagem("CPF inválido !");
        setTipoMensagem("danger");
        return;
    }

    if  (tipoInscricao === 1 && tamanho !== 14) {
        setMensagem("CNPJ inválido !");
        setTipoMensagem("danger");
        return;
    }   

    const { url2, options2 } = DISTRIBUIDORES_VALIDAR_INSCRICAO(inscricaoOnlyNumbers);        
    const response = await fetch(url2, options2);       
    var retorno = await(response.json());
    if  (retorno.id > 0 && retorno.id !== Number(id)) {
        setMensagem("CPF/CNPJ já vinculado a outro Distribuidor !");
        setTipoMensagem("danger");
        return;
    }

      //#endregion

        const dataAtual = new Date();
        const dataOriginal = new Date(dataInclusao);

        let regDistribuidor = {
            Id: Number(id),
            TipoDistribuidor: Number(tipoDistribuidor),            
            EmailFinanceiro: emailFinanceiro,
            EmailSuporte: emailSuporte,
            Site: site,
            TipoTabela: Number(tipoDistribuidor),    
            SolicitarOrdemCompra: ordemCompra ? 1 : 0,       
            RazaoSocial: razaoSocial,
            NomeFantasia: apelido,
            Logradouro: logradouro,
            Numero: numero, 
            Bairro: bairro,
            Cidade: cidade,
            UF: uf,
            Cep: Number(cep),
            Pais: 1058,
            TipoInscricao: Number(tipoInscricao),
            NumeroInscricao: inscricaoOnlyNumbers,
            InscricaoEstadual: inscricaoEstadual,
            NomeContato: nomeContato,
            FoneContato: foneContato, 
            EmailContato: emailContato,
            RamoAtividade: ramoAtividade,
            Observacao: observacoes,
            StatusEmpresa: status ? 1 : 0,
            DataCadastro: dataOriginal,
            DataAtualizacao: dataAtual,
            DataLiberacao: dataAtual,
            CodigoInterno: codigoInterno,
        }              
      
        const ret = await api.put("Distribuidor/", 
                    JSON.stringify(regDistribuidor), 
              {
                  headers: { 'Content-Type': 'application/json'}
              });

        if  (ret.data.match(/Sucesso/)){
             navigate("/distribuidores");
        }
        else
        {
            setMensagem("Ops, algo deu errado ! Atualize a pagina e tente novamente...");
            setTipoMensagem("danger");
        }     

    };


    if  (loading) return <Loading />

    return (
        <section className={styles.form}>

            {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
            <Alert.Heading style={{textAlign: 'center'}}>Editar Distribuidor</Alert.Heading>        
            <br />  

            <Row className="g2" style={{marginBottom: '1rem'}}>

                <Col md='2'>
                    <FloatingLabel controlid="floatingSelectGrid1" label="Tipo Inscrição">
                    <Form.Select onChange={getValueTipoInscricao}  className=" form-select-control" >
                          <option value="" disabled></option>
                           {tiposInscricao.map((reg) => (
                                    <option key={reg.idins} 
                                            idins={reg.idins}
                                            value={reg.idins}
                                            selected={reg.idins === tipoInscricao}>{reg.tipoIns}</option>
                            ))}

                    </Form.Select>                        
                            
                    </FloatingLabel>
                </Col>            
                 
                <Col md='6'>
                     <FloatingLabel controlid="floatingSelectGrid1" label="Número Inscrição">
                        <Form.Control placeholder="Número da Inscrição" value={inscricao}
                                    onChange={({ target }) => setInscricao(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                 <Col md='4'>
                    <div className="d-flex justify-content-end">
                        <Button onClick={pesquisarClick} variant="outline-primary"> 
                            <Search size={15} /> Pesquisar na Receita Federal
                        </Button>
                    </div>
                </Col> 

            </Row>

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                     <FloatingLabel controlid="floatingSelectGrid3" label="Razão Social">
                        <Form.Control placeholder="Razão Social" value={razaoSocial}
                                    onChange={({ target }) => setRazaoSocial(target.value)}/>
                      </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g2" style={{marginBottom: '1rem'}}>

                <Col md='7'>
                     <FloatingLabel controlid="floatingSelectGrid9" label="Nome Fantasia">
                        <Form.Control placeholder="Apelido" value={apelido}
                                    onChange={({ target }) => setApelido(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='2'>
                     <FloatingLabel controlid="floatingSelectGrid9" label="Codigo Interno">
                        <Form.Control placeholder="Apelido" value={codigoInterno}
                                    onChange={({ target }) => setCodigoInterno(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='3'>
                    <FloatingLabel controlid="floatingSelectGrid2" label="Tipo Distribuidor">
                    <Form.Select onChange={getValueTipoDistribuidor}  className=" form-select-control" >
                            <option value="" disabled></option>
                            {tiposDistribuidor.map((reg) => (
                                    <option key={reg.id2} 
                                            id2={reg.id2}
                                            value={reg.id2}
                                            selected={reg.id2 === tipoDistribuidor}>{reg.descricaoTipo}</option>
                            ))}

                    </Form.Select>                        
                    </FloatingLabel>
                </Col>            

            </Row>

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='4'>
                     <FloatingLabel controlid="floatingSelectGrid5" label="Inscrição Estadual">
                        <Form.Control placeholder="I.E." value={inscricaoEstadual}
                                    onChange={({ target }) => setInscricaoEstadual(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='8'>
                     <FloatingLabel controlid="floatingSelectGrid4" label="Ramo de Atividade">
                        <Form.Control placeholder="Razão Social" value={ramoAtividade}
                                    onChange={({ target }) => setRamoAtividade(target.value)}/>
                      </FloatingLabel>      
                </Col>                
            </Row>                    

            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Endereço</Alert.Heading>        
            <br /> 

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='2'>
                    <FloatingLabel controlid="floatingSelectGrid6" label="UF">
                    <Form.Select value={uf} onChange={({ target }) => setUf(target.value)}>

                        <option value="" disabled></option>
                        {ufs.map((uf, index) =>
                            <option key={index}>{uf.sigla}</option>
                        )}                   
                    </Form.Select>                              
                    </FloatingLabel>
                </Col>            

                <Col md='7'>
                    <FloatingLabel controlid="floatingSelectGridCidades" label="Cidade">
                    <Form.Select value={cidade} onChange={({ target }) => setCidade(target.value)}>

                        <option value="" disabled>Selecione a Cidade</option>
                            {cidades.map((cidade, cont) =>
                            <option key={cont}>{cidade.nome}</option>
                         )}
                   
                    </Form.Select>
                    </FloatingLabel>
                </Col>

                <Col md='3'>
                     <FloatingLabel controlid="floatingSelectGrid7" label="CEP">
                        <Form.Control placeholder="CEP" value={cep}
                                    onChange={({ target }) => setCep(target.value)}/>
                      </FloatingLabel>      
                </Col>                

            </Row>                    

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='6'>
                     <FloatingLabel controlid="floatingSelectGrid8" label="Logradouro">
                        <Form.Control placeholder="Logradouro" value={logradouro}
                                    onChange={({ target }) => setLogradouro(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='2'>
                     <FloatingLabel controlid="floatingSelectGrid8" label="Numero">
                        <Form.Control placeholder="Numero" value={numero}
                                    onChange={({ target }) => setNumero(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='4'>
                     <FloatingLabel controlid="floatingSelectGrid9" label="Bairro">
                        <Form.Control placeholder="Bairro" value={bairro}
                                    onChange={({ target }) => setBairro(target.value)}/>
                      </FloatingLabel>      
                </Col>                

            </Row>                    

            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Contato</Alert.Heading>        
            <br /> 

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='4'>
                     <FloatingLabel controlid="floatingSelectGrid10" label="Nome">
                        <Form.Control placeholder="Nome Contato" value={nomeContato}
                                    onChange={({ target }) => setNomeContato(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingSelectGrid11" label="Telefone">
                        <Form.Control placeholder="Fone Contato" value={foneContato}
                                    onChange={({ target }) => setFoneContato(target.value)}/>
                      </FloatingLabel>      
                </Col>                

                <Col md='5'>
                     <FloatingLabel controlid="floatingSelectGrid12" label="Site">
                        <Form.Control placeholder="Site Contato" value={site}
                                    onChange={({ target }) => setSite(target.value)}/>
                      </FloatingLabel>      
                </Col>                

            </Row>    

            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>E-mails</Alert.Heading>        
            <br /> 

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                     <FloatingLabel controlid="floatingSelectGrid13" label="Principal">
                        <Form.Control placeholder="Email Principal" value={emailContato}
                                    onChange={({ target }) => setEmailContato(target.value)}/>
                      </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                     <FloatingLabel controlid="floatingSelectGrid14" label="Financeiro">
                        <Form.Control placeholder="Email Financeiro" value={emailFinanceiro}
                                    onChange={({ target }) => setEmailFinanceiro(target.value)}/>
                      </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                     <FloatingLabel controlid="floatingSelectGrid14" label="Suporte">
                        <Form.Control placeholder="Email Suporte" value={emailSuporte}
                                    onChange={({ target }) => setEmailSuporte(target.value)}/>
                      </FloatingLabel>      
                </Col>                
            </Row>

            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Informações Adicionais</Alert.Heading>        
            <br /> 

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                     <FloatingLabel controlid="floatingSelectGrid15" label="Observações Gerais">
                        <Form.Control placeholder="Email Financeiro" value={observacoes}
                                    onChange={({ target }) => setObservacoes(target.value)}/>
                      </FloatingLabel>      
                </Col>    
            </Row>

            <Row className="g2" style={{marginBottom: '1rem'}}>
                <Col md='4'>
                    <Form.Check 
                        type="switch"
                        id="ordem-compra"
                        label="Ordem de Compra"                      
                        onChange={() => setOrdemCompra(!ordemCompra)}
                        checked={ordemCompra}
                    />

                </Col>

                <Col md='4'>
                    <Form.Check 
                        type="switch"
                        id="status"
                        label="Distribuidor Ativo"                      
                        onChange={() => setStatus(!status)}
                        checked={status}
                    />

                </Col>

            </Row>

            </Alert>

            <div className={styles.areaBtn}>    
                {nivelUsuario === "1" ?
                      <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn}>Atualizar</Button>                         
                      :
                      <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} disabled>Atualizar</Button>
                }
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>

    )
}

export default EditarDistribuidor;