import React from "react";
import api from "../../Api/apiAxios";
import { Button, Table } from "react-bootstrap";
import { Pencil, PlusSquare } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import HeaderCadastros from "../../Components/HeaderCadastros";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Paginacao } from "../../Components/Paginacao";
import styles from "./ClientesIndex.module.css";

const ClientesIndex = () => {

    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');

    const { mensagemGlobal, setMensagemGlobal, tipoMensagemGlobal, 
            listaGlobalClientes, setListaGlobalClientes,setListaGlobalContratos,
            setDistribuidorIdGlobal, itensPorPagina } = React.useContext(MyContext);

    const [pesquisa, setPesquisa] = React.useState('');
    const [clientes, setClientes] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const [registroInicial, setRegistroInicial] = React.useState(1);
    const [registroFinal, setRegistroFinal] = React.useState(20);
    const [quantidadeRegistros, setQuantidadeRegistros] = React.useState(0);


    React.useEffect(() => {  
            setListaGlobalContratos(null);
            setDistribuidorIdGlobal('');

            async  function pesquisar() { 
                setLoading(true);                             
                try {
                    if  (listaGlobalClientes === null) {

                        const response = await 
                              api.get(`/Cliente/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}&regInicial=${registroInicial}&itensPorPagina=${999999}`);   
    
                       // console.log(response.data);
    
                        var qtdeRegistros = response.data[0].cep;
                        setQuantidadeRegistros(qtdeRegistros);             
    
                        const response2 = await 
                              api.get(`/Cliente/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}&regInicial=${registroInicial}&itensPorPagina=${itensPorPagina}`);                 
                        response2.data.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));
                        setClientes(response2.data); 
                    }           
                    else {
                        setClientes(listaGlobalClientes);
                    }
                } catch (e) {
                    alert(e);
                } finally {
                    setLoading(false);
                }       
        }

        pesquisar();            
    }, []);            

    function pesquisarClick(event) { 
        event.preventDefault();     
        (async () => {
            try {
                setLoading(true);
                                      
                if  (pesquisa.length > 0) {
                    const response = await api.get(`/Cliente/${idDistribuidor}/A`);
                    response.data.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));

                    if  (isNaN(pesquisa)) {
                        const pesquisaLowerCase = pesquisa.toLowerCase();
                        const registrosFiltrados = response.data.filter((cliente) => 
                              cliente.razaoSocial.toLowerCase().includes(pesquisaLowerCase));               
                        setClientes(registrosFiltrados);            
                        setListaGlobalClientes(registrosFiltrados);
                    } else {
                        const cnpjComMascara = pesquisa.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
//                        console.log(cnpjComMascara);

                        const registrosFiltrados = response.data.filter((cliente) => 
                              cliente.numeroInscricao.includes(cnpjComMascara));               
                        setClientes(registrosFiltrados);            
                        setListaGlobalClientes(registrosFiltrados);
                    }
                } else {
                    const response = await 
                          api.get(`/Cliente/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}&regInicial=${registroInicial}&itensPorPagina=${itensPorPagina}`);   
                    response.data.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));    
                    setClientes(response.data);
                    setListaGlobalClientes(null);
                }    
                setLoading(false);
                setPesquisa('');
                

            } catch (error) {
                alert(error);
            }
        }
        )();
    }

    function adicionarClick(event) {
        event.preventDefault();
        navigate("/novocliente");
    }

    function cnpjsAdicionaisClick(cliente) {
        navigate(`/clientecnpj/${cliente.id}`);
    }

    function editarClick(cliente) {              
        navigate(`/editarcliente/${cliente.id}`);
    }

    if (loading) return <Loading />

    return (
       <section>
         {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

          <HeaderCadastros titulo={"Clientes"} textoBotao={" Adicionar Cliente"} 
                pesquisa={pesquisa} setPesquisa={setPesquisa}
                pesquisarClick={pesquisarClick} adicionarClick={adicionarClick} />

            <Table striped hover responsive size="sm">
                <thead className={styles.thead}>
                    <tr className={styles.trHead}>
                            <th className={styles.th}>Razão Social</th>
                            <th className={styles.th}>Cnpj/Cpf</th>                          
                            <th className={styles.th}>Contato</th>                        
                            <th className={styles.th}>E-Mail</th>
                            <th className={styles.th}>Fone</th>
                        </tr>
                </thead>
                <tbody className={styles.tbody}>
                {clientes.map(cliente => 
                    <tr key={cliente.id} className={styles.trBody}>                                     
                        <td className={styles.td}>{cliente.razaoSocial}</td>          
                        <td className={styles.inscricao}>{cliente.numeroInscricao}</td>
                        <td className={styles.td}>{cliente.nomeContato}</td>
                        <td className={styles.td}>{cliente.emailContato}</td>  
                        <td className={styles.td}>{cliente.foneContato}</td>
                        <td style={{display: 'flex'}}>
                            <OverlayTrigger overlay={
                                <Tooltip id="btn-editar">Editar</Tooltip> }> 
                                <Button onClick={() => editarClick(cliente)}
                                        variant="outline-warning">
                                    <Pencil size={15}/>
                                </Button>   
                            </OverlayTrigger>                                                         

                            <span> </span>

                            <OverlayTrigger   overlay={
                                <Tooltip id="btn-cnpjs">Cnpjs Adicionais</Tooltip> }>                              
                                <Button onClick={() => cnpjsAdicionaisClick(cliente)}                                       
                                        variant="outline-success">
                                        <PlusSquare size={15}/>
                                </Button>                                       
                            </OverlayTrigger>                     
                        </td>                        
 
                    </tr>
                )}
            </tbody>
            <tfoot>
                <Paginacao
                    setRegistrosData={setClientes}
                    setRegistroFinal={setRegistroFinal}
                    setRegistroInicial={setRegistroInicial} 
                    registroInicial={registroInicial}     
                    registroFinal={registroFinal}                                
                    quantidadeRegistros={quantidadeRegistros} 
                    itensPorPagina={itensPorPagina}
                    urlPesquisa={`/Cliente/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}`}
                />
            </tfoot>

            </Table>

       </section>
    )

}

export default ClientesIndex;