import React from "react"
import { Alert, Button } from "react-bootstrap";
import styles from "./ModalExclusao.module.css";


export const ModalExclusao = ( {setModalOpen, mensagem, setExclusaoOk} ) => {

    function setarParametros () {
        setModalOpen(false);
        setExclusaoOk(true);
    }

    return(
        <div className={styles.modalBackground}>
            <div className={styles.modalContainer}>
                <h3>Confirmação de Exclusão</h3>
                <hr/>

                <div className={styles.modalBody}>

                    <Alert variant="danger">
                        <p className={styles.mensagem}>{mensagem}</p>
                    </Alert>

                </div>

                <div className={styles.modalFooter}>

                    <Button className={styles.btn} variant="outline-danger"
                            onClick={() => setarParametros()}>Deletar</Button>

                    <Button className={styles.btn} variant="outline-secondary"
                            onClick={() => setModalOpen(false)}>Cancelar</Button>
                </div>

            </div>
        </div>
    )
 }

 export default ModalExclusao;
