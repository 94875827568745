import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../Components/Loading";
import { Button, Form, Row, Col, FloatingLabel, Alert, ListGroup } from "react-bootstrap";
import styles from "./ViewPedidoBmaFolha.module.css";

const ViewPedidoBmaFolha = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [registro, setRegistro] = React.useState({});
    const [razaoSocial, setRazaoSocial] = React.useState('');
    const [apelido, setApelido] = React.useState('Utilizado na personalização');
    const [inscricao, setInscricao] = React.useState('');
    const [statusPedido, setStatusPedido] = React.useState('');
    const [valorParcela, setValorParcela] = React.useState('0,00')
    const [cnpjs, setCnpjs] = React.useState([]);

    React.useEffect(() => {
        (async () => {
            setLoading(true);
            try {   
                const response = await api.get(`/BmaFolha/${id}`);    
                
                let calcValorParcela = (Number(response.data.ValorBruto)/Number(response.data.quantidadeParcelas)).toFixed(2);

                setValorParcela(calcValorParcela);

                switch(response.data.statusPedido) {
                    case 0 : setStatusPedido('Pendente');
                        break;                             
                    case 1 : setStatusPedido('Liberado');
                        break;                
                    case 2 : setStatusPedido('Cancelado');
                        break;
                    default: setStatusPedido('');

                }

                const dataString = response.data.dataPedido.substring(8,10) + "/" +
                                   response.data.dataPedido.substring(5,7)  + "/" +   
                                   response.data.dataPedido.substring(0,4);
                response.data.dataPedido = dataString;   

                if (response.data.dataLiberacao !== null) {
                    const dataLiberacaoString = response.data.dataLiberacao.substring(8,10) + "/" +
                                                response.data.dataLiberacao.substring(5,7)  + "/" +   
                                                response.data.dataLiberacao.substring(0,4);
                     response.data.dataLiberacao = dataLiberacaoString;
                }
                else response.data.dataLiberacao = '* * * * * *';

                if (response.data.dataCancelamento !== null) {
                    const dataCancelamentoString = response.data.dataCancelamento.substring(8,10) + "/" +
                                                   response.data.dataCancelamento.substring(5,7)  + "/" +   
                                                   response.data.dataCancelamento.substring(0,4);
                    response.data.dataCancelamento = dataCancelamentoString;
                }
                else {
                    response.data.dataCancelamento = '* * * * * *';
                    response.data.motivoCancelamento = '* * * * * *';
                }                 
                    
                buscarCliente(response.data.clienteId);

                const resp = await api.get(`/ClienteCnpj/${response.data.clienteId}`);
                const registrosFiltrados = resp.data.filter((cnpjAdicional) => cnpjAdicional.status === 1);          
                setCnpjs(registrosFiltrados);

                setRegistro(response.data);
                

            } catch (error) {
                alert(error);
            } finally {
                setLoading(false);
            }         
        }
        )();
    }, [id]);                

    function buscarCliente(id) {
        (async () => {
            try {   
                const response = await api.get(`/Cliente/${id}`);    
                setApelido(response.data.nomeFantasia);   
                setRazaoSocial(response.data.razaoSocial);             
                setInscricao(response.data.numeroInscricao);
            } catch (error) {
                alert(error);
            }            
        }
        )();
    }

    if  (loading) return <Loading />

    return (
        <section className={styles.form}>
            <Alert style={{background: 'ghostwhite', color: 'black'}}>

            {statusPedido === 'Liberado' &&
               <Alert.Heading style={{textAlign: 'center', color: 'seagreen'}}>Pedido BmaFolha ( {statusPedido} )</Alert.Heading>        
            }
            {statusPedido === 'Cancelado' &&
               <Alert.Heading style={{textAlign: 'center', color: 'tomato'}}>Pedido BmaFolha ( {statusPedido} )</Alert.Heading>        
            }
            {statusPedido === 'Pendente' &&
               <Alert.Heading style={{textAlign: 'center'}}>Pedido BmaFolha ( {statusPedido} )</Alert.Heading>        
            }

            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}>   
                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Tipo de Pedido">
                        <Form.Select value={registro.tipoPedido} disabled>
                            <option value="" disabled></option>
                            <option value="0">Cópia Nova</option>
                            <option value="1">Atualização</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid5" label="Número de Série">                               
                            <Form.Control value={registro.numeroSerie} disabled />                       
                    </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid6" label="Versão">
                            <Form.Control value={registro.versaoSistema} disabled />                        
                    </FloatingLabel>      
                </Col>                

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid7" label="Release">
                            <Form.Control value={registro.versaoRealese} disabled />             
                      </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>    
                <Col md='6'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Cliente">
                        <Form.Control value={razaoSocial} disabled />             
                    </FloatingLabel>                   
                </Col>

                <Col md='4'>
                     <FloatingLabel controlid="floatingInputGrid4" label="Nome Fantasia">
                        <Form.Control value={apelido} disabled />
                      </FloatingLabel>      
                </Col>                

                <Col md='2'>
                     <FloatingLabel controlid="floatingInputGrid4" label="CNPJ / CPF">
                        <Form.Control value={inscricao} disabled />
                      </FloatingLabel>      
                </Col>                
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Faixa Funcionários">
                        <Form.Control value={registro.faixaFuncionarios} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid7" label="Tipo de Instalação">
                        <Form.Select value={registro.tipoInstalacao} disabled>
                            <option value="" disabled></option>
                            <option value="0">Licenciado</option>
                            <option value="1">SaaS</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='3'>
                     <FloatingLabel controlid="floatingInputGrid8" label="Quantidade Usuários">
                        <Form.Control value={registro.quantidadeUsuarios} disabled />
                      </FloatingLabel>      
                </Col>                

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid9" label="Banco De Dados">
                        <Form.Select value={registro.tipoBancoDados} disabled>
                            <option value="" disabled></option>
                            <option value="1">MySql</option>
                            <option value="2">SqlServer</option>
                            <option value="3">Oracle</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>                
            </Row>

            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Integrações & Módulos Adicionais</Alert.Heading>        
            <br /> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="ControleEmpresa"
                        label="Controle Empresas"                      
                        readOnly
                        checked={registro.controleEmpresas === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="CalculoFolha"
                        label="Cálculo Folha"                      
                        readOnly
                        checked={registro.moduloFolha === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="Rpa"
                        label="RPA"                      
                        readOnly
                        checked={registro.moduloRpa === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="Treinamento"
                        label="Competencia/Treinamento"                      
                        readOnly
                        checked={registro.moduloCompetenciaTreinamento === 1}
                    />
                </Col>
            </Row>                             

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="Cargos"
                        label="Cargos e Salários"                      
                        readOnly
                        checked={registro.moduloCargosSalarios === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="SST"
                        label="SST"                      
                        readOnly
                        checked={registro.moduloSST === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="EM"
                        label="Exames Médicos"                      
                        readOnly
                        checked={registro.moduloExamesMedicos === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="ppp"
                        label="PPP"                      
                        readOnly
                        checked={registro.moduloPpp === 1}
                    />
                </Col>
            </Row>                    

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="epi"
                        label="EPI"                      
                        readOnly
                        checked={registro.moduloEpi === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="Paj"
                        label="PAJ"                      
                        readOnly
                        checked={registro.moduloPAJ === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="integraPonto"
                        label="BmaPonto"                      
                        readOnly
                        checked={registro.wpe === 1}
                    />
                </Col>

                <Col md='3'>
                    <Form.Check 
                        type="switch"
                        id="contrato"
                        label="Contrato"                      
                        readOnly
                        checked={registro.contrato === 1}
                    />
                </Col>
            </Row>                    

            <br /> 
            <hr />                    
            <Alert.Heading style={{textAlign: 'center'}}>Outras Informações</Alert.Heading>        
            <br /> 

            <Row style={{marginBottom: '1rem'}}>
                <Col md='12' >
                    <FloatingLabel controlid="floatingInputGrid11" label="Observações">
                        <Form.Control as="textarea" style={{height: '100px'}} value={registro.observacao} disabled />
                    </FloatingLabel>      
                </Col>    
            </Row> 

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Data Pedido">
                        <Form.Control value={registro.dataPedido} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Data Liberação">
                        <Form.Control value={registro.dataLiberacao} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Data Cancelamento">
                        <Form.Control value={registro.dataCancelamento} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid11" label="Motivo">
                        <Form.Control value={registro.motivoCancelamento} disabled />
                    </FloatingLabel>      
                </Col>

            </Row>   

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md= '6'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Quantidade de Parcelas">
                        <Form.Control value={registro.quantidadeParcelas} disabled />
                    </FloatingLabel>
                </Col>

                <Col md= '6'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Valor da Parcela (R$)">
                        <Form.Control value={valorParcela} disabled />
                    </FloatingLabel>
                </Col>
            </Row>                   

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='2'>
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Total Pedido (R$)" style={{fontWeight: 'bold'}}>
                        <Form.Control value={registro.valorBruto} disabled />
                    </FloatingLabel>      
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid12" label="Valor Mensal (R$)" style={{fontWeight: 'bold'}}>                       
                        <Form.Control value={registro.valorMensal} disabled />
                    </FloatingLabel>      
                </Col>
            </Row>                    

            {cnpjs.map(cnpj => 
                <ListGroup horizontal  key={cnpj.inscricaoAdicional} className="my-2">
                    <ListGroup.Item variant="secondary" style={{width: "525px", fontWeight: "bold", fontSize: 14}}>{cnpj.razaoSocial}</ListGroup.Item>
                    <ListGroup.Item variant="secondary" style={{width: "200px", fontWeight: "bold", fontSize: 14}}>{cnpj.inscricaoAdicional}</ListGroup.Item>
                </ListGroup>                                                                                        
                    
            )}

            </Alert>

            <div className={styles.areaBtn}>                    
                <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )
}

export default ViewPedidoBmaFolha;
