import React from "react";
import api from "../../Api/apiAxios";
import { Form, Alert, Button, Col, Row, OverlayTrigger, Tooltip,Table, FloatingLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading";
import Mensagem from "../../Components/Mensagem";
import { MyContext } from "../../Context/MyContext";
import { FolderPlus } from "react-bootstrap-icons";
import styles from "./RelatorioParcialFaturamentoRevenda.module.css";

const RelatorioParcialFaturamentoRevenda = () => {
    const navigate = useNavigate();

    const { listaGlobalClientes, setListaGlobalClientes,
            listaGlobalContratos, setListaGlobalContratos } = React.useContext(MyContext);

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');   

    const [loading, setLoading] = React.useState(false);
    const [mensagem, setMensagem] = React.useState(null);
    const [tipoMensagem, setTipoMensagem] = React.useState('');
    const [dataRelatorio, setDataRelatorio] = React.useState('');
    const [dataRelatorio2, setDataRelatorio2] = React.useState('');
    const [distribuidorSelecionado, setDistribuidorSelecionado] = React.useState('');
    const [titulo, setTitulo] = React.useState('');
    const [totalGeral, setTotalGeral] = React.useState(0);
    const [totais, setTotais] = React.useState([]);
    const [detalhesTotal, setDetalhesTotal] = React.useState([]);
    const [distribuidores, setDistribuidores] = React.useState([]);

    React.useEffect(() =>{
        setListaGlobalClientes(null);
        setListaGlobalContratos(null);

        var data = new Date();
       
        const ano = data.getFullYear();
        const mes = data.getMonth();
        const dia = 26;
        var data1 = new Date(ano, mes, dia);

        const dias = 30;
        const milissegundos_por_dia = 1000 * 60 * 60 * 24;

        var dataInicial = new Date(data1.getTime() -  (dias * milissegundos_por_dia));

        var ano1 = dataInicial.getFullYear();
        var mes1 = String(dataInicial.getMonth() + 1).padStart(2,'0')
        var dia1 = dataInicial.getDate().toString().padStart(2,'0')
        setDataRelatorio(ano1 + "-" + mes1 + "-" + 26);

        const mesEditado = String(data.getMonth() + 1).padStart(2,'0');
        setDataRelatorio2(ano + "-" + mesEditado + "-" + 25);

        async  function pesquisarDistribuidores() { 
            setLoading(true);                  

            if  (nivelUsuario !== "1") {
                 const resp = await api.get(`/Distribuidor/${idDistribuidor}`);  
                 const novoArray = []; 
                 novoArray.unshift(resp.data);
                 setDistribuidores(novoArray);            
            } else {
                   const response = await api.get('/Distribuidor');
                   const registrosFiltrados = response.data.filter((distribuidor) => 
                         distribuidor.statusEmpresa === 1);          
                   registrosFiltrados.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));
                   setDistribuidores(registrosFiltrados);  
            }    
                   
            setLoading(false);       
     }

     pesquisarDistribuidores();

    }, [])
   
    function sair() {
        navigate(-1);
    }

    function getValueDistribuidor(ev) {
        const idSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setDistribuidorSelecionado(idSelected);   
    };  

    async function viewDetalhes(reg) {

        setLoading(true);

        const response = 
            await api.get(`/Totais/relatorio-por-revenda-detalhamento?id=${reg.id}&distribuidorId=${reg.distribuidorId}`);   
        response.data.sort((a,b) => a.razaoSocialCliente.localeCompare(b.razaoSocialCliente));
        setDetalhesTotal(response.data);

        switch(Number(reg.id)) {
            case 11 : setTitulo('BmaPonto Licenciado');
                break;                             
            case 12 : setTitulo('BmaPonto Saas-RepC');
                break;                
            case 13 : setTitulo('BmaPonto Saas-RepP');
                break;                
            case 21 : setTitulo('BmaAcesso Empresa');
                break;                             
            case 22 : setTitulo('BmaAcesso Refeitorio');
                break;                             
            case 23 : setTitulo('BmaAcesso Academia');
                break;                             
            case 24 : setTitulo('BmaAcesso Condominio');
                break;                             
            case 25 : setTitulo('BmaAcesso Hospital');
                break;                             
            case 26 : setTitulo('BmaAcesso Escola');
                break;                             
            case 31 : setTitulo('Serviços');
                break;                             
            case 41 : setTitulo('Contratos');
                break;                             
        }

        setLoading(false);
    }

    async function handleSubmit(ev) {
        ev.preventDefault();

        if  (dataRelatorio.length === 0) {
            setMensagem("Faltou Selecionar o Período");
            setTipoMensagem("danger");
            return;
        }

        if  (dataRelatorio2.length === 0) {
            setMensagem("Faltou Selecionar o Período");
            setTipoMensagem("danger");
            return;
        }

        if  (distribuidorSelecionado.length === 0) {
            setMensagem("Faltou Selecionar o Distribuidor");
            setTipoMensagem("danger");
            return;
        }

        try {
            setLoading(true); 
            setMensagem('');                
          //  console.log(distribuidorSelecionado);
                 
            const tipoRelatorio = 0;
            const response = 
                await api.get(`/Totais/relatorio-por-revenda?id=${distribuidorSelecionado}&nivel=${nivelUsuario}&tipoRelatorio=${tipoRelatorio}&dataInicial=${dataRelatorio}&dataFinal=${dataRelatorio2}`);        
                setTotais(response.data);

                var sum = response.data.reduce(function(accumulator,object){ 
                    return accumulator + object.valorParcela
                  },0);                 
                setTotalGeral(sum);   
                
                setDetalhesTotal(null);
        }
        catch (error) {
              alert("Não foi possível gerar o relatório - Erro " + error);
        } finally {
            setLoading(false);
        }
    }


    if  (loading) return <Loading />

    return (
        <section className={styles.form}>

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center', fontSize: 'large'}}>Faturamento Parcial do Mês por Revenda</Alert.Heading>        

            <br />

            <Row className="g-2" style={{marginBottom: '1rem'}}>

            <Col md='3'>
            </Col>

{/*                 <Col md='2'>
                    <Form.Label controlid="floatingInputGrid14" style={{fontWeight: 'normal', textAlign: 'left'}}>Período Inicial</Form.Label>                       
                        <Form.Control type="date" placeholder="Periodo" value={dataRelatorio} style={{alignSelf: 'left'}}         
                                    onChange={({ target }) => setDataRelatorio(target.value)}/>
                </Col>            

                <Col md='1'>
                </Col>

                <Col md='2'>
                    <Form.Label controlid="floatingInputGrid14" style={{fontWeight: 'normal', textAlign: 'left'}}>Período Final</Form.Label>                       
                        <Form.Control type="date" placeholder="Periodo" value={dataRelatorio2} style={{alignSelf: 'left'}}         
                                    onChange={({ target }) => setDataRelatorio2(target.value)}/>
                </Col>

                <Col md='1'>
                </Col>
 */}
                <Col md='6'>
                    <FloatingLabel controlid="floatingInputGrid" label="Selecione o Distribuidor">
                        <Form.Select onChange={getValueDistribuidor} value={distribuidorSelecionado} className=" form-select-control">
                            <option></option>                               
                            {distribuidores.map((distribuidor) => (
                                <option key={distribuidor.id} 
                                        id={distribuidor.id}
                                        value={distribuidor.id}
                                        selected={distribuidor.id === distribuidorSelecionado}>{distribuidor.razaoSocial}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>    

                </Col>

            </Row>                    

            </Alert>

            <div className={styles.areaBtn}>    
            <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                        disabled={nivelUsuario === "2" || nivelUsuario === "4"}>Gerar
            </Button>               
            <Button onClick={sair} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

            {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem} /> }                                 


            {totais &&
                <>
                    {/* <h4>Detalhamento</h4> */}
                    {/* <hr />  */}
                    <br />

                    {totais.length > 0 &&
                    <Table hover responsive>
                        <thead style={{backgroundColor: 'dodgerblue', color: 'whitesmoke'}}>
                            <tr className={styles.trHead}>
                                <th className={styles.th}>Sub-Totais da Revenda</th>
                                <th className={styles.th}>Valor</th>  
                                <th></th>                        
                            </tr>
                        </thead>                            
                                              
                        <tbody className={styles.tbody}>                        
                        {totais.map(total => 
                            <tr key={total.id}>                                     
                                <td className={styles.td1}>{total.descricaoRelatorio}</td>          
                                <td className={styles.td2}>R$ {total.valorParcela.toFixed(2)}</td>
                                <td>
                                <span> </span>                     
                                    <OverlayTrigger   overlay={
                                    <Tooltip id="btn-view-detalhes">Detalhar Totalizador</Tooltip> }>                              
                                        <Button onClick={() => viewDetalhes(total)}                                       
                                                variant="outline-primary">
                                                <FolderPlus size={18}/>
                                        </Button>                                                                              
                                    </OverlayTrigger>                                          
                                </td>
                            </tr>
                        )}   
                        </tbody>    
                        <tfoot>
                            <tr>
                                <td className={styles.td1}> <b> Total Geral: </b> </td>
                                <td className={styles.td2}> <b>R$ {totalGeral.toFixed(2)}</b> </td>
                            </tr>
                        </tfoot>  
                    </Table>         
                    }
                    <br />
                </>
            }

            {detalhesTotal &&
              <> 
              <h4 className={styles.margemtab2}>{titulo}</h4>
                <Table responsive hover className={styles.tabela2}>
                    {detalhesTotal.length > 0 &&
                        <thead style={{backgroundColor: 'dodgerblue', color: 'whitesmoke'}}>
                            <tr className={styles.trHead}>
                                <th className={styles.th}>Cliente</th>
                                <th className={styles.th}>Data</th>                          
                                <th className={styles.th}>Parcelas/Tipo</th>                          
                                <th className={styles.th}>Valor</th>                          
                            </tr>
                        </thead>                 
                    }           
                    <tbody>
                        {detalhesTotal.map(reg => 
                        <tr key={reg.id} style={{backgroundColor: 'aliceblue'}}>      
                            <td className={styles.td3}>{reg.razaoSocialCliente}</td>          
                            <td className={styles.td3}>
                                {reg.dataFaturamento.substring(8,10) + "/" +
                                 reg.dataFaturamento.substring(5,7)  + "/" +   
                                 reg.dataFaturamento.substring(0,4)}
                            </td>  
                            {titulo === "Contratos" ?
                                <td className={styles.td5}>{reg.descricaoRelatorio}</td>     
                              : <td className={styles.td3}>{reg.parcelaDoMes}/{reg.parcelas}</td>          
                            }
                            <td className={styles.td4}>R$ {reg.valorParcela.toFixed(2)}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
              </>
            }
        </section>   
)}
    


export default RelatorioParcialFaturamentoRevenda;