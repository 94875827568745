import React from "react";
import api from "../../../Api/apiAxios";
import { useNavigate, useParams } from "react-router-dom";
import { MyContext } from "../../../Context/MyContext";
import Mensagem from "../../../Components/Mensagem";
import { Button, Form, Row, Col, FloatingLabel, Alert } from "react-bootstrap";
import Loading from "../../../Components/Loading";
import styles from "./EditarPersonalizacaoFolha.module.css";

const EditarPersonalizacaoFolha = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  

    const { mensagemGlobal, setMensagemGlobal, setTipoMensagemGlobal, tipoMensagemGlobal } = React.useContext(MyContext);

    const [loading, setLoading] = React.useState(false);

    const [razaoSocialDistribuidor, setRazaoSocialDistribuidor] = React.useState('');
    const [razaoSocialCliente, setRazaoSocialCliente] = React.useState('');
    const [numeroInscricao, setNumeroInscricao] = React.useState('');
    const [numeroSerie, setNumeroSerie] = React.useState('');
    const [dataValidade, setDataValidade] = React.useState(``);

    React.useEffect(() => {
        (async () => {
            try {   
                setLoading(true);
                const tipo = "ID";
                const response = await api.get(`/LicencaFolha/${tipo}/${id}`);    

                setRazaoSocialDistribuidor(response.data.distribuidor.razaoSocial);
                setRazaoSocialCliente(response.data.razaoSocial);
                setNumeroInscricao(response.data.numeroInscricao);
                setNumeroSerie(response.data.numeroSerie);
                if  (response.data.dataValidade) {                    
                    var data = response.data.dataValidade.toString();
                    var ano = data.substr(0,4);
                    var mes = data.substr(5,2);
                    var dia = data.substr(8,2);                  
                    setDataValidade(ano + "-" + mes + "-" + dia);            
                }
                else {
                    var data = new Date();
                    var ano = data.getFullYear();
                    var mes = String(data.getMonth() + 1).padStart(2,'0')
                    var dia = data.getDate().toString().padStart(2,'0')
                    setDataValidade(ano + "-" + mes + "-" + dia);            
                }

                setLoading(false);

            } catch (error) {
                alert(error);
            }            
        }
        )();

    }, [id]);                


    async function handleSubmit(ev) {
        ev.preventDefault();
        setMensagemGlobal(null);

        const retorno = await api.put(`/LicencaFolha/atualizar-data-validade?id=${id}&dataValidade=${dataValidade}`);   
              
        setMensagemGlobal(retorno.data);
        setTipoMensagemGlobal("success");               
        navigate("/personalizacoesfolha");

    }


    if  (loading) return <Loading />

    return (
        <section className={styles.form}>
            {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center'}}>Editar Licença BmaFolha</Alert.Heading>        
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}> 

                <Col md='12'>
                    <FloatingLabel controlid="floatingInputGrid" label="Distribuidor">
                    <Form.Control placeholder="Release" value={razaoSocialDistribuidor} disabled
                                    onChange={({ target }) => setRazaoSocialDistribuidor(target.value)}/>             
                   </FloatingLabel>    
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>    
                <Col md='7'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Cliente">
                    <Form.Control placeholder="Release" value={razaoSocialCliente} disabled
                                    onChange={({ target }) => setRazaoSocialCliente(target.value)}/>             
                    </FloatingLabel>                  
                </Col>

                <Col md='3'>
                    <FloatingLabel controlid="floatingInputGrid2" label="CNPJ / CPF">
                    <Form.Control placeholder="Release" value={numeroInscricao} disabled
                                    onChange={({ target }) => setNumeroInscricao(target.value)}/>             
                    </FloatingLabel>                  
                </Col>

                <Col md='2'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Número Série">
                    <Form.Control placeholder="Release" value={numeroSerie} disabled
                                    onChange={({ target }) => setNumeroSerie(target.value)}/>             
                    </FloatingLabel>                  
                </Col>
            </Row>

            <br />
            <hr />

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='4'>                    
                </Col>

                <Col md='4'>
                        <Form.Label controlid="floatingInputGrid14" style={{fontWeight: 'normal', textAlign: 'left'}}>Data Validade da Cópia</Form.Label>                       
                            <Form.Control type="date" placeholder="data Val." value={dataValidade} style={{alignSelf: 'left'}}         
                                        onChange={({ target }) => setDataValidade(target.value)}/>
                </Col>            

            </Row>

            </Alert>

            <div className={styles.areaBtn}>                
               <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                       disabled={nivelUsuario !== "1"}>Atualizar
               </Button>
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )

}

export default EditarPersonalizacaoFolha;