import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate } from "react-router-dom";
import { VictoryChart, VictoryBar, VictoryTooltip, VictoryLine, VictoryScatter } from "victory";
import Loading from "../../Components/Loading";
import styles from "./Dashboard.module.css";
import { Table } from "react-bootstrap";

const Dashboard = () => {
    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');   

    const [loading, setLoading] = React.useState(false);
    const [grafico, setGrafico] = React.useState([]);
    const [grafico2, setGrafico2] = React.useState([]);
    const [grafico3, setGrafico3] = React.useState([]);
    const [grafico4, setGrafico4] = React.useState([]);
    const [listaDistribuidores, setListaDistribuidores] = React.useState([]);
    const [totalPonto, setTotalPonto] = React.useState(0);
    const [totalAcesso, setTotalAcesso] = React.useState(0);
    const [totalFolha, setTotalFolha] = React.useState(0);
    const [totalGeral, setTotalGeral] = React.useState(0);

    const data = new Date();
    const anoAtual = data.getFullYear();
    
    React.useEffect(() => {            
        async function getFaturamento() {

            const qtdeMeses = 6;
            let tipo = 0;
            const response = await 
                  api.get(`/Totais/grafico-faturamento?id=${idDistribuidor}&nivel=${nivelUsuario}&qtdeMeses=${qtdeMeses}`);   

            const listaGrafico = response.data.map((item) => {
                  return {
                    x: item.detalheTotal,
                    y: Number(item.valorDetalheTotal / 1000),
                  };
            });       
            setGrafico(listaGrafico);     
        }

        async function getContratosAtivos() {

            const qtdeMeses = 6;
            let tipo = 1;
            const response = await 
                  api.get(`/Totais/grafico-contrato?id=${idDistribuidor}&nivel=${nivelUsuario}&qtdeMeses=${qtdeMeses}&status=${tipo}`);   

            const listaGrafico2 = response.data.map((item) => {
                  return {
                    x: item.detalheTotal,
                    y: Number(item.valorDetalheTotal),
                  };
            });       
            setGrafico2(listaGrafico2);                            
        }

        async function getContratosCancelados() {

            const qtdeMeses = 6;
            let tipo = 0;
            const response = await 
                  api.get(`/Totais/grafico-contrato?id=${idDistribuidor}&nivel=${nivelUsuario}&qtdeMeses=${qtdeMeses}&status=${tipo}`);   

            const listaGrafico3 = response.data.map((item) => {
                  return {
                    x: item.detalheTotal,
                    y: Number(item.valorDetalheTotal),
                  };
            });       
            setGrafico3(listaGrafico3);                            
        }

        async function getPedidosFaturados() {

            const qtdeMeses = 6;
            let tipo = 1;
            const response = await 
                  api.get(`/Totais/grafico-pedidos-faturados?id=${idDistribuidor}&nivel=${nivelUsuario}&qtdeMeses=${qtdeMeses}&status=${tipo}`);   

            const listaGrafico4 = response.data.map((item) => {
                  return {
                    x: item.detalheTotal,
                    y: Number(item.valorDetalheTotal),
                  };
            });       
            setGrafico4(listaGrafico4);                            
        }

        async function getTotaisPorDistribuidor() {

            const qtdeMeses = 6;
            const response = await 
                  api.get(`/Totais/pedidos-por-revenda?id=${idDistribuidor}&nivel=${nivelUsuario}&qtdeMeses=${qtdeMeses}`);   

            response.data.sort(function (a, b) { return b.valorTotal - a.valorTotal});                
            setListaDistribuidores(response.data);                            

            var tot1 = response.data.reduce(function(accumulator,object){ 
                return accumulator + object.valor1
              },0);                 
            setTotalPonto(tot1);               

            var tot2 = response.data.reduce(function(accumulator,object){ 
                return accumulator + object.valor2
              },0);                 
            setTotalAcesso(tot2);               

            var tot3 = response.data.reduce(function(accumulator,object){ 
                return accumulator + object.valor3
              },0);                 
            setTotalFolha(tot3);               

            setTotalGeral(tot1 + tot2 + tot3);

        }


        setLoading(true);   

        getFaturamento();        
        getContratosAtivos();
        getContratosCancelados();
        getPedidosFaturados();
        getTotaisPorDistribuidor();

        setLoading(false);        
       
    }, []);       
   

    if  (loading) return <Loading />

    if  (grafico)
        return(
            <section className={styles.formulario}>

              <div className={styles.container}> 

                <h2 className={styles.titulo}>Faturamento (Ultimos 06 meses)</h2>                
                <div className={styles.grafico}>
                    <VictoryChart>
                        <VictoryBar                           
                            alignment="start" 
                            labelComponent={<VictoryTooltip/>}                        
                            data={grafico}
                            labels={({ datum }) => `${datum.y}k`}
                            style={{ data: { fill: '#00BFFF' }, labels: {fontSize: 14} }}
                        >
                        </VictoryBar>
                    </VictoryChart>
                </div>

                <div className={styles.container2}>
                  <div className={styles.grafico2}>

                    {(grafico2) && 
                    <>
                            <h3 className={styles.titulo}>Novos Contratos</h3>
                            <div className={styles.graficoItem2}>
                                <VictoryChart>
                                    <VictoryLine
                                        alignment="start" 
                                        // labelComponent={<VictoryTooltip/>}                        
                                        data={grafico2}
                                        labels={({ datum }) => `${datum.y}`}
                                        style={{ data: { stroke: '#00FA9A' } }}
                                    >
                                    </VictoryLine>
                                </VictoryChart>
                            </div>
                        </>                        
                    }

                    {(grafico3) && 
                    <>
                            <h3 className={styles.titulo}>Contratos Cancelados</h3>
                            <div className={styles.graficoItem2}>
                                <VictoryChart>
                                    <VictoryLine                                 
                                        alignment="start" 
                                    // labelComponent={<VictoryTooltip/>}                        
                                        data={grafico3}
                                        labels={({ datum }) => `${datum.y}`}
                                        style={{ data: { stroke: '#FF4500' } }}
                                    >
                                    </VictoryLine>
                                </VictoryChart>
                            </div>
                        </>                        
                    }
                  </div>
                </div>
                
                <div className={styles.container3}>
                  <div className={styles.grafico3}>
                    <h3 className={styles.titulo}>Pedidos Comercializados por Período</h3>                
                    <div className={styles.graficoItem3}>
                        <VictoryChart domainPadding={12}>
                            <VictoryScatter 
                                alignment="start" 
                                size={11}
                            //   labelComponent={<VictoryTooltip/>}                        
                                data={grafico4}
                                labels={({ datum }) => `${datum.y}`}
                                style={{ data: { fill: '#c43a31' }, labels: {fontSize: 12} }}
                            >
                            </VictoryScatter>           
                        </VictoryChart>
                      </div>
                    </div>
                </div>

                {(listaDistribuidores && nivelUsuario ==="1") &&
                 <div className={styles.container3}>
                  <div className={styles.grafico3}>
                  <h3 className={styles.titulo}>Ranking Pedidos por Revenda Ultimos 06 meses</h3>                
                    <div className={styles.tabela}>
                        <Table hover responsive bordered>
                            <thead style={{backgroundColor: 'dodgerblue', color: 'whitesmoke'}}>
                                <tr className={styles.trHead}>
                                    <th className={styles.th}>Revenda</th>
                                    <th className={styles.th}>Ponto</th>                          
                                    <th className={styles.th}>Acesso</th>                          
                                    <th className={styles.th}>Folha</th>                          
                                    <th className={styles.th}>Total Pedidos</th>                          
                                </tr>
                                </thead>                            
                            
                                <tbody className={styles.tbody}>                        
                                    {listaDistribuidores.map(distribuidor => 
                                        <tr key={distribuidor.id}>                                     
                                            <td className={styles.td1}>{distribuidor.detalheTotal}</td>          
                                            <td className={styles.td2}>{distribuidor.valor1}</td>
                                            <td className={styles.td2}>{distribuidor.valor2}</td>
                                            <td className={styles.td2}>{distribuidor.valor3}</td>
                                            <td className={styles.td2}>{distribuidor.valorTotal}</td>
                                        </tr>
                                    )}   
                                </tbody>    
                                <tfoot style={{fontWeight: 'bold', backgroundColor: 'seashell'}}>
                                    <tr key={99999}>                                     
                                        <td className={styles.td1}>Total Geral: </td>          
                                        <td className={styles.td2}>{totalPonto}</td>
                                        <td className={styles.td2}>{totalAcesso}</td>
                                        <td className={styles.td2}>{totalFolha}</td>
                                        <td className={styles.td2}>{totalGeral}</td>
                                    </tr>
                                </tfoot>
                        </Table>         
                    </div>   
                  </div>   
                 </div>   
                }

              </div>             
              <br />             
            </section>


        )
    else return <p>Não Existem dados no periodo.</p>
}

export default Dashboard;