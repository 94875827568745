import React from "react";
import api from "../../Api/apiAxios";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading";
import { MyContext } from "../../Context/MyContext";
import Mensagem from "../../Components/Mensagem";
import { Button, Form, Row, Col, FloatingLabel, Alert } from "react-bootstrap";
import styles from "./NovoServico.module.css";

const NovoServico = () => {

    const navigate = useNavigate();

    var RegexValorDecimal = /[0-9]{1,3}[, ]*[0-9]{0,2}/;

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idUsuario = window.localStorage.getItem('idUsuario');
    const idDistribuidor = window.localStorage.getItem('distribuidorId');   

    const { mensagemGlobal, setMensagemGlobal, setTipoMensagemGlobal, tipoMensagemGlobal } = React.useContext(MyContext);

    const [loading, setLoading] = React.useState(false);

    const [distribuidores, setDistribuidores] = React.useState([]);
    const [clientes, setClientes] = React.useState([]);

    const [distribuidorSelecionado, setDistribuidorSelecionado] = React.useState('');
    const [clienteSelecionado, setClienteSelecionado] = React.useState('');
    const [sistema, setSistema] = React.useState('');
    const [valorServico, setValorServico] = React.useState(0);
    const [valorParcela, setValorParcela] = React.useState(0);
    const [quantidadeParcelas, setQuantidadeParcelas] = React.useState('');
    const [descricaoServico, setDescricaoServico] = React.useState('');
    const [ressarcimento, setRessarcimento] = React.useState(false);
    const [integrarMesAnterior, setIntegrarMesAnterior] = React.useState('');
    const [statusMesAnterior, setStatusMesAnterior] = React.useState(false);

    React.useEffect(() => {
        const data = new Date();
        const dia = data.getDate();     
        if  (dia > 25)
            setStatusMesAnterior(false)
        else
            setStatusMesAnterior(true);    
    
        async  function pesquisarDistribuidores() { 
               setLoading(true);                  
               const response = await api.get('/Distribuidor');

               if  (nivelUsuario !== "1") {
                   const distribuidoresFiltrados = response.data.filter((distribuidor) => 
                       distribuidor.id.toString().includes(idDistribuidor));               
                       setDistribuidores(distribuidoresFiltrados);            
               } else {
                        setDistribuidores(response.data);            
               }    
//                console.log(response.data);
               setLoading(false);       
        }

        pesquisarDistribuidores();            

    }, []);    

     React.useEffect(() => {
        if  (distribuidorSelecionado) {
            loadClientes(distribuidorSelecionado);
        }
    }, [distribuidorSelecionado])

    React.useEffect(() => {
        if  (quantidadeParcelas)
        {
            setValorParcela((Number(valorServico) / Number(quantidadeParcelas)).toFixed(2));
        }
    }, [quantidadeParcelas])

    async function loadClientes(id) {
            const response = await api.get(`/Cliente/${id}/A`);
            response.data.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));
            setClientes(response.data);            
    };    

    function getValueDistribuidor(ev) {
        const idSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setDistribuidorSelecionado(idSelected);            
    };  

    function getValueCliente(ev) {
        const idClienteSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setClienteSelecionado(idClienteSelected); 
    };  

    async function handleSubmit(ev) {
        ev.preventDefault();
        setMensagemGlobal(null);

        if  (distribuidorSelecionado.length === 0 || clienteSelecionado.length === 0) {
            setMensagemGlobal("Faltou Selecionar Distribuidor e/ou Cliente");
            setTipoMensagemGlobal("danger");
            return;
        }

        var valido = RegexValorDecimal.test(Number(valorServico));
        if (!valido) {
            setMensagemGlobal("Valor inválido. Utilize somente numeros e ponto decimal para centavos !");
            setTipoMensagemGlobal("danger");
            return;
        }

        if  (quantidadeParcelas.length === 0) {
            setMensagemGlobal("Faltou Definir a quantidade de parcelas...");
            setTipoMensagemGlobal("danger");
            return;
        }

        const dataAtual = new Date();       
        let regServico = {
            Id: 0,
            DistribuidorId: Number(distribuidorSelecionado),                        
            ClienteId: Number(clienteSelecionado),
            UsuarioId: Number(idUsuario),
            TipoSistema: Number(sistema),
            DescricaoServico: descricaoServico,
            QuantidadeParcelas: Number(quantidadeParcelas),
            StatusServico: 0,
            ValorServico: Number(valorServico),
            DataInclusao: dataAtual,
            DataLiberacao: dataAtual,
            Ressarcimento: ressarcimento ? 1 : 0,
            IntegrarMesAnterior: integrarMesAnterior ? 1: 0,
        }                
               
        const ret = await api.post("Servico/", 
                    JSON.stringify(regServico), 
              {
                  headers: { 'Content-Type': 'application/json'}
              });

        setMensagemGlobal(ret.data);
        setTipoMensagemGlobal("success");               
        navigate("/servicos");

    }


    if  (loading) return <Loading />

    return (
        <section className={styles.form}>
            {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center'}}>Inserir Serviço</Alert.Heading>        
            <br />  

            <Row className="g-2" style={{marginBottom: '1rem'}}> 
                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Selecione o Tipo de Sistema">
                        <Form.Select value={sistema} onChange={({target}) => setSistema(target.value)}>
                            <option value="" disabled></option>
                            <option value="1">BmaPonto</option>
                            <option value="2">BmaAcesso</option>
                            <option value="3">BmaFolha</option>
                            <option value="4">SisDm</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='8'>
                    <FloatingLabel controlid="floatingInputGrid" label="Selecione o Distribuidor">
                        <Form.Select onChange={getValueDistribuidor}  className=" form-select-control">
                            <option></option>                               
                            {distribuidores.map((distribuidor) => (
                                <option key={distribuidor.id} 
                                        id={distribuidor.id}
                                        value={distribuidor.id}
                                        selected={distribuidor.id === distribuidorSelecionado}>{distribuidor.razaoSocial}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>    
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>    
                <Col md='12'>
                    <FloatingLabel controlid="floatingInputGrid2" label="Selecione o Cliente">
                        <Form.Select onChange={getValueCliente}  className=" form-select-control" >
                            <option></option>
                            {clientes.map((cliente) => (
                                <option key={cliente.id} 
                                        id={cliente.id}
                                        value={cliente.id}
                                        selected={cliente.id === clienteSelecionado}>{cliente.razaoSocial} - {cliente.numeroInscricao}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>                  
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Valor Serviço">
                        <Form.Control placeholder="Val.Servico" value={valorServico} 
                                    onChange={({ target }) => setValorServico(target.value)}/>
                    </FloatingLabel>      
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid3" label="Quantidade de Parcelas">
                        <Form.Select value={quantidadeParcelas} onChange={({target}) => setQuantidadeParcelas(target.value)}>
                            <option value="" disabled></option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </Form.Select>
                    </FloatingLabel>                    
                </Col>

                <Col md='4'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Valor Parcela">
                        <Form.Control placeholder="Val.Servico" value={valorParcela} disabled
                                    onChange={({ target }) => setValorParcela(target.value)}/>
                    </FloatingLabel>      
                </Col>

            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                    <FloatingLabel controlid="floatingInputGrid6" label="Descrição do Serviço">
                        <Form.Control placeholder="Descrição Serviço" value={descricaoServico} 
                                    onChange={({ target }) => setDescricaoServico(target.value)}/>
                    </FloatingLabel>      
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                    <Form.Check 
                        type="switch"
                        id="status-servico"
                        label="Assinale se o Serviço for de Ressarcimento a Revenda"                      
                        onChange={() => setRessarcimento(!ressarcimento)}
                        checked={ressarcimento}
                    />
                </Col>
            </Row>

            <Row className="g-2" style={{marginBottom: '1rem'}}>
                <Col md='12'>
                    <Form.Check 
                        type="switch"
                        id="integrar-servico-mes-ant"
                        label="Assinale para integrar o Serviço no faturamento anterior (somente para OS´s com data entre 26 e 31)"                      
                        onChange={() => setIntegrarMesAnterior(!integrarMesAnterior)}
                        checked={integrarMesAnterior}
                        disabled={statusMesAnterior}
                    />
                </Col>
            </Row>

            </Alert>

            <div className={styles.areaBtn}>                
               <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                       disabled={nivelUsuario !== "1"}>Confirmar
               </Button>
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

        </section>
    )

}

export default NovoServico;