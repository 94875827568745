import React from "react";

import { Form, Alert, Button, Table, Col, Row, FloatingLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { format, parseISO, differenceInCalendarMonths } from "date-fns";
import { VictoryStack, VictoryArea, VictoryChart, VictoryGroup, VictoryScatter, VictoryPortal } from "victory";

import { MyContext } from "../../../Context/MyContext";
import Mensagem from "../../../Components/Mensagem";
import Loading from "../../../Components/Loading";
import styles from "./RelatorioDesempenhoRevenda.module.css";

import api from "../../../Api/apiAxios";

export const RelatorioDesempenhoRevenda = () => {
    const navigate = useNavigate();

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');
    
    const { setListaGlobalClientes, setListaGlobalContratos, setDistribuidorIdGlobal } = React.useContext(MyContext);

    const [distribuidorSelecionado, setDistribuidorSelecionado] = React.useState('');
    const [dataInicialRelatorio, setDataInicialRelatorio] = React.useState('');
    const [dataFinalRelatorio, setDataFinalRelatorio] = React.useState('');
    const [distribuidores, setDistribuidores] = React.useState([]);
    const [tipoMensagem, setTipoMensagem] = React.useState('');
    const [dadosGrafico, setDadosGrafico] = React.useState('');
    const [registros, setRegistros] = React.useState('');
    const [mensagem, setMensagem] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const [totalCopiasNovas, setTotalCopiasNovas] = React.useState(0.00);
    const [totalValorCopiasNovas, setTotalValorCopiasNovas] = React.useState(0.00);
    const [totalAtualizacoes, setTotalAtualizacoes] = React.useState(0.00);
    const [totalValorAtualizacoes, setTotalValorAtualizacoes] = React.useState(0.00);
    const [totalValorContratos, setTotalValorContratos] = React.useState(0.00);
    const [totalValorServicos, setTotalValorServicos] = React.useState(0.00);
    const [totalFinal, setTotalFinal] = React.useState(0.00); 

    const [mediaCopiasNovas, setMediaCopiasNovas] = React.useState(0.00);
    const [mediaValorCopiasNovas, setMediaValorCopiasNovas] = React.useState(0.00);
    const [mediaAtualizacoes, setMediaAtualizacoes] = React.useState(0.00);
    const [mediaValorAtualizacoes, setMediaValorAtualizacoes] = React.useState(0.00);
    const [mediaValorContratos, setMediaValorContratos] = React.useState(0.00);
    const [mediaValorServicos, setMediaValorServicos] = React.useState(0.00);
    const [mediaFinal, setMediaFinal] = React.useState(0.00); 

    React.useEffect(() =>{
        setListaGlobalClientes(null);
        setListaGlobalContratos(null);
        setDistribuidorIdGlobal('');

        const data = new Date();
      
        const ano = data.getFullYear();
        const mes = String(data.getMonth() + 1).padStart(2,'0');       
        setDataInicialRelatorio(ano + "-" + mes);
        setDataFinalRelatorio(ano + "-" + mes);

        async  function pesquisarDistribuidores() { 
            setLoading(true);                  

            if  (nivelUsuario !== "1") {
                 const resp = await api.get(`/Distribuidor/${idDistribuidor}`);  
                 const novoArray = []; 
                 novoArray.unshift(resp.data);
                 setDistribuidores(novoArray);            
            } else {
                   const response = await api.get('/Distribuidor');
                   const registrosFiltrados = response.data.filter((distribuidor) => 
                         distribuidor.statusEmpresa === 1);          
                   registrosFiltrados.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));                
                   setDistribuidores(registrosFiltrados);  

            }    
                   
            setLoading(false);       
     }
    pesquisarDistribuidores();
    }, [idDistribuidor, nivelUsuario, setDistribuidorIdGlobal, setListaGlobalClientes, setListaGlobalContratos])

    function getValueDistribuidor(ev) {
        setDistribuidorSelecionado('');
        setRegistros('');
        
        const idSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setDistribuidorSelecionado(idSelected);   
    }  

    async function handleSubmit(e) {
        e.preventDefault();
        setRegistros('');
        

        if (distribuidorSelecionado.length === 0) {
            setMensagem("Selecione o Distribuidor");
            setTipoMensagem("danger");

            return
        } else if (dataInicialRelatorio.length === 0 || dataFinalRelatorio.length === 0) {
            setMensagem("Selecione o período desejado");
            setTipoMensagem("danger");

            return
        } else if (differenceInCalendarMonths(dataInicialRelatorio, dataFinalRelatorio) > 12 || differenceInCalendarMonths(dataInicialRelatorio, dataFinalRelatorio) < -12) {
            setMensagem("O período selecionado deve ser de no máximo 12 meses.");
            setTipoMensagem("danger");

            return
        }

        try {
            setLoading(true);
            setMensagem("");

            const resp = await api.get(`/Totais/desempenho-por-revenda?id=${distribuidorSelecionado}&periodoInicial=${dataInicialRelatorio}&periodoFinal=${dataFinalRelatorio}`);

            setRegistros(resp.data);
            
            if (resp.data.length > 0) {
                let totalRegistros = resp.data.length;
                let dados = resp.data.map(registro => ({x: format(parseISO(registro.dataFaturamento), 'MM/yy'), y: ((((registro.valorCopiasNovas) + (registro.valorCopiasAtualizacao) + registro.valorNovosContratos + registro.valorTotalServicos)/1000)) }))

                setDadosGrafico(dados);

                const total = (key) => {
                    return resp.data.reduce((a,b) => a + b[key] || 0, 0)
                }

                const totalFinal = resp.data.reduce((a,b) => a + ((b['valorCopiasNovas']) + (b['valorCopiasAtualizacao']) + b['valorNovosContratos'] + b['valorTotalServicos'] ) || 0, 0); 

                setTotalCopiasNovas(total('quantidadeCopiasNovas'))
                setTotalValorCopiasNovas(total('valorCopiasNovas'))
                setTotalAtualizacoes(total('quantidadeCopiasAtualizacao'))
                setTotalValorAtualizacoes(total('valorCopiasAtualizacao'))
                setTotalValorContratos(total('valorNovosContratos'))
                setTotalValorServicos(total('valorTotalServicos'))
                setTotalFinal(totalFinal);

                setMediaCopiasNovas(total('quantidadeCopiasNovas')/totalRegistros);
                setMediaValorCopiasNovas(total('valorCopiasNovas')/totalRegistros);
                setMediaAtualizacoes(total('quantidadeCopiasAtualizacao')/totalRegistros);
                setMediaValorAtualizacoes(total('valorCopiasAtualizacao')/totalRegistros);
                setMediaValorContratos(total('valorNovosContratos')/totalRegistros);
                setMediaValorServicos(total('valorTotalServicos')/totalRegistros);
                setMediaFinal(totalFinal/totalRegistros);



            } else {
                setMensagem("Não foram encontrados registros para o período selecionado.");
                setTipoMensagem("danger");
            }

        } catch (e) {
            alert("Não foi possível gerar o relatório - Erro: " + e);
            console.log(e)
        } finally {
            setLoading(false);
        }
    }

    if  (loading) return <Loading />

    return (
        <section className={styles.form}>
            {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem} /> }
            
            <Alert style={{background: 'ghostwhite', color: 'black'}} >
                <Alert.Heading style={{textAlign: 'center', fontSize: 'large'}} >
                    Desempenho por Revenda
                </Alert.Heading> 

                <br />

                <Row>
                    <Col md='2' />
                    <Col md='8'>
                        <FloatingLabel label="Selecione o Distribuidor">
                            <Form.Select onChange={getValueDistribuidor} value={distribuidorSelecionado} className=" form-select-control">
                                <option />                               
                                {distribuidores.map((distribuidor) => (
                                    <option 
                                        key={distribuidor.id} 
                                        id={distribuidor.id}
                                        value={distribuidor.id}
                                    >
                                        {distribuidor.razaoSocial}
                                    </option>
                                ))}
                            </Form.Select>
                        </FloatingLabel>    
                    </Col>
                </Row>
                <br />

                <Row className="g-2" style={{marginBottom: '1rem'}}>
                    <Col md='2' />
                    <Col md='4'>
                        <Form.Label style={{fontWeight: 'normal', textAlign: 'left'}} >
                            Período Inicial
                        </Form.Label>                       
                            <Form.Control 
                                type="month" 
                                placeholder="Periodo" 
                                value={dataInicialRelatorio} 
                                style={{alignSelf: 'left'}}         
                                onChange={({ target }) => setDataInicialRelatorio(target.value)}
                            />
                    </Col>

                    <Col md='4' >
                        <Form.Label style={{fontWeight: 'normal', textAlign: 'left'}} >
                            Período Final
                        </Form.Label>                       
                        <Form.Control 
                            type="month" 
                            placeholder="Periodo" 
                            value={dataFinalRelatorio} 
                            style={{alignSelf: 'left'}}         
                            onChange={({ target }) => setDataFinalRelatorio(target.value)}
                        />
                    </ Col>
                </Row>                    
            </Alert>

            <div className={styles.areaBtn}>    
               <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                        disabled={nivelUsuario === "2" || nivelUsuario === "4"}>Gerar
               </Button>               
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>

            {registros.length >= 1 && 
            <>
                <Table hover responsive>
                    <thead className={styles.tableHeader} style={{backgroundColor: 'dodgerblue', color: 'whitesmoke'}}>
                        <tr className={styles.trHead}>
                            <th className={styles.th}>Mês</th>
                            <th className={styles.th}>Cópias novas</th> 
                            <th className={styles.th}>Valor cópias novas</th>
                            <th className={styles.th}>Atualizações</th>
                            <th className={styles.th}>Valor atualizações</th>
                            <th className={styles.th}>Valor novos contratos</th>
                            <th className={styles.th}>Valor serviços</th>
                            <th className={styles.th}>Total</th> 
                            <th></th>                        
                        </tr>
                    </thead>

                    <tbody className={styles.registros}>
                        {registros.map(registro => 
                            <tr key={registro.id}>
                                <td>{format(parseISO(registro.dataFaturamento), 'MM/yyyy')}</td>
                                <td>{registro.quantidadeCopiasNovas}</td>
                                <td>R$ {registro.valorCopiasNovas.toFixed(2)}</td>
                                <td>{registro.quantidadeCopiasAtualizacao}</td>
                                <td>R$ {registro.valorCopiasAtualizacao.toFixed(2)}</td>
                                <td>R$ {registro.valorNovosContratos.toFixed(2)}</td>
                                <td>R$ {registro.valorTotalServicos.toFixed(2)}</td>
                                <td>R$ {((registro.valorCopiasNovas) + (registro.valorCopiasAtualizacao) + registro.valorNovosContratos + registro.valorTotalServicos).toFixed(2)}</td>
                            </tr>
                        )}
                        <tr>
                            <td style={{fontWeight: 'bold'}}>Total</td>
                            <td style={{fontWeight: 'bold'}}>{totalCopiasNovas.toFixed(2)}</td>
                            <td style={{fontWeight: 'bold'}}>R$ {totalValorCopiasNovas.toFixed(2)}</td>
                            <td style={{fontWeight: 'bold'}}>{totalAtualizacoes.toFixed(2)}</td>
                            <td style={{fontWeight: 'bold'}}>R$ {totalValorAtualizacoes.toFixed(2)}</td>
                            <td style={{fontWeight: 'bold'}}>R$ {totalValorContratos.toFixed(2)}</td>
                            <td style={{fontWeight: 'bold'}}>R$ {totalValorServicos.toFixed(2)}</td>
                            <td style={{fontWeight: 'bold'}}>R$ {totalFinal.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>Média</td>
                            <td>{mediaCopiasNovas.toFixed(2)}</td>
                            <td>R$ {mediaValorCopiasNovas.toFixed(2)}</td>
                            <td>{mediaAtualizacoes.toFixed(2)}</td>
                            <td>R$ {mediaValorAtualizacoes.toFixed(2)}</td>
                            <td>R$ {mediaValorContratos.toFixed(2)}</td>
                            <td>R$ {mediaValorServicos.toFixed(2)}</td>
                            <td>R$ {mediaFinal.toFixed(2)}</td>
                        </tr>

                    </tbody>
                </Table>
            </>
            }  
            { registros.length >= 2 && totalFinal > 0 &&
                <div className={styles.grafico}>
                <label className={styles.obs}>* O eixo Y refere-se ao total mensal em milhares</label>
                <VictoryChart width={700} height={400} >
                    <VictoryStack 
                        colorScale="heatmap" 
                    >
                        <VictoryGroup data={dadosGrafico}>
                            <VictoryArea />
                            <VictoryPortal>
                                <VictoryScatter style={{ data: { fill: 'black' } }} />
                            </VictoryPortal>
                        </VictoryGroup>
                    </VictoryStack>
                </VictoryChart>
            </div>  
            }
        </section>
    )
}
