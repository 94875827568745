import React from "react";
import api from "../../Api/apiAxios";
import { Form, Alert, Button, Col, Row, FloatingLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading";
import Mensagem from "../../Components/Mensagem";
import { MyContext } from "../../Context/MyContext";
import styles from "./RelatorioFaturamento.module.css";

const RelatorioFaturamento = () => {
    const navigate = useNavigate();

    const { setListaGlobalClientes, setListaGlobalContratos, setDistribuidorIdGlobal } = React.useContext(MyContext);

    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');   

    const [loading, setLoading] = React.useState(false);
    const [mensagem, setMensagem] = React.useState(null);
    const [somenteTotais, setSomenteTotais] = React.useState(false);
    const [tipoMensagem, setTipoMensagem] = React.useState('');
    const [distribuidorSelecionado, setDistribuidorSelecionado] = React.useState('');
    const [dataRelatorio, setDataRelatorio] = React.useState('');
    const [distribuidores, setDistribuidores] = React.useState([]);

    React.useEffect(() =>{
        setListaGlobalClientes(null);
        setListaGlobalContratos(null);
        setDistribuidorIdGlobal('');

        const data = new Date();
      
        const ano = data.getFullYear();
        const mes = String(data.getMonth() + 1).padStart(2,'0');       
        setDataRelatorio(ano + "-" + mes);

        async  function pesquisarDistribuidores() { 
            setLoading(true);                  

            if  (nivelUsuario !== "1") {
                 const resp = await api.get(`/Distribuidor/${idDistribuidor}`);  
                 const novoArray = []; 
                 novoArray.unshift(resp.data);
                 setDistribuidores(novoArray);            
            } else {
                   const response = await api.get('/Distribuidor');
                   const registrosFiltrados = response.data.filter((distribuidor) => 
                         distribuidor.statusEmpresa === 1);          
                   registrosFiltrados.sort((a,b) => a.razaoSocial.localeCompare(b.razaoSocial));

                   var todos = {
                    id: 9999,
                    razaoSocial: '*** TODOS ***',
                   }
                   registrosFiltrados.push(todos);
                   
                   setDistribuidores(registrosFiltrados);  

            }    
                   
            setLoading(false);       
     }

    pesquisarDistribuidores();

    }, [])

    function getValueDistribuidor(ev) {
        const idSelected = ev.target.children[ev.target.selectedIndex].getAttribute('id');
        setDistribuidorSelecionado(idSelected);   
    };  


    async function handleSubmit(ev) {
        ev.preventDefault();

        if  (dataRelatorio.length === 0) {
            setMensagem("Faltou Selecionar o Período");
            setTipoMensagem("danger");
            return;
        }

        try {

            if  (distribuidorSelecionado.length === 0) {
                setMensagem("Faltou Selecionar o Distribuidor");
                setTipoMensagem("danger");
                return;
            }

            setLoading(true); 
            setMensagem('');       

            const totais = somenteTotais ? 1 : 0;

            const response = 
                await api.get(`/Faturamento/relatorio-por-periodo?id=${distribuidorSelecionado}&nivel=${nivelUsuario}&periodo=${dataRelatorio}&totais=${totais}`, 
                      { responseType: 'blob' }
            );   

            var pdfBlob = new Blob([response.data], { type: 'application/pdf'} );
            var url = window.URL.createObjectURL(pdfBlob)
            window.open(url);

            // navigate(-1);

//             const link = document.createElement('a')
//                   link.href = url
//                   link.setAttribute('download', `Faturamento_Periodo_${periodo}_Distribuidor_${idDistribuidor}.pdf`)
//                   link.click();
//                   link.remove();
//             URL.revokeObjectURL(url);         

        }
        catch (error) {
              alert("Não foi possível gerar o relatório. Erro - " + error);
        } finally {
            setLoading(false);
        }
    }


    if  (loading) return <Loading />

    return (
        <section className={styles.form}>

            <Alert style={{background: 'ghostwhite', color: 'black'}}>
                <Alert.Heading style={{textAlign: 'center', fontSize: 'large'}}>Relação Faturamento por Período</Alert.Heading>        
                <p style={{fontSize: 'medium', fontStyle: 'italic'}}>
                    Aviso: O Faturamento agora é gerado por competência. Exemplo: Dados de 26/11 a 25/12 vão gerar 
                    o faturamento do período 12/2023. 
                </p>
                <br />

                <Row>
                    <Col md='2'>
                    </Col>
                    <Col md='8'>
                        <FloatingLabel controlid="floatingInputGrid" label="Selecione o Distribuidor">
                            <Form.Select onChange={getValueDistribuidor} value={distribuidorSelecionado} className=" form-select-control">
                                <option></option>                               
                                {distribuidores.map((distribuidor) => (
                                    <option key={distribuidor.id} 
                                            id={distribuidor.id}
                                            value={distribuidor.id}
                                            selected={distribuidor.id === distribuidorSelecionado}>{distribuidor.razaoSocial}</option>
                                ))}
                            </Form.Select>
                        </FloatingLabel>    
                    </Col>
                </Row>
                <br />

                <Row className="g-2" style={{marginBottom: '1rem'}}>

                    <Col md='2'>
                    </Col>

                    <Col md='3'>
                        <Form.Label controlid="floatingInputGrid14" style={{fontWeight: 'normal', textAlign: 'left'}}>Informe o Período</Form.Label>                       
                            <Form.Control type="month" placeholder="Periodo" value={dataRelatorio} style={{alignSelf: 'left'}}         
                                        onChange={({ target }) => setDataRelatorio(target.value)}/>
                    </Col>

                    <Col md='1'>
                    </Col>

                    <Col md='3'>
                        <Form.Check 
                            type="switch"
                            id="SomenteTotais"
                            label="Somente Totais"                      
                            onChange={() => setSomenteTotais(!somenteTotais)}
                            checked={somenteTotais}
                        />
                    </Col>

                </Row>                    

            </Alert>

            <div className={styles.areaBtn}>    
               <Button onClick={handleSubmit} variant="outline-primary" className={styles.btn} 
                        disabled={nivelUsuario === "2" || nivelUsuario === "4"}>Gerar Relatório
               </Button>               
               <Button onClick={() => navigate(-1)} variant="outline-info" className={styles.btn}>Voltar</Button>
            </div>    

            {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem} /> }                                 

         </section>   
    )
}

export default RelatorioFaturamento;