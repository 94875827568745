import React from "react";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import styles from "./Paginacao.module.css";

import api from "../Api/apiAxios";

export const pesquisarPrevious = async ({
    registroInicial, 
    urlPesquisa, 
    setRegistroInicial, 
    setRegistroFinal, 
    setRegistrosData,
    itensPorPagina
}) => {
    try {
        if  (registroInicial < 2) {
            setRegistroInicial(1);
            setRegistroFinal(itensPorPagina);
    
            const response2 = await api.get(urlPesquisa + `&regInicial=${1}&itensPorPagina=${itensPorPagina}`);   
            setRegistrosData(response2.data);   
        }
        else {
            let contador1 = registroInicial - itensPorPagina;
            let contador2 = contador1 + (itensPorPagina - 1);
    
            setRegistroInicial(contador1);
            setRegistroFinal(contador2);
    
            const response2 = await api.get(urlPesquisa + `&regInicial=${contador1}&itensPorPagina=${itensPorPagina}`);   
            setRegistrosData(response2.data);   
        } 
    } catch (e) {
        alert(e);
    }
    
}

export const pesquisarNext = async ({
    registroInicial, 
    registroFinal,
    urlPesquisa, 
    quantidadeRegistros, 
    setRegistroInicial, 
    setRegistroFinal, 
    setRegistrosData,
    itensPorPagina
}) => {
    if (quantidadeRegistros < registroFinal || quantidadeRegistros  === registroFinal) return;

    try {
        if  ((registroFinal + itensPorPagina) > quantidadeRegistros) {
            let contador1 = registroInicial + itensPorPagina;
    
            setRegistroInicial(contador1);
            setRegistroFinal(quantidadeRegistros);
    
            const response2 = await api.get(urlPesquisa + `&regInicial=${contador1}&itensPorPagina=${itensPorPagina}`);   
            setRegistrosData(response2.data);   
        }
        else {
            let contador1 = registroInicial + itensPorPagina;
            let contador2 = contador1 + (itensPorPagina - 1);
    
            setRegistroInicial(contador1);
            setRegistroFinal(contador2);
    
            const response2 = await api.get(urlPesquisa + `&regInicial=${contador1}&itensPorPagina=${itensPorPagina}`);   
            setRegistrosData(response2.data);   
      }         
    } catch (e) {
        alert(e);
    }
}

export const Paginacao = ( {
    registroInicial, 
    registroFinal,
    urlPesquisa, 
    quantidadeRegistros,
    itensPorPagina, 
    setRegistroInicial, 
    setRegistroFinal, 
    setRegistrosData
} ) => {

    const params = {
        registroInicial: registroInicial, 
        registroFinal: registroFinal,
        urlPesquisa: urlPesquisa, 
        quantidadeRegistros: quantidadeRegistros,
        itensPorPagina: itensPorPagina, 
        setRegistroInicial: setRegistroInicial, 
        setRegistroFinal: setRegistroFinal, 
        setRegistrosData: setRegistrosData
    }

    const handleOnClickPrevious = () => { pesquisarPrevious(params) };
    const handleOnClickNext = () => { pesquisarNext(params) };

    return (
        <div className={styles.main}>            
            <p className={styles.registros}>{registroInicial} - {registroFinal} de {quantidadeRegistros}.</p>
            <Button className={styles.btnPage} onClick={handleOnClickPrevious} variant="outline-dark">            
                  <ChevronLeft size={15} />
            </Button>
            <Button className={styles.btnPage} onClick={handleOnClickNext} variant="outline-dark">            
                  <ChevronRight size={15} />
            </Button>
        </div>
    )                                    
}
