import React from "react";
import api from "../../../Api/apiAxios";
import { Table } from "react-bootstrap";
import Loading from "../../../Components/Loading";
import { MyContext } from "../../../Context/MyContext";
import Mensagem from "../../../Components/Mensagem";
import HeaderPersonalizacoes from "../../../Components/HeaderPersonalizacoes";
import { Paginacao } from "../../../Components/Paginacao";
import styles from "./PersonalizacaoPontoIndex.module.css";

import ItemStatus from "../../../Components/ItemStatus";
import TipoInstalacao from "../../../Components/TipoInstalacao";
import PersonalizacoesButtons from "../../../Components/PersonalizacoesButtons";
import { TableItemName } from "../../../Components/TableItemName";

const PersonalizacaoPontoIndex = () => {
    const nivelUsuario = window.localStorage.getItem('nivelUsuario');  
    const idDistribuidor = window.localStorage.getItem('distribuidorId');   

    const { mensagemGlobal, setMensagemGlobal, tipoMensagemGlobal, setTipoMensagemGlobal,
             setListaGlobalClientes, setListaGlobalContratos,
            listaGlobalLicencasBmaPonto, setListaGlobalLicencasBmaPonto,
            pesquisaGlobalLicencasBmaPonto, setPesquisaGlobalLicencasBmaPonto, 
            setDistribuidorIdGlobal, itensPorPagina } = React.useContext(MyContext);

    const [pesquisa, setPesquisa] = React.useState('');
    const [tipoPesquisa, setTipoPesquisa] = React.useState('*');
    const [loading, setLoading] = React.useState(false);
    const [quantidadeLicencas, setQuantidadeLicencas] = React.useState(0);
    const [registroInicial, setRegistroInicial] = React.useState(1);
    const [registroFinal, setRegistroFinal] = React.useState(20);

    const [personalizacoes, setPersonalizacoes] = React.useState([]);
    
    React.useEffect(() => {
        setListaGlobalClientes(null);
        setListaGlobalContratos(null);
        setDistribuidorIdGlobal('');

        pesquisaInicial();
    }, []);    

    async function pesquisaInicial() { 
      
        setLoading(true);          

        if  (listaGlobalLicencasBmaPonto !== null) {
             setPersonalizacoes(listaGlobalLicencasBmaPonto);
             setQuantidadeLicencas(listaGlobalLicencasBmaPonto.length);   
             setPesquisa(pesquisaGlobalLicencasBmaPonto);
        }
        else {
            try {
                const response = await api.get(`/LicencaPonto/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}&regInicial=${registroInicial}&itensPorPagina=${999999}`);   
                var qtdeRegistros = response.data[0].valorCnpj;
                setQuantidadeLicencas(qtdeRegistros);             
                
                const response2 = await api.get(`/LicencaPonto/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}&regInicial=${registroInicial}&itensPorPagina=${itensPorPagina}`);   
                setPersonalizacoes(response2.data); 
            } catch (e) {
                alert (e);
            }  
        }
        setLoading(false);       
    }

    async function pesquisarClick() {
        var tamanho = pesquisa.length;
        if  (tamanho > 0 && (tipoPesquisa !== "ns" && tipoPesquisa !== "cliente" && tipoPesquisa !== "distribuidor") && isNaN(pesquisa))
        {
            setMensagemGlobal("Pesquisa Parcial somente para NS, Cliente ou Distribuidor !");
            setTipoMensagemGlobal("warning");
            return;
        }

        setLoading(true);                  

        setMensagemGlobal(null);
        setPesquisaGlobalLicencasBmaPonto(null);
        setListaGlobalLicencasBmaPonto(null);

        try {
            if  (tipoPesquisa === "comregistro" || tipoPesquisa === "semregistro")        
            {
                const pesq = tipoPesquisa;
                const response = await api.get(`/LicencaPonto/parametros-consulta?id=${idDistribuidor}&nivel=${nivelUsuario}&tipo=${tipoPesquisa}&args=${pesq}`);   
                setPersonalizacoes(response.data);   
            
            }
            else  if  (pesquisa.length === 0) {   

                    const response2 = await api.get(`/LicencaPonto/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}&regInicial=${registroInicial}&itensPorPagina=${itensPorPagina}`);   
                    setPersonalizacoes(response2.data);   
                }
                else {
                        const response = await api.get(`/LicencaPonto/parametros-consulta?id=${idDistribuidor}&nivel=${nivelUsuario}&tipo=${tipoPesquisa}&args=${pesquisa}`);   
                        setPersonalizacoes(response.data);            
                        setListaGlobalLicencasBmaPonto(response.data);
                        setPesquisaGlobalLicencasBmaPonto(pesquisa);      
                }
        } catch (e) {
            alert(e);
        } finally {
            setPesquisa('');
            setLoading(false);
        }       

    }

    if  (loading) return <Loading />
                           
    return (
        <section>
          {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                 

           <HeaderPersonalizacoes titulo={"Licenças BmaPonto"}  
                pesquisa={pesquisa} setPesquisa={setPesquisa}
                tipoPesquisa={tipoPesquisa} setTipoPesquisa={setTipoPesquisa}
                pesquisarClick={pesquisarClick} 
            />

            <Table striped hover responsive size='sm'>
                <thead className={styles.thead}>
                    <tr className={styles.trHead}>
                            <th className={styles.th}>Distribuidor</th>
                            <th className={styles.th}>Cliente</th>                          
                            <th className={styles.th}>Inscrição</th>                          
                            <th className={styles.th}>NS</th>                        
                            <th className={styles.th}>Versão</th>
                            <th className={styles.th}>Plano</th>
                            <th className={styles.th}>Faixa</th>
                            <th className={styles.th}>Data Reg.</th>
                            <th className={styles.th}>Status</th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                {personalizacoes.map(personalizacao => 
                    <tr key={personalizacao.id} className={styles.trBody}>                      
                        <TableItemName title={personalizacao.distribuidor.nomeFantasia} />
                        <TableItemName title={personalizacao.razaoSocial} />
                        
                        <td className={styles.inscricao}>{personalizacao.numeroInscricao}</td>
                        <td className={styles.td}>{personalizacao.numeroSerie}</td>  
                        <td className={styles.td}>{personalizacao.versao}</td>                        

                        <TipoInstalacao tipoInstalacao={personalizacao.tipoInstalacao} isPonto={true} />

                        <td className={styles.td}>{personalizacao.faixaFuncionarios}</td>
                        {personalizacao.dataRegistro ?
                            <td className={styles.td}>
                                 {personalizacao.dataRegistro.substring(8,10)}/ 
                                 {personalizacao.dataRegistro.substring(5,7)}/   
                                 {personalizacao.dataRegistro.substring(0,4)}    
                            </td>
                            :
                            <td className={styles.td}>* * * * * * *</td>
                        }

                        <ItemStatus dataRegistro={personalizacao.dataRegistro}  baixadaPorNovaVersao={personalizacao.baixadaPorNovaVersao} />
                        <PersonalizacoesButtons 
                            nivelUsuario={nivelUsuario} 
                            personalizacao={personalizacao} 
                            pesquisarClick={pesquisarClick} 
                            editarUrl={`/editarpersonalizacaoponto/${personalizacao.id}`}
                            bloquearUrl={`/LicencaPonto/atualizar-bloqueio?id=${personalizacao.id}`}
                            liberarPersonalizacaoUrl={`/LicencaPonto/liberar-novo-registro?id=${personalizacao.id}`}
                            viewUrl={`/viewpersonalizacaoponto/${personalizacao.id}`}
                        />
                    </tr>
                )}

                </tbody>
                <tfoot>
                    <tr>
                        <Paginacao 
                            setRegistroFinal={setRegistroFinal}
                            setRegistroInicial={setRegistroInicial}
                            setRegistrosData={setPersonalizacoes}
                            registroInicial={registroInicial}       
                            registroFinal={registroFinal}                                
                            quantidadeRegistros={quantidadeLicencas} 
                            itensPorPagina={itensPorPagina}
                            urlPesquisa={`/LicencaPonto/consulta-paginada?id=${idDistribuidor}&nivel=${nivelUsuario}`}
                        />
                    </tr>

                </tfoot>                    
            </Table>

        </section>
    )

}

export default PersonalizacaoPontoIndex;